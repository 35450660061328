import {Container,Modal, Form, Card, Button, Col, Row} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';
import * as inputOptions from '../../external-tool/input-options'
import * as VALUES from '../../constants/values';

export default function CreateGlobalConfigurationModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [currentForm, setCurrentForm] = useState(() => {
        return {
            sensorType: '1',
            maxDownRange: '1',
            maxCrossRange: '1',
            downRangeOffset: '0',
            mountingHeight: '0',
            terrain: '0',
            audioScheme: undefined,
            triggerType: '1'
        }
    })
    const [title, setTitle] = useState(() => '');

    const setDownAndCrossRangeOptions = () => {
        let arr = new Array(59).fill(1);

        arr = arr.map((n, i) => {
            const modifier = .5 * i;
            const value = n + modifier;
            return {
                displayValue: `${value}m`,
                eepromValue: value
            }
        });

        
        return arr;
    }

    const [downRangeOptions, setDownRangeOptions] = useState(() => []);
    const [crossRangeOptions, setCrossRangeOptions] = useState(() => []);
    const [downRangeOffsetOptions, setDownRangeOffsetOptions] = useState(() => []);

    const saveForm = async (evt) => {
        evt.preventDefault();

        const payload = {
            configuration_title: title,
            configurations: currentForm
        }

        await api.attemptCreateGlobalExternalToolConfigurations(payload);
        close();

    }

    const buildAudioScheme = () => {
        if (currentForm.sensorType != '3' && currentForm.sensorType != '4') return null;

        return (
            <Form.Group className="mt-3">
                <Form.Label>
                    Audio Scheme
                </Form.Label>
                <Form.Select value={currentForm.audioScheme} onChange={evt => setCurrentForm({...currentForm, audioScheme: evt.target.value})}>
                    {
                        inputOptions.audioSchemeOptions.map((o, i) => {
                            return (
                                <option key={i} value={o.eepromValue}>{o.displayValue}</option>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
        )
    }

    useEffect(() => {
        const options = setDownAndCrossRangeOptions();
       const offsetOptions = inputOptions.getDownRangeOffsetOptions();
       setCrossRangeOptions(options);
       setDownRangeOptions(options);
       setDownRangeOffsetOptions(offsetOptions);
    }, [])
    return (
        <Modal  show={true} onHide={close} >
        <Modal.Header className="spartan-modal" closeButton>
            <Modal.Title className="spartan-modal">CREATE GLOBAL CONFIGURATION</Modal.Title>
        </Modal.Header>
        <Modal.Body className="spartan-modal">
        <div>
            <Form onSubmit={saveForm}>
            <Container>
                <Card bg="dark" text="white">
                    
                    <Card.Body>
                        <Row>
                            <Form.Group>
                                <Form.Label>
                                    Configuration Title
                                </Form.Label>
                                <Form.Control type="text" value={title} onChange={evt => setTitle(evt.target.value)} required/>
                            </Form.Group>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                
                                <Form.Group>
                                    <Form.Label>
                                        Sensor Type
                                    </Form.Label>
                                    <Form.Select required value={currentForm.sensorType} onChange={evt => setCurrentForm({...currentForm, sensorType: evt.target.value})}>
                                        <option></option>
                                        {
                                            
                                            inputOptions.sensorTypes.map((o, i) => {
                                                
                                                return (
                                                    <option value={o.eepromValue} key={i}>{o.displayValue}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Max Segments
                                    </Form.Label>
                                    <Form.Control type="number" readOnly value={(currentForm.sensorType != '5' && currentForm.sensorType != '6' ? VALUES.MAX_SEGMENTS_STANDARD : VALUES.MAX_SEGMENTS_PILLAR)} />
                                </Form.Group>
                                
                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Max Down Range
                                    </Form.Label>

                                    <Form.Select required value={currentForm.maxDownRange} onChange={evt => { 
                                        setCurrentForm({...currentForm, maxDownRange: evt.target.value})}}>
                                        {
                                            downRangeOptions.map((o,i) => {
                                                return (
                                                    <option value={o.eepromValue} key={i}>{o.displayValue}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                               
                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Down Range Offset
                                    </Form.Label>

                                    <Form.Select required value={currentForm.downRangeOffset} onChange={evt => setCurrentForm({...currentForm, downRangeOffset: evt.target.value})}>
                                        {
                                            downRangeOffsetOptions.map((o, i) => {
                                                return (
                                                    <option value={o.eepromValue} key={i}>{o.displayValue}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Mounting Height
                                    </Form.Label>
                                    <Form.Select required value={currentForm.mountingHeight} onChange={evt => setCurrentForm({...currentForm, mountingHeight: evt.target.value})}>
                                        {inputOptions.mountingHeightOptions.map((o, i) => {
                                            return (
                                                <option key={i} value={o.value}>{o.displayValue}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>

                                {buildAudioScheme()}
                            </Col>
                            <Col>

                                <Form.Group >
                                    <Form.Label>
                                        Zones
                                    </Form.Label>
                                    <Form.Control type="number" readOnly value={(currentForm.sensorType != '5' && currentForm.sensorType != '6' ? VALUES.MAX_ZONES_STANDARD : VALUES.MAX_ZONES_PILLAR)}/>
    
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Max Cross Range
                                    </Form.Label>
                                    <Form.Select value={currentForm.maxCrossRange} onChange={evt => setCurrentForm({...currentForm, maxCrossRange: evt.target.value})}>
                                    {
                                            crossRangeOptions.map((o,i) => {
                                                return (
                                                    <option value={o.eepromValue} key={i}>{o.displayValue}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Trigger Type
                                    </Form.Label>
                                    <Form.Select value={currentForm.triggerType} onChange={evt => setCurrentForm({...currentForm, triggerType: evt.target.value})}>
                                        {
                                            inputOptions.triggerTypes.map((o, i) => {
                                                return <option key={i} value={o.eepromValue}>{o.displayValue}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mt-3">
                                    <Form.Label>
                                        Terrain
                                    </Form.Label>

                                    <Form.Select value={currentForm.terrain} onChange={evt => setCurrentForm({...currentForm, terrain: evt.target.value})}>
                                        {
                                            inputOptions.terrainOptions.map((o, i) => {
                                                return (
                                                    <option value={o.eepromValue} key={i}>{o.displayValue}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-center">
                        <Button type="submit">Save Global Configuration</Button>
                    </Card.Footer>
                </Card>
            </Container>
            </Form>
        </div>
        </Modal.Body>
        
        </Modal>
    )
}