import {useState, useEffect} from 'react';
import { Modal, Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import SpartanApi from '../../helpers/spartan-api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const radioOptions = ['all', 'none', 'select-fields']
export default function UpdateExternalFieldVisibilityModal({close, organization}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api]    = useState(() => new SpartanApi({dispatch, navigate}));
    const [visibilityType, setVisibilityType] = useState(() => 'all');
    const fieldOptions = useSelector(s => s.externalTool.fieldOptions);
    const [selectedOptions, setSelectedOptions] = useState(s => []);

    const handleFieldChange = (evt) => {
        const name      = evt.target.name;
        const isChecked = evt.target.checked;
        
        if (!isChecked){
            setSelectedOptions(prev => prev.filter(p => p != name))
        } else {
            setSelectedOptions(prev => [...prev, name])
        }
    }

    const saveFields = async(evt) => {
        evt.preventDefault();

        let payload = {
            id: organization.visible_fields.id,
            organization_id: organization.id,
            fields: (visibilityType == 'all' || visibilityType == 'none') ? visibilityType : selectedOptions.join('|')
        }

        await api.attemptUpdateOrganizationExternalToolFieldAvailability(payload);
        close();
    }
    
    const buildFieldOptions = () => {
        if (visibilityType != "select-fields") return null;
        return (
            <div>
                <hr/>
                <h6 className="text-center">Select the Fields you want visible</h6>
                {fieldOptions.map((o, i) => {
                    return (
                        <Form.Check
                            key={i}
                            type="checkbox"
                            label={o}
                            name={o}
                            checked={selectedOptions.includes(o)}
                            onChange={handleFieldChange}
                        />
                    )
                })}
            </div>
        )
    }

    useEffect(() => {
        if (!organization) return;
        if (organization.visible_fields.fields == 'all' || organization.visible_fields.fields == 'none'){
            setVisibilityType(organization.fields);
        } else {
            setVisibilityType('select-fields');
            setSelectedOptions(organization.visible_fields.fields.split('|'))
        }
    }, [organization])
    
    return (
        <Modal onHide={close} show={true} >
            <Modal.Header className='spartan-modal' closebutton>
                Update {organization && organization.organization_name}'s Visible Fields
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={saveFields}>
                    {
                        radioOptions.map((o, i) => {
                            return (
                                <Form.Check key={i}
                                            type="radio"
                                            label={o}
                                            name="visibility-type"
                                            value={o}
                                            checked={visibilityType == o}
                                            onChange={evt => setVisibilityType(evt.target.value)}/>
                            )
                        })
                    }
                    {
                        buildFieldOptions()
                    }
                    <div className="mt-3 text-center">
                        <Button type="submit">SAVE FIELDS</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}