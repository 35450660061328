import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
export const spartanUploadHelper = async({sasToken, containerName, file}) => {
    const blobService = new BlobServiceClient('https://spartanproductiontest.blob.core.windows.net/?' + sasToken);

    const containerClient = blobService.getContainerClient(containerName);
    await containerClient.createIfNotExists({access: 'container'});
    
    const uploadDate = Date.now();
    const fileName = uploadDate+file.name
   

    const blobClient = containerClient.getBlockBlobClient(fileName);
    const options = {blobHTTPHeaders: {blobContentType: file.type}};
    await blobClient.uploadBrowserData(file, options);

    return `https://spartanproductiontest.blob.core.windows.net/${containerName}/${fileName}`
 
    
}