import { useState } from "react";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import SpartanApi from "../../helpers/spartan-api";
import { Container,Card, Form, Button} from "react-bootstrap";
import AlertHelper from "../../helpers/alert-helper";


export default function ChangePassword () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const [alertHelper] = useState(() => new AlertHelper({dispatch}))
    const [newPassword, setNewPassword] = useState(() => {
        return {
            new_password: '',
            confirm_new_password: ''
        }
    });

    const [isUpdating, setIsUpdating] = useState(() => false);
    const [isSuccess, setIsSuccess]   = useState(() => false);

    const attemptChangePassword = async (evt) => {
        evt.preventDefault();
        setIsUpdating(true);
        const success = await  api.attemptChangePassword({user_password: newPassword.new_password});

        if (!success){
            setIsUpdating(false);
            alertHelper.addAlert({
                title: 'Unable to Change Password at this time',
                body: 'Please try again later'
            });
            return;
        }

        setIsSuccess(true);

        setTimeout(() => {
            window.localStorage.clear();
            window.location.href = "/"
        }, 4000)
    }

    if (isSuccess){
        return (
            <Container>
                <Card className="text-center" bg="dark" text="white">
                    <Card.Header>
                        <h4>Thank you for resetting your password</h4>
                    </Card.Header>
                    <Card.Body>
                        <p>You will be redirected to the login page shortly</p>
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            <Card className="text-center" bg="dark" text="white">
                <Card.Header>
                    <h4>Welcome to Spartan FTT</h4>
                    <p>Please create a new password below (upon completion to will be brought back to the login page)</p>
                </Card.Header>
                <Card.Body>
                    <form onSubmit={attemptChangePassword}>
                        <Form.Group className="mt-4">
                            <Form.Label>
                                New  Password (Minimum of 8 Characters)
                            </Form.Label>

                            <Form.Control type="password"
                                          value={newPassword.new_password}
                                          onChange={evt => setNewPassword({...newPassword, new_password: evt.target.value})}
                                          minLength="8"
                                          required
                            />
                        </Form.Group>

                        <Form.Group className="mt-4">
                            <Form.Label>
                                Confirm Password
                            </Form.Label>

                            <Form.Control type="password"
                                          value={newPassword.confirm_new_password}
                                          onChange={evt => setNewPassword({...newPassword, confirm_new_password: evt.target.value})}
                                          minLength="8"
                                          required
                            />
                        </Form.Group>

                        <Button className="mt-4" type="submit" disabled={(newPassword.new_password != newPassword.confirm_new_password) || isUpdating}> CREATE NEW PASSWORD </Button>
                    </form>
                </Card.Body>
            </Card>
        </Container>
        
    )
    
}