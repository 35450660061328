import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import SpartanApi from "../../helpers/spartan-api";
import { QrReader } from "react-qr-reader";
import { Container, Row, Col, Button, Card, Form, Modal } from "react-bootstrap";
import AlertHelper from "../../helpers/alert-helper";
import AddSubAssembly from "./sub-modals/AddSubAssembly";
import AlertContainer from "../../shared/alerts/alerts";

export default function CreateShippingModal({close}) {
    const dispatch = useDispatch();
    const [api]    = useState(() => new SpartanApi({dispatch}));
    const [alertHelper] = useState(() => new AlertHelper({dispatch}))
    const [devicesToShip, setDevicesToShip] = useState(() => []);
    const [partsToShip, setPartsToShip] = useState(() => []);
    const [accessoriesToShip, setAccessoriesToShip] = useState(() => []);
    const [subAssembliesToShip, setSubAssembliesToShip] = useState(() => []);
    const [inSubAssemblyMode, setInSubAssemblyMode] = useState(() => false);
    const [shippingAddress, setShippingAddress] = useState(() => {
        return {
            recipient: '',
            address_one: '',
            address_two: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            
        }
    });
    const [barcode, setBarcode] = useState(() => undefined);

    const [scanned, setScanned] = useState(() => undefined);

    const appState = useSelector(s => s);

    const [bundleInformation, setBundleInformation] = useState(() =>  {
        return {
            shipment_location_id: undefined,
            shipping_carrier: undefined,
            shipping_tracking_number: undefined,
            delivery_status: undefined
        }
    })

    const [isSaving, setIsSaving] = useState(() => false);

    const onBarcodeScan = (evt) => {
        evt.preventDefault();
        if (!barcode) return;
        setScanned(barcode);
    }

    const decipher = async(result, error) => {
        if (error) return;
        setScanned(result.text)
    }

    const removeItem = async(index) => {
        const newArr = [...devicesToShip];
        newArr.splice(index, 1);
        setDevicesToShip(newArr);
    }
    const loadData = async() =>  {
        await api.attemptGetSubAssemblyPartTypes();
        await api.attemptGetSubAssemblyTypes();
        await api.attemptGetAccessories();
        await api.attemptGetTestSites();
    }

    const saveShipment = async (evt) =>  {
        evt.preventDefault();
        setIsSaving(true);

        let shipment_accessory_items = accessoriesToShip.filter(s => {
            return s.part_number != "SCANNED DEVICE ITEM DOESN'T PASS VALIDATION" && s.quantity
        });
        
        const requestData = {
            shipping_bundle: bundleInformation,
            shipping_address: shippingAddress,
            shipment_bundle_items: devicesToShip,
            shipment_accessory_items,
            shipping_bundle_sub_assemblies: subAssembliesToShip,
            stand_alone_parts: partsToShip
        }

        await api.attemptCreateShippingBundle(requestData);

        close();
        
    }

    const attemptAddPartToArray = async(part) => {
        const valid = await  api.attemptValidateSubAssemblyPartForShipping(part.serial_number);
        if (!valid.valid) {
            return alertHelper.addAlert(
                {
                    title: "SCANNED PART DOESN'T PASS VALIDATION",
                    body: valid.reasonForFailure
                }
            )
        }
        const exists = partsToShip.find(p => p.part_id == part.part_id);
        if (exists) return;
        setPartsToShip(prev => {
            return [part, ...prev]
        });
    }

    const attemptAddToArray = async (sn) => {
        let device = await api.attemptGetDeviceOrPartBySerialNumber(sn);
        if (!device) return;
        if (device.part) {
            //THIS IS A SUB ASSEMBLY PART, NOT A DEVICE
            attemptAddPartToArray(device.part);
            return;
        }
        
        device = device.device;
        const exists = devicesToShip.find(d => d.device_id == device.device_id);
        const valid = await api.attemptValidateItemForShipping(device.device_id);
        setBarcode(() => '');
        if (!valid.valid) {
            alertHelper.addAlert(
                {
                    title: "SCANNED DEVICE ITEM DOESN'T PASS VALIDATION",
                    body: valid.reasonForFailure
                }
            )
            return
        }
        if (exists) return; // Already Added
        setDevicesToShip((prev) => {
            return [device, ...prev]
        })
    }

    const addAccessoryForm = () => {
        setAccessoriesToShip([...accessoriesToShip,{ 
            accessory_item_id: '',
            quantity: 0
        }])
    }

    const handleAccessoryChange = (index, key, value) => {
        let copy = [...accessoriesToShip];
        copy[index][key] = value;
        setAccessoriesToShip(copy);
    }
    
    const handleRemoveAccessoryItem = (index) => {
        let copy = [...accessoriesToShip];
        copy.splice(index, 1);
        setAccessoriesToShip(copy);
    }

    const cancelAssembly = () => {
        setInSubAssemblyMode(false);
    }

    const removeSubAssembly = (index) => {
        let newArr = [...subAssembliesToShip];
        newArr.splice(index, 1);
        setSubAssembliesToShip(newArr);
    }

    const addSubAssemblyToList = (assembly) => {
        setSubAssembliesToShip(prev => {
           return [...prev, assembly];
        })

        setInSubAssemblyMode(false);
    }

    const removePartFromList = (index) => {
        let newArr = [...partsToShip];
        newArr.splice(index, 1);
        setPartsToShip(newArr);
    }

    const buildSubAssemblyAdd = () => {
        return (
            <AddSubAssembly cancel={cancelAssembly} save={addSubAssemblyToList}/>
        )
    }

    const buildStandAloneParts = () => {
        if (!partsToShip.length) return null;

        return (
            <div>
                <Card bg="dark" text='white' className="mb-5">
                    <Card.Header>
                        <h5>Standalone Parts</h5>
                    </Card.Header>
                    <Card.Body>
                        
                            {
                                partsToShip.map((p, i) => {
                                    return (
                                        <Row key={i}>
                                            <Col>
                                                ({p.serial_number})
                                            </Col>
                                            <Col>
                                                {p.model} | {p.part_name}
                                            </Col>
                                            <Col>
                                                <Button variant='danger' onClick={evt => removePartFromList(i)}>REMOVE</Button>
                                            </Col>
                                        </Row>
                                       
                                    )
                                })
                            }
                       
                    </Card.Body>
                </Card>
            </div>
        )
    }

    const buildMainSection = () => {
        if (inSubAssemblyMode) return buildSubAssemblyAdd();
        return (
            <Modal.Body className="spartan-modal">
                <AlertContainer/>
        <div className="text-center">
            <div className="qr-reader-container">
                <h5>Scan Devices To Ship</h5>
                <div style={{width: 300, height: 200, margin: '0 auto'}}>
                    <QrReader constraints={{facingMode: 'environment'}}  onResult={decipher} style={{ width: '100%' }}/>
                    <br/>
                </div>

                <br/> <br/> <br/>
                <h5>You can also use the barcode scanner. Click the input box below and scan the QR Code</h5>
                    <Form onSubmit={onBarcodeScan}>
                        <Form.Control value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                    </Form>
                
            </div>

            <hr className="mb-5 mt-5"/>
            <Card bg="dark" text="white" className="mb-5">

                <Card.Header>
                    <h5>Items to Ship</h5>
                </Card.Header>

                <Card.Body>
                    {devicesToShip.map((d, i) => {
                        
                        return (
                            <Row>
                                <Col>
                                    ({d.device_serial_number || d.serial_number})
                                </Col>
                                <Col>
                                    {d.model} | {d.device_line} | {d.device_name}
                                </Col>
                                <Col>
                                    <Button variant="danger" onClick={() => removeItem(i)}>Remove</Button>
                                </Col>
                                <hr className="mt-3"/>
                            </Row>
                            
                        )
                    })}
                </Card.Body>
            </Card>
            
            
            {buildStandAloneParts()}
            
            <Card bg="dark" text="white">
                <Card.Header>
                    <h5>Sub-Assemblies</h5>
                </Card.Header>
                <Card.Body>
                    <ul>
                    {subAssembliesToShip.map((s, i) => {
                        return <li key={i}>{s.model} | {s.assembly_name} <br/>
                        {s.parts.length} Parts Included <br/>
                        <Button variant="danger" onClick={() => removeSubAssembly(i)}>Remove From Bundle</Button></li>
                    })}
                    </ul>
                   
                </Card.Body>
                <Card.Footer className="mt-4">
                    <Button onClick={() => setInSubAssemblyMode(true)}>ADD SUB-ASSEMBLY TO BUNDLE</Button>
                </Card.Footer>
            </Card>
            <hr/>
            <Card bg="dark" text="white">
                <Card.Header>
                    <h5>Accessory Items To Ship</h5>
                </Card.Header>

                <Card.Body>
                    <Container>
                {accessoriesToShip.map((ats, i) => {
                return (
                    <Row key={i}>
                      <Col >
                      <Form.Group>
                        <Form.Label>Accessory</Form.Label>
                        <Form.Select value={ats.accessory_item_id} onChange={evt => handleAccessoryChange(i, 'accessory_item_id', evt.target.value)}>
                            <option value={undefined}>SELECT AN ACCESSORY ITEM</option>
                            {appState.accessory.accessories.map((accessory, j) =>  {
                                return (
                                    <option key={j} value={accessory.id}>{`${accessory.part_number}|${accessory.description}`}</option>
                                )
                            })}
                        </Form.Select>
                      </Form.Group>
                      
                       </Col>

                       <Col>
                            <Form.Group>
                                <Form.Label>
                                    Quantity
                                </Form.Label>

                                <Form.Control type="number" value={ats.quantity} onChange={evt => handleAccessoryChange(i, 'quantity', evt.target.valueAsNumber)} />
                            </Form.Group>
                        
                        
                        </Col>

                        <Col>
                            <Form.Group>
                                <br/> 
                                <Button onClick={() => handleRemoveAccessoryItem(i)} variant="danger">Remove Accessory</Button>
                            </Form.Group>
                            
                        </Col>
                        
                   
                    </Row>
                )
            })}

                </Container>
                </Card.Body>

                <Card.Footer>
                    <Button className="mt-5" onClick={addAccessoryForm}>ADD ACCESSORY ITEM TO BUNDLE</Button>
                </Card.Footer>
            </Card>
           
            
            

            <Container>
                <hr/>
            <form onSubmit={saveShipment}>
                <Row>
                    
                    <Col>
                    <h6>Recipient</h6>
                    <input  name="recipient"
                            value={shippingAddress.recipient}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
                   <br/><br/>
                    <h6>Address Line One</h6>
                    <input  name="address_one"
                            value={shippingAddress.address_one}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
                <br/><br/>
                    <h6>Address Line Two</h6>
                    <input  name="address_two"
                            value={shippingAddress.address_two}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            />
<br/><br/>
                    <h6>City</h6>
                    <input  name="city"
                            value={shippingAddress.city}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/><br/>
                    <h6>State</h6>
                    <input  name="state"
                            value={shippingAddress.state}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/><br/>
                    <h6>Zipcode</h6>
                    <input  name="zipcode"
                            value={shippingAddress.zipcode}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/><br/>
                    <h6>Country</h6>
                    <input  name="country"
                            value={shippingAddress.country}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
                            <br/><br/>
                    </Col>

                    <Col>
                        <h6>Shipping From</h6>
                        <select value={bundleInformation.shipment_location_id} onChange={evt => setBundleInformation({...bundleInformation, shipment_location_id: evt.target.value})} required>
                                    <option></option>
                            {appState.testSite.sites.map((s, i) =>  {
                                return (
                                    <option key={i} value={s.id}>{s.site_city}, {s.site_state}, {s.site_country}</option>
                                )
                            })}
                        </select>
                        <br/> <br/>
                        <h6>Tracking Number (Optional)</h6>
                        <input  name="shipping_tracking_number"
                                value={bundleInformation.shipping_tracking_number}
                                type="text"
                                onChange={evt => setBundleInformation({...bundleInformation, [evt.target.name]: evt.target.value})} />
                        <br/> <br/>
                        <h6>Shipping Carrier (Optional)</h6>
                        <input name="shipping_carrier"
                               value={bundleInformation.shipping_carrier}
                               type="text"
                               onChange={evt => setBundleInformation({...bundleInformation, [evt.target.name]: evt.target.value})}
                                />
                        <br/> <br/>

                        <h6>Delivery Status</h6>
                        <select value={bundleInformation.delivery_status}
                                onChange={evt => setBundleInformation({...bundleInformation, delivery_status: evt.target.value})} 
                                required>
                            <option></option>
                            <option value="Awaiting Delivery">Awaiting Delivery</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Delivered">Delivered</option>
                        </select>
                    </Col>
                    
                    
                </Row>
                <Row>
                <hr className="mt-5"/>
                <Col>
                <h6>Purchase Info</h6>
                        <select value={bundleInformation.payment_info}
                                onChange={evt => setBundleInformation({...bundleInformation, payment_info: evt.target.value})}>
                            <option></option>
                            <option value="Zero Cost">Zero Cost</option>
                            <option value="PO Received">PO Received</option>
                            <option value="CC Information Received">CC Information Received</option>
                        </select>
                        <br/> <br/>

                        <h6>Purchase Order Number</h6>
                        <input type="text" 
                               value={bundleInformation.purchase_order_number} 
                               onChange={evt => setBundleInformation({...bundleInformation, purchase_order_number: evt.target.value})}/>
                        <br/> <br/>

                        <h6>Purchase Order URL</h6>
                        <input type="text"
                               value={bundleInformation.purchase_order_url}
                               onChange={evt => setBundleInformation({...bundleInformation, purchase_order_url: evt.target.value})}/>
                </Col>

                <Button disabled={isSaving || (!devicesToShip.length && !subAssembliesToShip.length && !partsToShip.length && !accessoriesToShip.length)} type="submit" className="mt-5">Save Bundle</Button>
                </Row>
                </form>
            </Container>
        </div>
        </Modal.Body>
        )
    }
    useEffect(() =>  {
        loadData();
    }, [])

    useEffect(() =>  {
        if (!scanned) return;
        attemptAddToArray(scanned);
    }, [scanned])
   

    return (
        <Modal  show={true} onHide={close} dialogClassName="modal-90w">
        <Modal.Header className="spartan-modal" closeButton>
            <Modal.Title className="spartan-modal">CREATE A SHIPPING BUNDLE</Modal.Title>
        </Modal.Header>
        {buildMainSection()}
        
        </Modal>
    )
}