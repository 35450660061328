import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SpartanApi from '../../helpers/spartan-api'
import { useNavigate } from 'react-router-dom';


export default function CreateUserModal ({closeCB, siteId}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));

    const [newUser, setNewUser] = useState(() => {
        return {
           first_name: '',
           last_name: '',
           email: '',
           user_password: '',
           test_site_id: siteId
        }
    });


    const createNewUser = async(evt) => {
        evt.preventDefault();
        await api.attemptAddSiteUser(newUser);
        closeCB();
    }

    if (!siteId) return null;

    return (
        <div className="spartan-modal">
            <form onSubmit={createNewUser}>
                <h6>First Name</h6>
                <input name="first_name"
                       type="text"
                       value={newUser.first_name}
                       onChange={evt => setNewUser({...newUser, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>Last Name</h6>
                <input name="last_name"
                       type="text"
                       value={newUser.last_name}
                       onChange={evt => setNewUser({...newUser, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>User Email Address</h6>
                <input name="email" 
                       type="email" 
                       value={newUser.email} 
                       onChange={evt => setNewUser({...newUser, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>User Password</h6>
                <input name="user_password" 
                       type="password" 
                       value={newUser.usser_password} 
                       onChange={evt => setNewUser({...newUser, [evt.target.name]: evt.target.value})} 
                       required />

               
                <button type="submit">Create New User</button>
            </form>
        </div>
    )
}