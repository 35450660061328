import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import {Card, Form, Button, Row, Col} from 'react-bootstrap'

export default function UpdateTestCaseModal({test_case, close}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}))
    const [testCase, setTestCase] = useState(() => {
        return {...test_case,
        locations_to_add: [],
        locations_to_remove: []}
    });
    const appState = useSelector(s => s)

    const deviceTypes = useSelector(s => s.device.deviceTypes);
    console.log(testCase)
    const save = async (evt) => {
        evt.preventDefault();
        await api.attemptUpdateDeviceTestCase(testCase);
        close();
    }

    const changeDeviceType = (value) => {
        setTestCase({...testCase, device_type_id: value})
    }

    const onTestChange = (key, value, index) => {
        const tc = {...testCase};
        tc.test_case.tests[index] = {...tc.test_case.tests[index], [key]: value};
        setTestCase(tc);
    }

    const removeTest = (index) => {
        const tc = {...testCase};
        tc.test_case.tests.splice(index, 1);
        setTestCase(tc);
    }

    const addBlankTest = () => {
        const tc = {...testCase};
        tc.test_case.tests.push({
            title: '',
            description: '',
            passed: false,
            notes: ''
        })
        setTestCase(tc);
    }

    const addRemoveSite = (isChecked, site_id) => {
        let test_sites = Array.isArray(testCase.test_sites) ? [...testCase.test_sites] : [];
        if (!isChecked){
            test_sites = test_sites.filter(t => t.id != site_id);
            const locations_to_add = testCase.locations_to_add.filter(t => t != site_id);
            const locations_to_remove = [...testCase.locations_to_remove, site_id];
            setTestCase({...testCase, test_sites, locations_to_add, locations_to_remove});
            return;
        }

            test_sites = [...test_sites, {id: site_id}]
            const locations_to_add = [...testCase.locations_to_add, site_id];
            const locations_to_remove = testCase.locations_to_remove.filter(t => t != site_id);
            setTestCase({...testCase, test_sites, locations_to_add, locations_to_remove});
    }

    useEffect(() => {
        api.attemptGetTestSites();
    }, [])

    return (
        <Card bg="dark" text="white" className="text-center">

            <Card.Header>
                <h5>Updating The Test Case</h5>
            </Card.Header>
            <form onSubmit={save}>
            
            <h5>Test Name</h5>
            <input type="text"
                   value={testCase.test_name}
                   onChange={evt => setTestCase({...testCase, test_name: evt.target.value})}/>
                   
            <h5>Device Type</h5>
            <select value={testCase.device_type_id} onChange={evt => changeDeviceType(evt.target.value)} required>
                <option>Select a Device Type</option>
                {deviceTypes.map((dt, i) => {
                    return <option key={i} value={dt.id}>{`${dt.device_name}|${dt.model}`}</option>
                })}
            </select>
            <hr/>

            <h5 className="mt-5">Test Sites</h5>
            <p>Select the sites, where this test can be conducted.</p>
            <Row>
                {
                    appState.testSite.sites.map((site, i) => {
                        let checked = Array.isArray(testCase.test_sites) ?  testCase.test_sites.find(ts => ts.id == site.id) : false
                        checked = checked ? true : false
                        return (
                            <Col key={i} xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                                <Form.Check checked={checked} onChange={evt => addRemoveSite(evt.target.checked, site.id)}/> <Form.Label>
                                    {`(${site.site_name})`} <br/>
                                    {site.site_city}, {site.site_state} {site.site_country}
                                </Form.Label>
                            </Col>
                        )
                    })
                }
            </Row>

            <h5>Tests</h5>
            {
                testCase.test_case.tests.map((t, i) => {
                    return (
                        <div key={i} className="spartan-test-form">
                            <strong>Title</strong>
                            <p>(Title of the test)</p>
                            <input type="text" 
                                   value={t.title}
                                   onChange={evt => onTestChange("title", evt.target.value, i)}
                                    required/>
                            <br/>
                            <strong>Description</strong>
                            <p>Description of the test</p>
                            <textarea
                                   value={t.description}
                                   onChange={evt => onTestChange("description", evt.target.value, i)} 
                                   required
                                   />
                            <br/>
                            <button onClick={() => removeTest(i)}>REMOVE TEST</button>
                            <hr/>
                        </div>
                    )
                })
            }

            <button onClick={addBlankTest}>Add Another Test</button>
            <br/>
            <button className="btn btn-primary" type="submit">Save To Device</button>
            </form>
            </Card>
    )
}