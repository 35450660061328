import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    sites: (state = [], action) => {
        switch(action.type) {
            case ACTIONS.SET_TEST_SITES: {
                return action.payload;
            }
            default: return state;
        }
    },
    userSites: (state = [], action)=> {
        switch(action.type){
            case ACTIONS.SET_USER_SITES: {
                return action.payload;
            }
            default: return state;
        }
    },
    siteUsers: (state = {}, action) => {
        switch(action.type) {
            case ACTIONS.SET_SITE_USERS: {
                return {
                    ...state,
                    [action.payload.test_site_id]: action.payload.users
                }
            }
            default: return state;
        }
    }
})