import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {Table, Container} from 'react-bootstrap';
import SpartanApi from '../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';
import AlertHelper from '../helpers/alert-helper';
import moment from 'moment';

export default function Business () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState (() => new SpartanApi({dispatch, navigate}));
    const devices = useSelector(s => s.business.devicesUnderWarranty)
    const loadData = async() => {
        await api.attemptGetDevicesUnderWarranty();
    }

    useEffect(() => {
        loadData()
    }, [
    ])

    console.log(devices)
    return (
        <Container>
            <Table variant="dark">
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Model</th>
                        <th>Device Name</th>
                        <th>Device Line</th>
                        <th>Warranty Terms</th>
                        <th>Warrant Expiration Date</th>
                        <th>Customer First Name</th>
                        <th>Customer Last Name</th>
                    </tr>
                </thead>

                <tbody>
                    {devices.map((d, i) => {
                        return (
                            <tr key={i}>
                                <td>{d.serial_number}</td>
                                <td>{d.model}</td>
                                <td>{d.device_name}</td>
                                <td>{d.device_line}</td>
                                <td>{d.default_warranty_years}</td>
                                <td>{moment(d.warranty_end_date).format('MM/DD/YYYY')}</td> 
                                <td>{d.customer_first_name}</td>
                                <td>{d.customer_last_name}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    )
}