import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import useInterval from '../hooks/use-interval';
import moment from 'moment';
import { Pie } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);


export default function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const testSites = useSelector(s => s.testSite.sites);
    const [siteId, setSiteId] = useState(() => null);
    const dashboard = useSelector(s => s.dashboard);
    const deviceTypes = useSelector(s => s.device.deviceTypes)
    const [currentTime, setCurrentTime ]= useState(s => moment().format("MM/DD/YYYY hh:mm:ss a"))

    const loadInitialData = async() => {
        try {
            await Promise.all([
                api.attemptGetTestSites(),
                api.attemptGetDeviceTypes()
            ])
        } catch(err) {
            
        }
    }

    const loadChartData = async (id) => {
        if (!id) return;
        try {
            await Promise.all([
                api.attemptGetDashboardDevicesAtSiteStats(id),
                api.attemptGetDashboardDevicesInTesting(id),
                api.attemptGetDashboardDevicesPassedTesting(id),
                api.attemptGetDashboardPassFailRatio(id),
                api.attemptGetDashboardDevicesShipped(id),
                api.attemptGetDashboardDeviceTypePassToFailRatio(id),
                api.attemptGetDashboardShippingStatsByDeviceType(id)
            ])
            console.log("DATA REFRESHED")
            setCurrentTime(moment().format("MM/DD/YYYY hh:mm:ss a"));
        } catch(err) {
            console.log("UNABLE TO REFRESH DATA")
        }
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    
    useInterval(() => {
        loadChartData(siteId);
    }, 60000); //REFRESH EVERY 60 SECONDS

    const handleSiteChange = (id) => {
        if (!id || !id.length) return;
        setSiteId(id);
        loadChartData(id);
    }

    const buildLowerThird = () => {
        let site = testSites.find((ts) => ts.id == siteId)
        
        return (
        <div style={{position: 'absolute', top: 75, right: 20, width: 300, textAlign: 'right'}}>
            <strong>{site.site_name} - ({`${site.site_city}, ${site.site_state}`})</strong> <br/>
            <strong>Last Updated:</strong> <br/>
            <strong>{currentTime}</strong>
        </div>
        )
    }

    const buildDevicesAtSite = () => {
        if (!dashboard.devices_at_site) return null;
        let totalDevices = dashboard.devices_at_site.reduce((t, v) => t + Number(v.device_count), 0)
        return (
        <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Card bg="dark" color="white" >
                        <Card.Header className="text-center">
                            <h5>Devices Scanned</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-center">
                                <h4>
                                    {totalDevices}
                                </h4>
                                <h6>Total Devices</h6>
                            </div>
                            <hr/>
                            
                            
                            
                                
                            {dashboard.devices_at_site.map((d, i) => {
                                    return (
                                        <div className="text-center mt-3">
                                            <h4>{d.device_count}</h4>
                                            <h6>{d.device_line}<br/>{d.model}-{d.device_name}</h6>
                                        </div>
                                    ) 
                            })}
                            
                            
                        </Card.Body>
                    </Card>
                </Col>
        )
    }

    const buildDevicesInTesting = () => {
       
        if (!dashboard.devices_in_testing) return null;
        const totalDevices = dashboard.devices_in_testing.reduce((t, v) => t + Number(v.count) , 0)
        return (
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Card bg="dark" color="white" >
                        <Card.Header className="text-center">
                            <h5>Devices in Testing</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-center">
                                <h4>
                                    {totalDevices}
                                </h4>
                                <h6>Total Devices</h6>
                            </div>
                            <hr/>
                            
                                {dashboard.devices_in_testing.map((d, i) => {
                                    const deviceType = deviceTypes.find(dt => dt.id == d.device_type_id)

                                    return (
                                        <div className="text-center mt-3">
                                            <h4>{d.count}</h4>
                                            <h6>{deviceType.device_line}<br/>{deviceType.model}-{deviceType.device_name}</h6>
                                        </div>
                                    )
                                   
                                })}
                            
                        </Card.Body>
                    </Card>
                </Col>
        )
    }

    const buildDevicesPassedTesting = () => {
        if (!dashboard.devices_passed_testing) return null;
        if (!dashboard.devices_in_testing) return null;
        const totalDevices = dashboard.devices_passed_testing.reduce((t, v) => t + Number(v.count) , 0)
        return (
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Card bg="dark" color="white" >
                        <Card.Header className="text-center">
                            <h5>Devices Passed Testing</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-center">
                                <h4>
                                    {totalDevices}
                                </h4>
                                <h6>Total Devices</h6>
                            </div>
                            <hr/>
                           
                                {dashboard.devices_passed_testing.map((d, i) => {
                                    const deviceType = deviceTypes.find(dt => dt.id == d.device_type_id)
                                    return (
                                        <div className="text-center mt-3">
                                            <h4>{d.count}</h4>
                                            <h6>{deviceType.device_line}<br/>{deviceType.model}-{deviceType.device_name}</h6>
                                        </div>
                                    )
                                   
                                })}
                            
                        </Card.Body>
                    </Card>
                </Col>
        )
    }

    const buildPassToFailRatio = () => {
        if (!dashboard.device_types_pass_to_fail_ratio) return null;

        const charts = dashboard.device_types_pass_to_fail_ratio.map((d, i) => {
            const data = {
                labels: ['Passes', 'Fails'],
                datasets: [
                    {
                        data: [d.passes, d.fails],
                        backgroundColor: ['green', 'red'],
                        hoverBackgroundColor: ['darkgreen', 'darkred']
                    }
                ]
            }
            const passPercentage = (Number(d.passes) / Number(d.total) * 100).toFixed(2);

            return (
                <Col lg={6} className="mt-3 text-center">
                    
                    <Pie data={data} />
                    <div className="mt-3">

                    <h5>{passPercentage} %</h5>
                        <strong>Passing</strong> <br/><br/>
                        <strong>{d.device_line}</strong> <br/>
                        <strong>{d.model}-{d.device_name}</strong>
                        
                    </div>
                </Col>
            )
        })
       
        return (
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                    <Card bg="dark" color="white" >
                        <Card.Header className="text-center">
                            <h5>Testing Pass/Fail Ratio</h5>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                            <Row>
                            {charts}
                        </Row>
                            </Container>
                        
                        
                        
                        </Card.Body>
                    </Card>
                </Col>
        )
    }

    const buildDevicesShipped = () => {
        if (!dashboard.devices_shipped || !dashboard.devices_shipped_by_device_type) return null;
        return (
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12} className="mt-5">
                    <Card bg="dark" color="white" >
                        <Card.Header className="text-center">
                            <h5>Devices Shipped</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-center">
                                <h4>
                                    {dashboard.devices_shipped.total_in_queue}
                                </h4>
                                <h6>Total Devices</h6>
                            </div>
                            <hr/>
                            {dashboard.devices_shipped_by_device_type.map((d, i) => {
                                return (
                                <div className="mt-4 text-center">
                                    <h4>{d.total_in_queue}</h4>
                                    <h6>{d.device_line}</h6>
                                    <h6>{d.model}-{d.device_name}</h6>
                                </div>
                                )
                            })}
                            
                            
                        </Card.Body>
                    </Card>
                </Col>
        )
    }

    if (!siteId) {
        return (
            <Container>
                <Card bg="dark" color="white" className="text-center">
                    <Card.Header>
                        <h4>Please choose a site</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form.Select value={siteId} onChange={evt => handleSiteChange(evt.target.value)}>
                            <option></option>
                            {testSites.map((ts, i) => {
                                return <option key={i} value={ts.id}>{ts.site_name} - {ts.site_city}, {ts.site_state}</option>
                            })}
                        </Form.Select>
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    
    return (
        <Container>
            <Row>
                {buildDevicesAtSite()}
                {buildDevicesInTesting()}
                {buildDevicesPassedTesting()}
                {buildPassToFailRatio()}
                {buildDevicesShipped()}
                {buildLowerThird()}
            </Row>
        </Container>
    )
}