import {Modal, Button} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useState} from 'react';
import SpartanApi from '../../helpers/spartan-api';
import moment from 'moment'

export default function FulFillModal({mode, id, orderData, close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [isSubmitting, setIsSubmitting] = useState(() => false);

    let section = null;

    const fulfillSalesOrder = async () => {
        setIsSubmitting(() => true);
        await api.attemptFulfillSalesOrder({sales_order_id: id});
        close();
    }

    const fulfillPurchaseOrder = async () => {
        setIsSubmitting(() => true);
        await api.attemptFulfillPurchaseOrder({purchase_order_id: id});
        close();
    }

    const buildCustomerData = () => {
        if (!orderData.full_request || !orderData.full_request.customerShipTo) return null;
        let customerToShipTo = orderData.full_request.customerShipTo.split('\n');
        return (
            <div className="mt-3">
                <h6>Customer Data</h6>
                {customerToShipTo.map((c, i) => {
                    return (
                        <div key={i}>
                            {c}
                        </div>
                    )
                })}
            </div>
        )
       
    }

    const buildLineItems = () => {
        if (!orderData.line_items || !orderData.line_items.length) return null;
        return (
            <div className="mt-3">
                <h6>Line Items</h6>
                <ul>
                    {orderData.line_items.map((li, i) => {
                        return (
                            <li key={i}>
                                {li.item_name} - {li.quantity} units
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const buildAcknowledgeButton = () => {
        if (orderData.fulfilled_on){
            return (
                <div className="mt-4 text-center">
                    <hr/>
                    This order was {mode == 'sales' ? 'fulfilled on': 'acknowledged on'} {moment(orderData.fulfilled_on).format('MM/DD/YYYY hh:mma')}
                </div>
            )
        }

        if (mode == 'sales') {
            return (
                <div className="mt-4 text-center">
                    <hr/>
                    <Button disabled={isSubmitting} onClick={fulfillSalesOrder}>{!isSubmitting ? 'Click to mark the order as Fulfilled': 'Submitting Request... Please Wait'}</Button>
                </div>
            )
        }

        return (
            <div className="mt-4 text-center">
                    <hr/>
                    <Button disabled={isSubmitting} onClick={fulfillPurchaseOrder}>{!isSubmitting ? 'Click to mark the order as Fulfilled': 'Submitting Request... Please Wait'}</Button>
            </div>
        )

    }

    if (mode == 'sales'){
        section = (
            <div>
                <strong>Sales Order Id: </strong> <span>{orderData.sales_order_id}</span> <br/>
                <strong>Transaction Number: </strong> <span>{orderData.transaction_number}</span> <br/>
                <strong>Customer Id: </strong> <span>{orderData.external_customer_id}</span> <br/>
                <strong>Added to Armory On: </strong> <span>{moment(orderData.created_on).format('MM/DD/YYYY hh:mma')}</span>
                {buildCustomerData()}
                {buildLineItems()}
                {buildAcknowledgeButton()}
            </div>
        )
    } else {
        section = (
            <div>
                <strong>Purchase Order Id: </strong> <span>{orderData.purchase_order_id}</span> <br/>
                <strong>Transaction Number: </strong> <span>{orderData.transaction_number}</span> <br/>
                <strong>Vendor Id: </strong> <span>{orderData.external_vendor_id}</span> <br/>
                <strong>Added to Armory On: </strong> <span>{moment(orderData.created_on).format('MM/DD/YYYY hh:mma')}</span>
                {buildLineItems()}
                {buildAcknowledgeButton()}
            </div>
        )
    }

    if (!id) return null;

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal" closeButton>
                {mode == 'sales' ? 'Acknowledging Sales Order': 'Acknowledging Purchase Order'}
            </Modal.Header>

            <Modal.Body className="spartan-modal">
                {section}
            </Modal.Body>
        </Modal>
    )
}