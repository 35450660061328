import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';
import {Container, Card, Row, Button} from 'react-bootstrap';
import CreateSubAssemblyPartTypeModal from './modals/CreateSubAssemblyPartTypeModal';

export default function SubAssemblyPartTypes(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const subAssemblyPartTypes = useSelector(s => s.subAssembly.subAssemblyPartTypes);
    const [modalOpen, setModalOpen] = useState(() => false);

    const load = async() => {
        await api.attemptGetSubAssemblyPartTypes();
        await api.attemptGetSubAssemblyTypes();
    }

    const closeModal = async() => {
        await load();
        setModalOpen(false);
    }

    useEffect(() => {
        load();
    }, []);


    let modal = null;

    if (modalOpen){
        modal = <CreateSubAssemblyPartTypeModal close={closeModal}/>
    }

    if (!subAssemblyPartTypes || !subAssemblyPartTypes.length) {
        return (
            <Container>
                {modal}
                 <Card bg="dark" color="white">
                    <Card.Header>
                        NO SUB ASSEMBLY PART TYPES EXIST
                    </Card.Header>
                    <Card.Body>
                        <Button onClick={() => setModalOpen(true)}>Click Here</Button> to create one
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            {modal}
            <Button className="mb-4" onClick={() => setModalOpen(true)}>CREATE PART TYPE</Button>
            <table className="table table-dark">
                <thead>
                    <tr>
                        <th>Model</th>
                        <th>Part name</th>
                    </tr>
                </thead>
                <tbody>
                    {subAssemblyPartTypes.map((part, i) => {
                        return( 
                            <tr key={i}>
                                <td>{part.model}</td>
                                <td>{part.part_name}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </Container>
    )
}