import {Modal, Button, Form, Table} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';

export default function AddOrganizationLicensesModal({close, organization}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const toolData = useSelector(s => s.externalTool);
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const [newLicenseData, setNewLicenseData] = useState(() => {
        return {
            organization_id: organization.id,
            dollars: 0,
            cents: 0,
            total_price_in_cents: 0,
            license_items: [
                {
                    profile_id: undefined,
                    quantity: 0
                }
            ]
        }
    });

    const addLicenseItemRow = () => {
        const copy = {...newLicenseData};
        const li   = copy.license_items;
        li.push({profile_id: undefined, quantity: 0});
        copy.license_items = li;
        setNewLicenseData(copy);
    }

    const removeLicenseItemRow = (index) => {
        const copy = {...newLicenseData};
        const li   = copy.license_items;
        li.splice(index, 1);
        copy.license_items = li;
        setNewLicenseData(copy);
    }

    const handleChange = (index, key, value) => {
        const copy = {...newLicenseData};
        const li   = copy.license_items;
        li[index][key] = value
        copy.license_items = li;
        setNewLicenseData(copy);
    }

    const loadData = async () => {
        await api.attemptGetExternalConfigToolPremiumProfiles()
    }

    const allocateLicenses = async(evt) => {
        evt.preventDefault();
        setIsSubmitting(() => true);
        const requestObj = {
            organization_id: organization.id,
            total_price_in_cents: (newLicenseData.dollars * 100) + newLicenseData.cents,
            license_items: newLicenseData.license_items
        }
        await api.attemptAddOrganizationLicenses(requestObj);
        close();
    }

    useEffect(() => {
        loadData();
    }, []);
    console.log("HERE: ", organization);
    console.log("toolData", toolData)
    if (!organization || !toolData.premiumProfiles || !toolData.premiumProfiles.length) return null;

    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header className="spartan-modal">
                ALLOCATE LICENSES TO {organization.organization_name}
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={allocateLicenses}>
                    <Form.Group>
                        <Form.Label>
                            Total Amount Paid
                        </Form.Label><br/>
                        $<input type="number" min={0} value={newLicenseData.dollars} onChange={evt => setNewLicenseData({...newLicenseData, dollars: evt.target.valueAsNumber})} />.<input type="number" min={0} max={99} value={newLicenseData.cents} onChange={evt => setNewLicenseData({...newLicenseData, cents: evt.target.value})}/>
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>
                            Licenses
                        </Form.Label>
                            <Table variant="dark" striped>
                                <thead>
                                    <tr>
                                        <th>License Type</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        newLicenseData.license_items.map((li, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <select value={li.profile_id} onChange={evt => {handleChange(i, 'profile_id', evt.target.value)}} required>
                                                            <option></option>
                                                            {toolData.premiumProfiles.map((p, j) => {
                                                                return (
                                                                    <option value={p.profile_id} key={j}>{p.profile_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="number" min={1} value={li.quantity} onChange={evt => handleChange(i, 'quantity', evt.target.valueAsNumber)} required/>
                                                    </td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => removeLicenseItemRow(i)}>REMOVE (-)</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                            <Button onClick={addLicenseItemRow}>Add Another Column</Button>
                    </Form.Group>
                    <hr className="mt-5"/>
                    <div className="text-center">
                        <Button disabled={isSubmitting} type="submit">Issue Licenses</Button>
                    </div>
                    
                </Form>
            </Modal.Body>
        </Modal>
    )
    
    
}