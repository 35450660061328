import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions'

export default combineReducers({
    data: (state = null, action) => {
        switch (action.type){
            case ACTIONS.SET_USER_DATA: {
                return action.payload;
            }
            default: return state;
        }
    },
    sasToken: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_SAS_TOKEN: {
                return action.payload;
            }
            default: return state;
        }
    },
    currentSite: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_LOCATION: {
                return action.payload;
            }

            default: return state;
        }
    }
});