import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    alerts: (state = [], action) => {
        switch(action.type){
            case ACTIONS.ADD_ALERT: {
            
                return [action.payload, ...state];
            }
            case ACTIONS.REMOVE_ALERT: {
                const newState = [...state];
                newState.splice(action.payload, 1);
                return newState;
            }
            case ACTIONS.CLEAR_ALERTS: {
                return [];
            }
            default: return state;
        }
    }
})