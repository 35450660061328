import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import SpartanApi from "../helpers/spartan-api";
import CreateDeviceTypeModal from "./modals/CreateDeviceTypeModal";
import { BlobServiceClient } from '@azure/storage-blob';
import { Button } from "react-bootstrap";
import UpdateDeviceTypeModal from "./modals/UpdateDeviceTypeModal";

export default function DeviceTypes(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const deviceTypes = useSelector(s => s.device.deviceTypes);
    const [createDeviceTypeModalOpen, setCreateDeviceTypeModalOpen] = useState(() => false);
    const [updateDeviceTypeModalOpen, setUpdateDeviceTypeModalOpen] = useState(() => false);
    const [selectedDeviceType, setSelectedDeviceType] = useState(() => null);
    const [files, setFiles] = useState(() => {return {}});
    const userData   = useSelector(s => s.user);
  

    const closeModal = async() => {
        setUpdateDeviceTypeModalOpen(() => false);
        setCreateDeviceTypeModalOpen(() => false);
        setSelectedDeviceType(() => null);
        await api.attemptGetDeviceTypes();
    }
    const loadData = async () => {
        await api.attemptGetDeviceTypes();
    }

    const handleDTUpdate = (dt) => {
        setSelectedDeviceType(() => dt);
        setUpdateDeviceTypeModalOpen(() => true)
    }

    useEffect(() => {
        loadData();
    }, []);

    if (createDeviceTypeModalOpen) return <CreateDeviceTypeModal closeCB={closeModal}/>

    if (!deviceTypes.length) {
        return (
            <div>
                No Device Types have been created at this time... Please <button onClick={() => setCreateDeviceTypeModalOpen(true)}>Create One</button>
            </div>
        )
    }

    const uploadFile = async(index, id) => {
        if (!files[index]) return;
        await api.attemptGetToken();
        console.log("We are golden... bum bum bum")
        if (!userData.sasToken) return;
        console.log("We got a token")
        console.log("Sas Token: ", userData.sasToken);
        const blobService = new BlobServiceClient('https://spartanproductiontest.blob.core.windows.net/?' + userData.sasToken);
        const containerClient = blobService.getContainerClient('configfiles');
        await containerClient.createIfNotExists({access: 'container'});
        console.log("We Get Here")
        const blobClient = containerClient.getBlockBlobClient(files[index].name);
        const options = {blobHTTPHeaders: {blobContentType: files[index].type}};
        const uploadedData = await blobClient.uploadBrowserData(files[index], options);
        console.log(id);
        console.log("File Uploaded: ", uploadedData);
        await api.attemptSaveDeviceConfigs({device_type_id: id,  download_url:`https://spartanproductiontest.blob.core.windows.net/configfiles/${files[index].name}` });
        console.log("SAVED")
        setFiles({...files, [index]: undefined});
        loadData();
    }

    let modal = null;

    if (updateDeviceTypeModalOpen) {
        modal = <UpdateDeviceTypeModal deviceType={selectedDeviceType} close={closeModal} />
    }

    return (
        <div className="text-center">
            {modal}
            <h4 className="text-center">Device Types</h4>
            <table className="table table-dark">
                <thead>
                    <tr>
                        <th>Device Line</th>
                        <th>Device Name</th>
                        <th>Device Model</th>
                        <th>Device Type Data Set</th>
                        <th>Device Type Data Upload</th>
                        <th>Mechanical Drawing Link</th>
                        <th>Electrical Link</th>
                        <th>Summary Text</th>
                    </tr>
                </thead>

                <tbody>
                    {deviceTypes.map((dt, i) => {
                        return(
                            <tr key={i}>
                                <td>{dt.device_line}</td>
                                <td>{dt.device_name}</td>
                                <td>{dt.model}</td>
                                <td>{dt.device_configs && dt.device_configs.length ? "Configs Set": "Not Yet Set"}</td>
                                <td>
                                        <input type="file" onChange={evt => setFiles({...files, [i]: evt.target.files[0]})}/>
                                        {files[i] ? <button onClick={() => uploadFile(i, dt.id)}>Save File</button>: null}
                                </td>
                                <td>{dt.mechanical_drawing_link}</td>
                                <td>{dt.electrical_link}</td>
                                <td>{dt.summary}</td>
                                <td><Button onClick={() => handleDTUpdate(dt)}>Edit</Button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <button className="btn btn-primary mx-auto" onClick={() => setCreateDeviceTypeModalOpen(true)}>Create Device Type</button>
        </div>
    )
}