import { spartanUploadHelper } from "../../helpers/upload-helper";
import SpartanApi from "../../helpers/spartan-api";
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Form, Button} from 'react-bootstrap';

export default function UploadECTVersionModal({close}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}))
    const [file, setFile] = useState(() => null);
    const [formData, setFormData] = useState(() => {
        return {
            version: '',
            url: undefined,
            notes: undefined
        }
    });
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const appState = useSelector(s => s);

    const onSave = async (evt) => {
        evt.preventDefault();
        if (!file) return;
        setIsSubmitting(true);
        let requestObj = {...formData};
        await api.attemptGetToken();
        requestObj.url = await spartanUploadHelper({sasToken: appState.user.sasToken, containerName: 'externaltoolwindows',file});
        await api.attemptUploadConfigurationVersion(requestObj);
        close();
    }

    return (
        <Modal size="xl" show={true} onHide={close}>
            <Modal.Header className="spartan-modal">
                Upload a new version of the configuration tool.
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={onSave}>
                    <Form.Group>
                        <Form.Label>
                            Upload EXE File
                        </Form.Label>
                        <input type="file" accept=".exe" onChange={evt => setFile(evt.target.files[0])} required/>
                    </Form.Group>

                    <Form.Group className="mt-5">
                        <Form.Label>
                            Version
                        </Form.Label>
                        <Form.Control type="text" value={formData.version} onChange={evt => setFormData({...formData, version: evt.target.value })} required/>

                    </Form.Group>

                    <Form.Group className="mt-5">
                        <Form.Label>
                            Notes
                        </Form.Label>
                        <textarea className="form-control" value={formData.notes} onChange={evt => setFormData({...formData, notes: evt.target.value})} required/>
                    </Form.Group>

                    <Button  type="submit">Save</Button> <br/>
                    {!isSubmitting ? '': <p>* File Upload Could Take Awhile... please leave browser open and wait.</p>}
                </Form>
            </Modal.Body>
        </Modal>
    )
}