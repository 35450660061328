import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SpartanApi from "../../helpers/spartan-api";
import { QrReader } from "react-qr-reader";
import * as QrHelpers from '../../helpers/qr-reader';
import { Button, Card, Form } from "react-bootstrap";

export default function CreateDeviceModal({closeCB}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const deviceTypes = useSelector(s => s.device.deviceTypes);
    const appState    = useSelector(s => s);
    const [barcode, setBarcode] = useState(() => '');

    
    const [qrData, setQrData] = useState(() => {
        return {
            model: undefined,
            serialNumber: undefined
        }
    });

    const onBarcodeScan = (evt) => {
        evt.preventDefault();
        if (!barcode) return;
        const data = QrHelpers.decipherQrData(barcode);
        setQrData(data);
    }

    const decipher = (result, error) => {
        if (error) return;
        const data = QrHelpers.decipherQrData(result.text);
        setQrData(data);
    }

    const loadData = async () => {
        await api.attemptGetSitesByUser();
    }

    useEffect(() => {
        loadData();
    }, [])

    const createNewDevice = async (serialNumber, deviceTypeId) => {
        const requestData = {
            serial_number: serialNumber,
            device_type_id: deviceTypeId,
            current_site: appState.user.currentSite.id
        }

        await api.attemptCreateDevice(requestData);
        closeCB();
    }

    const displayDeviceData = () => {
        if (!qrData.model) return null;
        const deviceData = deviceTypes.filter(dt => dt.model === qrData.model)[0];
        if (!deviceData) return <div><h4>Device Type not recognized</h4></div>
        return (
            <div className="text-center">
                <h6>Serial Number</h6>
                <span>{qrData.serialNumber}</span> 

                <h6>Model</h6>
                <span>{qrData.model}</span>

                <h6>Device Line</h6>
                <span>{deviceData.device_line}</span>

                <h6>Device name</h6>
                <span>{deviceData.device_name}</span>
                
                {appState.user.currentSite ? (
                    <div>
                        <h6>Current Location</h6>
                        <span>{appState.user.currentSite.site_city}, {appState.user.currentSite.site_state} {appState.user.currentSite.site_country}</span>
                    </div>
                ) : null}
                <br/> <br/>
                <Button variant="primary" onClick={() => createNewDevice(qrData.serialNumber, deviceData.id)}>
                    CREATE DEVICE
                </Button>
              
            </div>
        )
    }

    
    return (
        <Card bg="dark" text="white">
            <Card.Header className="text-center">
                <h3>Create a New Device</h3>
            </Card.Header>
            
            <div style={{width: 300, height: 200, margin: '0 auto'}}>
                <QrReader constraints={{facingMode: 'environment'}} onResult={decipher} style={{ width: '100%' }}/>
                <br/> <br/> <br/> <br/>
                
            </div>
            <br/> <br/><br/>
            <div style={{width: 300, margin: '0 auto'}}>
            <h5>You can also use the barcode scanner. Click the input box below and scan the QR Code</h5>
                        <Form onSubmit={onBarcodeScan}>
                            <Form.Control value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                        </Form>
            </div>
            
            <br/> 
            {displayDeviceData()}
        </Card>
    )
}