import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import SpartanApi from "../helpers/spartan-api";
import { Button } from "react-bootstrap";

export default function TesterDashboard() {
    const dispatch = useDispatch();
    
    const state = useSelector(s => s);
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));

    const loadUserData = async() => {
        await api.attemptCheckOnline();
        await api.attemptGetSitesByUser();
    }
    useEffect(() => {
        loadUserData();
    } , [])
    useEffect(() => {
        console.log(state)
    }, [state])
    return (
        <div className="card text-bg-dark text-center">
            <h3>What would you like to do? </h3>
            <Button className="mt-5" variant="dark" onClick={() => navigate('/device/test-device')}>TEST A DEVICE</Button>
            <Button className="mt-2" variant="dark" onClick={() => navigate('/shipping')}>SHIP DEVICES</Button>
            <Button className="mt-2" variant="dark" onClick={() => navigate('/device-list')}>FIND A DEVICE</Button>
        </div>
    )
}