import {Modal, Card, Button, Form} from 'react-bootstrap';
import SpartanApi from '../../helpers/spartan-api';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect} from 'react';

export default function UpdateDeviceTypeModal({deviceType, close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [dt, setDt] = useState(() => deviceType || {});
    const [changed, setChanged] = useState(() => { return {}});

    useEffect(() => {
        if (!deviceType) return;
        setDt(deviceType);
    }, [deviceType]);

    const updateDeviceType = async (evt) => {
        evt.preventDefault();
        if(!Object.keys(changed).length) {
            close()
            return;
        }

        await api.attemptUpdateDeviceType({...changed, id: dt.id});
        close();
        
    }

    const handleChange = async (key, value) => {
        setDt({...dt, [key]: value});
        setChanged({...changed, [key]: value});
    }

    if (!deviceType) return null;

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal">
                UPDATE THE DEVICE TYPE
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={updateDeviceType}>
                    <div className="mb-5">
                        <h6>Model: {dt.model}</h6>
                        <h6>Device Line: {dt.device_line}</h6>
                        <h6>Device Name: {dt.device_name}</h6>
                        (Model, Device Line, and Device Name CANNOT be updated)
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            Mechanical Drawing Link
                        </Form.Label>
                        <Form.Control type="text" 
                                      value={dt.mechanical_drawing_link}
                                      onChange={evt => handleChange('mechanical_drawing_link', evt.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            Electrical Link
                        </Form.Label>
                        <Form.Control type="text" 
                                      value={dt.electrical_link}
                                      onChange={evt => handleChange('electrical_link', evt.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            Summary
                        </Form.Label> <br/>
                        <textarea   className="form-control" value={dt.summary}
                                      onChange={evt => handleChange('summary', evt.target.value)} />
                    </Form.Group>
                    <div className='text-center'>
                        <Button type="submit">Update Device Type</Button>
                    </div>
                    
                </Form>
            </Modal.Body>
        </Modal>
    )
}