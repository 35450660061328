import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Navigation.scss'
import { faFileLines,faBarcode,faChartColumn,faBars, faCaretLeft, faCaretRight, faClipboard, faDolly, faMagnifyingGlass, faMicroscope, faTablet, faUserTie, faWarehouse, faUser } from '@fortawesome/free-solid-svg-icons';
import spartanLogo from '../../spartan-logo.png';
import spartanMobileLogo from '../../spartan-full-logo.png';

export default function Navigation(){
    const location = useLocation();
    const navigate = useNavigate();
    const [desktopOpen, setDesktopOpen] = useState(() => false);
    const [mobileOpen, setMobileOpen] = useState(() => false);
    const appState = useSelector(s => s)
    let desktopClassList = "d-none d-sm-block spartan-desktop-navigation"
    
    if (desktopOpen){
        desktopClassList = "d-none d-sm-block spartan-desktop-navigation open"
    }

    useEffect(() => {
        console.log("WE HAVE SOME DATA")
    }, [appState.user.data])

    if (!appState.user.data) return null; // NOT LOGGED IN
    if (location.pathname == "/" ||
        location.pathname == "/change-password" ||
        location.pathname == "/forgot-password" ||
        location.pathname == "/reset-passwword") return null; //LOGIN SCREEN
    
   
    return (
        <div>
            {/* MOBILE ONLY*/}
            <div className="d-block d-sm-none spartan-mobile-navigation">

                <div className="logo-container">
                    <img style={{width: 150}} src={spartanMobileLogo}/>
                </div>
                <button className="toggle-button"
                        onClick={() => setMobileOpen(!mobileOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                </button>

                {!mobileOpen ? null : <div className="mobile-menu">
                <div className="menu-option clicky" onClick={() => navigate('/checkin')}>
                    <FontAwesomeIcon icon={faBarcode} />
                    <strong className="label">Checkin Devices and Parts</strong>
                </div>
                <div className="menu-option clicky" onClick={() => navigate('/device/test-device')}>
                    <FontAwesomeIcon icon={faMicroscope} />
                    <strong className="label">Test Device</strong>
                </div>
                <div className="menu-option clicky" onClick={() => navigate('/shipping')}>
                    <FontAwesomeIcon icon={faDolly} />
                    <strong className="label">Shipping</strong>
                </div>
                <div className="menu-option clicky" onClick={() => navigate('/device-list')}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <strong className="label">Search</strong>
                </div>

                <div className="menu-option clicky" onClick={() => navigate('/business')}>
                    <FontAwesomeIcon icon={faUserTie} />
                    <strong className="label">Business</strong>
                </div>

                <div className="menu-option clicky" onClick={() => navigate('/inventory')}>
                    <FontAwesomeIcon icon={faWarehouse} />
                    <strong className="label">Inventory</strong>
                </div>

                <div className="menu-option clicky" onClick={() => navigate('/dashboard')}>
                    <FontAwesomeIcon icon={faChartColumn} />
                    <strong className="label">Dashboard</strong>
                </div>

                <div className="menu-option clicky" onClick={() => navigate('/report')}>
                    <FontAwesomeIcon icon={faFileLines} />
                    <strong className="label">Reports</strong>
                </div>

                <div className="menu-option clicky" onClick={() => navigate('/checkout')}>
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faBarcode} />
                    <FontAwesomeIcon icon={faUser} color="rgba(0,0,0,0.8)"  />
                </span> 
                    <strong className="label">Checkout Devices</strong>
                </div>

                {!appState.user.data.is_admin ? null : (
                    <div className="menu-option clicky" onClick={() => navigate('/admin')}>
                    <FontAwesomeIcon icon={faClipboard} />
                    <strong className="label">Admin</strong>
                </div>
                )}
                </div>}
            </div>

            {/* DESKTOP AND TABLET NAVIGATION */}
            <div className={desktopClassList}>
                <button className="toggle-button"
                        onClick={() => setDesktopOpen(!desktopOpen)}>
                            {desktopOpen ? <FontAwesomeIcon icon={faCaretLeft} /> : <FontAwesomeIcon icon={faCaretRight} />}
                </button>
                <div className="option-container">
                    <div className="menu-option clicky" onClick={() => navigate('/checkin')}>
                        <FontAwesomeIcon icon={faBarcode} />
                        <strong className="label">Checkin Devices and Parts</strong>
                    </div>
                    <div className="menu-option" onClick={() => navigate('/device/test-device')}>
                        <FontAwesomeIcon icon={faMicroscope} />
                        <strong className="label">Test Device</strong>
                    </div>
                    <div className="menu-option" onClick={() => navigate('/shipping')}>
                        <FontAwesomeIcon icon={faDolly} />
                        <strong className="label">Shipping</strong>
                    </div>
                    <div className="menu-option" onClick={() => navigate('/device-list')}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                        <strong className="label">Search</strong>
                    </div>
                    <div className="menu-option clicky" onClick={() => navigate('/business')}>
                        <FontAwesomeIcon icon={faUserTie} />
                        <strong className="label">Business</strong>
                    </div>

                    <div className="menu-option clicky" onClick={() => navigate('/inventory')}>
                        <FontAwesomeIcon icon={faWarehouse} />
                        <strong className="label">Inventory</strong>
                    </div>
                    
                    <div className="menu-option clicky" onClick={() => navigate('/dashboard')}>
                        <FontAwesomeIcon icon={faChartColumn} />
                        <strong className="label">Dashboard</strong>
                    </div>
                    <div className="menu-option clicky" onClick={() => navigate('/report')}>
                        <FontAwesomeIcon icon={faFileLines} />
                        <strong className="label">Reports</strong>
                    </div>
                    <div className="menu-option clicky" onClick={() => navigate('/checkout')}>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon={faBarcode} />
                        <FontAwesomeIcon icon={faUser} color="rgba(0,0,0,0.8)"  />
                    </span> <strong className="label">Checkout Devices</strong>
                    </div>
                    {!appState.user.data.is_admin ? null : (
                        <div className="menu-option" onClick={() => navigate('/admin')}>
                        <FontAwesomeIcon icon={faClipboard} />
                        <strong className="label">Admin</strong>
                    </div>
                    )}
                </div>
                

                <div className="logo-container">
                    <img src={spartanLogo} style={{width: 35}} />
                </div>
            </div>
        </div>
    )
}