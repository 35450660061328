import {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api';
import { PDFViewer } from '@react-pdf/renderer';
import ShippingReport from './ShippingReport';

export default function ShippingBundlePDF(){
    const pdfViewerRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {shippingBundleID} = useParams();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [bundle, setBundle] = useState(() => null);

    const loadData = async () => {
        const bundle =  await api.attemptGetShippingBundleById(shippingBundleID);
        if (!bundle) return;
        setBundle({...bundle});
    }

    useEffect(() => {
        if (!shippingBundleID) return;
        loadData();
    }, [shippingBundleID])


    if (!bundle) return null;
    return (
       
                <PDFViewer style={{width: '100vw', height: '100vh'}} >
                    <ShippingReport bundle={bundle}/>
                </PDFViewer>
       
    )
}