import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api';
import CreateDeviceTypeModal from './modals/CreateDeviceTypeModal';
import CreateDeviceModal from './modals/CreateDeviceModal';
import ViewTestResultsModal from '../shared/modals/ViewTestResultsModal';
import UpdateShippingModal  from '../admin/modals/UpdateShippingModal';
import { Col, Row, Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpDown, faDownLong, faUpLong } from '@fortawesome/free-solid-svg-icons';
import ViewDeviceModal from './modals/ViewDeviceModal';
const sortOptions = ['Tests Passed', 'Serial Number'];
const limits = ['5', '10', '25', '50', '100'];


export default function Devices(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [searchTerm, setSearchTerm] = useState(() => '')
    const [createDeviceTypeModalOpen, setCreateDeviceTypeModalOpen] = useState(() => false);
    const [createDeviceModalOpen, setCreateDeviceModalOpen] = useState(() => false);
    const [viewResultsModalOpen, setViewResultsModalOpen] = useState(() => false);
    const [deviceTypeFilterId, setDeviceTypeFilterId] = useState(() => undefined);
    const [testResultsDeviceId, setTestResultsDeviceId] = useState(() => undefined);
    const deviceData = useSelector(s => s.device);
    const [selectedSort, setSelectedSort] = useState(() => undefined);
    const [bundleId, setBundleId] = useState(() => undefined);
    const [shippingModalOpen, setShippingModalOpen] = useState(() => false)
    const [limit, setLimit] = useState(() => 100);
    const [offset, setOffset] = useState(() => 0);
    const sites = useSelector(s => s.testSite.sites);
    const [searchSet, setSearchSet] = useState(() => false)
    const [orderBys, setOrderBys] = useState(() => {
        return {
            column: undefined,
            direction: undefined
        }
    });
    
    const [selectedDevice, setSelectedDevice] = useState(() => null);
    const [viewDeviceModalOpen, setViewDeviceModalOpen] = useState(() => false);

    const [query, setQuery] = useState(() => {
        return {
            serialNumber: undefined,
            location: undefined,
            deviceTypeId: undefined,
            shippingStatus: undefined,
            startDate: undefined,
            endDate: undefined
        }
    })

    const searchDevices = async(evt) => {
        evt.preventDefault();
        setSearchSet(() => true);
        setOffset(0); //Will Trigger Search
        await loadDevices(true);
    }
   
    const nextPage =  () => {
        let newOffset = offset + 1;
        setOffset(newOffset);
    }



    const prevPage = () => {
        let newOffset = offset - 1;
        if (newOffset < 0) return;
        setOffset(newOffset);
       
    }

    const changeLimit = (newLimit) => {
        setLimit(newLimit);
        setOffset(0);
        
    }

    const loadDevices = async (useQuery) => {
        let filteredOrderBys = undefined;

        if (orderBys.column && orderBys.direction){
            filteredOrderBys = `${orderBys.column},${orderBys.direction}`
        }
        let params = {
            offset, limit, orderBys: filteredOrderBys
        }
        if (searchSet || useQuery){
            params = {
                offset, 
                limit, 
                orderBys: filteredOrderBys, 
                device_type_id: query.deviceTypeId, 
                location: query.location, 
                serial_number: query.serialNumber, 
                shipping_status: query.shippingStatus,
                start_date: !query.startDate ? null : moment(query.startDate).utc().format('MM/DD/YYYY'),
                end_date: !query.endDate ? null : moment(query.endDate).utc().format('MM/DD/YYYY'),
            }
        }
        await api.attemptGetDevices(params);
       
    };

    const loadData = async() => {
        await api.attemptGetDevices({offset, limit});
        await api.attemptGetDeviceTypes();
        await api.attemptGetTestSites();
    }

    const handleOpenResults = (deviceId) => {
        setTestResultsDeviceId(deviceId);
        setViewResultsModalOpen(true)
    }

    const buildDeviceRows = () => {
       let arr = [...deviceData.devices]
        console.log(arr);
       if (selectedSort == 'Tests Passed') {
         arr = arr.sort((a, b) => {
           const testA = Number(a.tests_pass_count);
           const testB = Number(b.tests_pass_count);
           return testB - testA
         })
       } else if (selectedSort == "Serial Number") {
         arr = arr.sort((a, b) => {
            const testA = Number(a.device_serial_number.split('-')[1])
            const testB = Number(b.device_serial_number.split('-')[1])
            
            return testA - testB
         })
       }

       return arr.map((d , i) => {
            const siteData = sites.find(s => s.id == d.current_site);
            return (
                <tr className="text-center" key={i}>
                    <td className="clicky" onClick={() => {
                        setSelectedDevice(d)
                        setViewDeviceModalOpen(() => true);
                    }}>{d.device_serial_number}</td>
                    <td>{d.model}</td>
                    <td>{d.device_name}</td>
                    <td>{d.device_line}</td>
                    <td>{d.firmware_version ? <a download={true} href={d.firmware_github_url}>{d.firmware_version}</a>: "Not Set"}</td>
                    <td>{d.configs_url ? <a download={true} href={d.configs_url}>Download Here</a>: "Not Set"}</td>
                    <td>{d.device_configs_url ? <a download={true} href={d.device_configs_url}>Download Here</a>: "Not Set"}</td>
                    <td>{`${d.tests_pass_count}/${d.test_count} Tests Passed`} <br/> 
                        {d.tests_pass_count > 0 ? <span onClick={() => handleOpenResults(d.device_id)} className="clicky">(View Results)</span> : null}
                    </td>
                    <td>{siteData ? siteData.site_name: ''}</td>
                    <td>{d.shipping_bundle && d.shipping_status.indexOf('Return') <= -1? <a className="clicky" onClick={() => handleShippingClick(d.shipping_bundle.id)}>{d.shipping_bundle.delivery_status || 'Awaiting Delivery'}</a> : d.shipping_status}</td>
                    <td>{moment(d.created_on).format('MM/DD/YYYY hh:mma')}</td>
                </tr>
            )
        })
    }
  
    const closeViewModal = () => {
        setViewDeviceModalOpen(() => false);
    }
    

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadDevices();
    }, [limit, offset, orderBys])


    const closeDeviceTestResultsModal = () => {
        setViewResultsModalOpen(false);
        setTestResultsDeviceId(undefined);
    }

    const closeDeviceTypeModal = () => {
        setCreateDeviceTypeModalOpen(false);
        loadData();
    }

    const closeDeviceModal = () => {
        setCreateDeviceModalOpen(false);
        loadData();
    }

    const onDeviceTypeFilterChange = async (evt) => {
        const val = evt.target.value
        setDeviceTypeFilterId(val);
        
        setLimit(100)
        setOffset(0);

        if (!val || val.toLowerCase() == 'show all devices') {
            await api.attemptGetDevices({limit: 100, offset: 0});
        } else {
            await api.attemptGetDevicesByDeviceType({deviceTypeId: val, limit: 100, offset:0});
        }
    }

    const search = async (evt) => {
        if (!searchTerm || !searchTerm.length || searchTerm.length < 2) return;
        await api.attemptSearchDeviceBySerialNumber(searchTerm)
    }

    const handleShippingClick = (id) => {
        setBundleId(id)
        setShippingModalOpen(true)
    }

    const closeShippingModal = () => {
        setShippingModalOpen(false);
        loadData();
    }

   
    let modal = null;
    if (viewResultsModalOpen) modal = <ViewTestResultsModal close={closeDeviceTestResultsModal} deviceId={testResultsDeviceId} />
    if (shippingModalOpen) modal = <UpdateShippingModal bundleId={bundleId} close={closeShippingModal}/>
    if (viewDeviceModalOpen) modal = <ViewDeviceModal device={selectedDevice} close={closeViewModal} />
    
    if (createDeviceTypeModalOpen) return <CreateDeviceTypeModal closeCB={closeDeviceTypeModal}/>
    if (createDeviceModalOpen) return <CreateDeviceModal closeCB={closeDeviceModal}/>
    /*
    if (!deviceData.deviceTypes.length) {
        return (
            <div>No DEVICE TYPES have been created, please 
                <button onClick={() => setCreateDeviceTypeModalOpen(true)}>Create One</button>
            </div>
        )
    }

    /*
    if (!deviceData.devices.length){
        return(
            <div>No DEVICES have been created, please 
                <button onClick={() => setCreateDeviceModalOpen(true)}>Create A Device</button>
            </div>
        )
    }
    */

    const changeOrderBy = async (key) => {
        let newOrderBys = {...orderBys};
        if(newOrderBys.column != key){
            newOrderBys.column = key;
            newOrderBys.direction = 'ASC';
        } else {
            //FLIP IT
            newOrderBys.direction = newOrderBys.direction == 'ASC' ? 'DESC' : 'ASC';
        }
        
        setOrderBys(() => {return newOrderBys});
    }

    const buildIcon = (key) => {
        if (orderBys.column != key){
            return <FontAwesomeIcon icon={faUpDown} />
        } else if (orderBys.direction == 'ASC'){
            return <FontAwesomeIcon icon={faUpLong} />
        } else {
            return <FontAwesomeIcon icon={faDownLong} />
        }
    } 

    

    return (
        <div>
            {modal}
            <Row>
                <Col>
                <Card bg="dark" text="white" className="mb-4">
                    <Card.Header>
                        Search Devices
                    </Card.Header>
                    <Form onSubmit={searchDevices}>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Form.Group>
                                <Form.Label>
                                    Device Type
                                </Form.Label>
                                <Form.Select 
                                        value={query.deviceTypeId}
                                        onChange={evt => setQuery({...query, deviceTypeId: evt.target.value})}>
                                        <option value={null}>Show All Devices</option>
                                        {
                                            deviceData.deviceTypes.map((dt, i) => {
                                                return (
                                                    <option key={i} value={dt.id}>{dt.device_line}|{dt.device_name}|{dt.model}</option>
                                                )
                                            })
                                        }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>
                                    Devices Currently Located At
                                </Form.Label>
                                <Form.Select value={query.location}
                                             onChange={evt => setQuery({...query, location: evt.target.value})}>
                                    <option value={null}>No Location Selected</option>
                                    {sites.map((s, i) => {
                                        return (
                                            <option value={s.id} key={i}>
                                                {s.site_name} - {s.site_city}, {s.site_state}, {s.site_country}
                                            </option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mt-4">
                                <Form.Label>
                                    Device Serial Number
                                </Form.Label>
                                <Form.Control value={query.serialNumber}
                                              onChange={evt => setQuery({...query, serialNumber: evt.target.value})}/>
                            </Form.Group>

                                </Col>

                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            Shipping Status
                                        </Form.Label>
                                        <Form.Select value={query.shippingStatus}
                                                     onChange={evt => setQuery({...query, shippingStatus: evt.target.value})}>
                                                <option value={null}>Any Shipping Status</option>
                                                <option value={'Awaiting Delivery'}>Awaiting Delivery</option>
                                                <option value={'Not Shipped'}>Not Shipped</option>
                                                <option value={'Shipped'}>Shipped</option>
                                                <option value={'Delivered'}>Delivered</option>
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mt-4">
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>
                                                        Created Start Date
                                                    </Form.Label>
                                                    <Form.Control type="date"
                                                                  value={query.startDate}
                                                                  onChange={evt => setQuery({...query, startDate: evt.target.value})} />
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                            <Form.Group>
                                                    <Form.Label>
                                                        Created End Date
                                                    </Form.Label>
                                                    <Form.Control type="date"
                                                                  value={query.endDate}
                                                                  onChange={evt => setQuery({...query, endDate: evt.target.value})} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                           

                        </Card.Body>
                        <Card.Footer className="text-center">
                            <Button type='submit'>
                                SEARCH
                            </Button>
                        </Card.Footer>
                    </Form>
                </Card>
                

            

            <div className="text-center mb-3">
            <Button onClick={() => prevPage()}>Prev</Button>
                <select value={limit} onChange={evt => changeLimit(evt.target.value)}>
                    {
                        limits.map((l, i) => {
                            return (
                                <option key={i} value={l}>{l} results per page</option>
                            )
                        })
                    }
                </select>
            <Button onClick={() => nextPage()}>Next</Button> <br/>
            {`(${deviceData.currentDeviceCount} Records)`}
            </div>
            
      
                </Col>

                <Col>
                <button className="btn btn-primary float-end" onClick={() => setCreateDeviceModalOpen(true)}>Add a Device to System</button>
                </Col>
            </Row>
           
            
           
           

            
            <table className="table table-dark">
                <thead>
                    <tr className="text-center">
                        <th onClick={() => changeOrderBy('d.serial_number')}>Serial Number {buildIcon('d.serial_number')}</th>
                        <th onClick={() => changeOrderBy('dt.model')}>Model {buildIcon('dt.model')}</th>
                        <th onClick={() => changeOrderBy('dt.device_name')}>Device Name {buildIcon('dt.device_name')}</th>
                        <th onClick={() => changeOrderBy('dt.device_line')}>Device Line {buildIcon('dt.device_line')}</th>
                        <th onClick={() => changeOrderBy('dtf.firmware_version')}>Original Firmware Version {buildIcon('dtf.firmware_version')}</th>
                        <th>Global Data</th>
                        <th>Device Data</th>
                        <th>Test Results</th>
                        <th onClick={() => changeOrderBy('d.current_site')}>Current Location {buildIcon('d.current_site')}</th>
                        <th onClick={() => changeOrderBy('d.shipping_status')}>Shipping Status {buildIcon('d.shipping_status')}</th>
                        <th onClick={() => changeOrderBy('d.created_on')}>Date Added {buildIcon('d.created_on')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        buildDeviceRows()
                    }
                </tbody>
            </table>

            
        </div>
    )
}