import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SpartanApi from '../../helpers/spartan-api'
import { useNavigate } from 'react-router-dom';


export default function CreateSiteModal ({closeCB}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [newSite, setNewSite] = useState(() => {
        return {
            site_name: '',
            site_city: '',
            site_state: '',
            site_country: ''
        }
    });


    const createNewSite = async(evt) => {
        evt.preventDefault();
        await api.attemptCreateTestSite(newSite);
        closeCB();
    }

    return (
        <div className="spartan-modal">
            <form onSubmit={createNewSite}>
                <h6>Site Name</h6>
                <input name="site_name"
                       type="text"
                       value={newSite.site_name}
                       onChange={evt => setNewSite({...newSite, [evt.target.name]: evt.target.value})}
                       required
                       />
                <h6>Site City</h6>
                <input name="site_city" 
                       type="text" 
                       value={newSite.site_city} 
                       onChange={evt => setNewSite({...newSite, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>Site State</h6>
                <input name="site_state" 
                       type="text" 
                       value={newSite.site_state} 
                       onChange={evt => setNewSite({...newSite, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>Site Country</h6>
                <input name="site_country" 
                       type="text" 
                       value={newSite.site_country} 
                       onChange={evt => setNewSite({...newSite, [evt.target.name]: evt.target.value})} 
                       required />
                <button type="submit">Create Site</button>
            </form>
        </div>
    )
}