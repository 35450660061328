import { useDispatch } from "react-redux";
import { useNavigate, useLocation} from "react-router-dom";
import { useState, useEffect } from "react";
import AlertHelper from "../../helpers/alert-helper";
import SpartanApi from "../../helpers/spartan-api";
import {Container, Form, Button, Card} from 'react-bootstrap'

export default function ResetPassword(){
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [alertHelper] = useState(() => new AlertHelper({dispatch}));
    const [newPassword, setNewPassword] = useState(() => {
        return {
            user_password: '',
            confirm_user_password: ''
        }
    })
    const [isUpdating, setIsUpdating] = useState(() => false);
    const [token, setToken] = useState(() => undefined); 
     useEffect(() => {

        try {
            let newToken = window.location.href.split('?token=')[1]
            setToken(newToken)
        } catch(err) {
            console.log(err)
        }

    }, [])

    const attemptResetPassword = async(evt) => {
        evt.preventDefault();
        setIsUpdating(true)
        console.log("TOKEN: ", token);
        const success = await api.attemptResetPassword({user_password: newPassword.user_password, token})

        if (!success) {
            setIsUpdating(false);
            alertHelper.addAlert({title: 'Unable to Reset Password', body: 'Please check your request and try again later'})
            return;
        }

        return navigate('/')
    }

    return (
        <Container>
            <Card bg="dark" text="white" className="text-center">
                <Card.Header>
                    <h4>Create New Password</h4>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={attemptResetPassword}>
                        <Form.Group>
                            <Form.Label>
                                New Password (8 Characters Minimum)
                            </Form.Label>
                            <Form.Control type="password" 
                                          value={newPassword.user_password}
                                          minLength={8}
                                          onChange={evt => setNewPassword({...newPassword, user_password: evt.target.value})}
                                          required/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Confirm New Password
                            </Form.Label>
                            <Form.Control type="password" 
                                          value={newPassword.confirm_user_password}
                                          onChange={evt => setNewPassword({...newPassword, confirm_user_password: evt.target.value})}
                                          required/>
                        </Form.Group>

                        <Button type="submit" disabled={isUpdating || (newPassword.user_password != newPassword.confirm_user_password)}>Reset Password</Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}