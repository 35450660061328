import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect} from 'react';
import SpartanApi from "../helpers/spartan-api";
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Row, Col, Button, Table, Accordion } from "react-bootstrap";
import ShippingListRow from "./shared/ShippingListRow";
const editable_value = 'Awaiting Delivery'

export default function UpdateShippingBundle (){
    const dispatch = useDispatch();
    const {shippingBundleID} = useParams();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [bundle, setBundle] = useState(() => null);
    const [partsToAdd, setPartsToAdd] = useState(() => []);
    const [partsToShip, setPartsToShip] = useState(() => []);
    const [partsToRemove, setPartsToRemove] = useState(() => []);
    const [devicesToShip, setDevicesToShip] = useState(() => []);
    const [accessoriesToShip, setAccessoriesToShip] = useState(() => []);
    const [accessoriesToRemove, setAccessoriesToRemove] = useState(() => [])
    const [devicesToAdd, setDevicesToAdd] = useState(() => []);
    const [devicesToRemove, setDevicesToRemove] = useState(() => []);
    const [subAssembliesToShip, setSubAssembliesToShip] = useState(() => []);
    const [subAssembliesToRemove, setSubAssembliesToRemove] = useState(() => []);
    const [subAssembliesToAdd, setSubAssembliesToAdd] = useState(() => []);
    const [inSubAssemblyMode, setInSubAssemblyMode] = useState(() => false);
    const [shippingAddress, setShippingAddress] = useState(() => {
        return {
            recipient: '',
            address_one: '',
            address_two: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            
        }
    });

    const [bundleInformation, setBundleInformation] = useState(() =>  {
        return {
            shipment_location_id: undefined,
            shipping_carrier: undefined,
            shipping_tracking_number: undefined,
            delivery_status: undefined
        }
    })
    const [barcode, setBarcode] = useState(() => undefined);
    const [scanned, setScanned] = useState(() => undefined);
    const [isSaving, setIsSaving] = useState(() => false);
    const [initialStatus, setInitialStatus] = useState(() => null);

    const loadData = async () => {
        const bundle =  await api.attemptGetShippingBundleById(shippingBundleID);
        if (!bundle) return;
        setBundle({...bundle});
    }

    const buildDevicesShippedCard = () => {
        if (!bundle || !bundle.shipping_bundle_items) return null;
        const items = bundle.shipping_bundle_items;

        return (
            <Accordion className="dark-accordion mt-5">
            <Accordion.Item  eventKey={1}>
                <Accordion.Header className="text-center">Shipped Devices ({items.length} devices)</Accordion.Header>
                    <Accordion.Body>
                  
                        
                       
                            <Table variant="dark">
                                <thead>
                                    <tr>
                                        <th>
                                            Model
                                        </th>
                                        <th>
                                            Device Line
                                        </th>
                                        <th>
                                            Device Name
                                        </th>
                                        <th>
                                            Serial Number
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((d, i) => {
                                        return (
                                            <tr>
                                                <td>{d.model}</td>
                                                <td>{d.device_line}</td>
                                                <td>{d.device_name}</td>
                                                <td>{d.device_serial_number}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                     
                    </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        )
    }

    const buildStandAloneParts = () => {
        if (!bundle || !bundle.stand_alone_parts) return null;
        const items = bundle.stand_alone_parts;

        return (
            <Accordion className="dark-accordion mt-5">
            <Accordion.Item  eventKey={1}>
                <Accordion.Header className="text-center">Standalone Parts ({items.length} parts)</Accordion.Header>
                    <Accordion.Body>
                  
                        
                       
                            <Table variant="dark">
                                <thead>
                                    <tr>
                                        <th>
                                            Model
                                        </th>
                                        <th>
                                            Part Name
                                        </th>
                                        <th>
                                            Serial Number
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((d, i) => {
                                        return (
                                            <tr>
                                                <td>{d.model}</td>
                                                <td>{d.part_name}</td>
                                                <td>{d.serial_number}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                     
                    </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        )
    }



    useEffect(() => {
        if (!shippingBundleID) return;
        loadData();
    }, [shippingBundleID]);

    useEffect(() =>  {
        if (!bundle) return;
        setBundleInformation({
            ...bundle
        })

        setShippingAddress({...bundle.shipping_address})
        if (bundle.shipping_bundle_items){
            setDevicesToShip([...bundle.shipping_bundle_items]);
        }

        if (bundle.accessory_items) {
            setAccessoriesToShip([...bundle.accessory_items])
        }

        if (bundle.sub_assemblies){
            setSubAssembliesToShip([...bundle.sub_assemblies])
        }

        if (bundle.stand_alone_parts) {
            setPartsToShip([...bundle.stand_alone_parts]);
        }
        

        setInitialStatus(bundle.delivery_status);
    }, [bundle])

    if (!bundle) return;
    console.log(bundle)
    return (
        <div>
            <ShippingListRow bundle={bundle} onUpdateModalClose={loadData} overview/>
            {buildDevicesShippedCard()}
            {buildStandAloneParts()}
        </div>
        
    )
}