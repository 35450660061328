import { useState, useRef, useEffect } from "react";
import {Container, Row, Col, Card, Form} from 'react-bootstrap'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpartanApi from "./helpers/spartan-api";
import ParticleImage, {forces} from "react-particle-image";
const particleOptions = {
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y);
    // Make a particle for this pixel if blue > 50 (range 0-255)
    return pixel.b > 0;
  },
  color: ({ x, y, image }) => "#61dafb",
  mass: () => 40,
  friction: () => .25
};
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api] = useState(() => new SpartanApi({dispatch, navigate}));
  const [loginUser, setLoginUser] = useState(() => {
    return {
      email: '',
      user_password: ''
    }
  });
  const [containerWidth, setContainerWidth] = useState(() => 400);
  const parentContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (parentContainerRef.current) {
        const containerWidth = parentContainerRef.current.offsetWidth;
        setContainerWidth(containerWidth);
        // Perform any additional actions based on the new width
      }
    };

    // Attach event listener to window resize
    window.addEventListener('resize', handleResize);
    handleResize();
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const attemptLogin = async (evt) => {
    evt.preventDefault();

    const user = await api.attemptLogin(loginUser);
    console.log(user);
    if (!user) return //TODO: Add Error Messaging
    if (!user.changed_password) return navigate('/change-password')
    if (!user.is_admin) return navigate('/home')

    //SEND TO ADMIN SECTION
    return navigate('/admin')
  }
  return (
    <Container>
      <Row>
      <Col xs={12} sm={6} md={6} lg={4} className="mt-4"> 
            <div className="card text-bg-dark text-center">
              <h4>Please Login</h4>
              <form onSubmit={attemptLogin}>
                <h6>Email Address:</h6>
                <input type="email" 
                      value={loginUser.email}
                      onChange={evt => setLoginUser({...loginUser, email: evt.target.value})}
                      required={true}/>

                <h6>Password:</h6>
                <input type="password"
                      value={loginUser.user_password}
                      onChange={evt => setLoginUser({...loginUser, user_password: evt.target.value})}
                      required={true}/>

                <br/> <br/>
                <button className="btn btn-primary" type="submit">Login</button>

                <br/> <br/>
                <a href="/#/forgot-password">Forgot Password?</a>

                <br/> <br/>
              </form>
            </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={8} className="d-none d-sm-block mt-4">
          <div ref={parentContainerRef}>
          <ParticleImage
              style={{backgroundColor: 'rgba(0,0,0,0)'}}
              src={"/car.png"}
              scale={.9}
              entropy={10}
              maxParticles={20000}
              particleOptions={particleOptions}
              width={containerWidth}
              mouseMoveForce={(x, y) => forces.disturbance(x, y, 10)}
              touchMoveForce={(x, y) => forces.disturbance(x, y, 6)}
              mouseDownForce={(x, y) => forces.disturbance(x, y, 50)}
          />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
