import {Modal, Form, Button, Table} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import { spartanUploadHelper } from '../../helpers/upload-helper';
const locationOptions = ['left', 'right', 'front', 'rear'];

export default function ViewOrganizationDemoModal({close, organization}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [demos, setDemos] = useState(() => []);
    const [activeDemo, setActiveDemo] = useState({
        id: undefined,
        demo_name: undefined,
        builds: [],
        demo_devices: []
    })
    const [buildDragMap, setBuildDragMap] = useState(() => {
        return {}
    })
    const [serialNumber, setSerialNumber] = useState(() => undefined);
    const appState = useSelector(s => s);
    const addDeviceToActiveList = async () => {
        //First Make Sure it Doesn't Already Exist in the List
        if (activeDemo.demo_devices.find(d => d.serial_number == serialNumber)) return;

        //Make an API Call to see if the device exists
        const device = await api.attemptGetDeviceBySerialNumber(serialNumber);
        console.log(device);
        if (!device) return; //TODO: ERROR

        //Add Device To The List
        const copy = {...activeDemo};
        copy.demo_devices.push({
            device_id: device.device_id,
            serial_number: device.device_serial_number
        })

        setActiveDemo(() => copy);
    }

    const loadDemos = async () => {
        const demos = await api.attemptGetCustomerDemosByOrganizationId({organization_id: organization.id});
        console.log(demos);
        if (demos){
            setDemos(() => demos);
        }
    }

    useEffect(() => {
        if (!organization) return;
        loadDemos()
    }, [organization])

    const createFreshDemo = async () => {
        //STEP ONE: CREATE THE DEMO
        const d = await api.attemptCreateOrganizationDemo({
            organization_id: organization.id,
            demo_name: activeDemo.demo_name
        })
        
        //Step Two: Add The Builds
        for (let i = 0, len = activeDemo.builds.length; i < len; i++){
            let build = {...activeDemo.builds[i]};
            const files = [];

            for (let j = 0, jlen= build.system_build_ini_files.files.length; j < jlen; j++){
                const f = build.system_build_ini_files.files[j];
                await api.attemptGetToken();
                const uploadUrl = await spartanUploadHelper({
                    sasToken: appState.user.sasToken,
                    containerName: 'demofiles',
                    file: f.file
                })
                files.push({
                    url: uploadUrl,
                    side: f.side
                })
            }
            

           // build.system_build_ini_files.files = files;
            const system_build_ini_files = {files};
            await api.attemptAddBuildToOrganizationDemo({...build, system_build_ini_files, demo_id: d.id})
        }

        //ADD Devices
        for (let i = 0, len = activeDemo.demo_devices.length; i < len; i++){
            const demoDevice = activeDemo.demo_devices[i];
            await api.attemptAddDeviceToOrganizationDemo({
                device_id: demoDevice.device_id,
                demo_id: d.id
            })
        }

        close();

    }

    const saveDemo = async(evt) => {
        evt.preventDefault();
        //TODO: ACCOUNT FOR UPDATE / VIEW
        if (!activeDemo.id) {
            return createFreshDemo()
        }

        //TODO: UPDATE
    }

    const buildHeader = () => {
        if (!demos || !demos.length) {
            return (
                <div className="text-center">
                    <p className="mb-5">
                        There are no demos associated with this organization, create one below.
                        
                    </p>
                    <hr/>
                </div>
            )
        }
    }

    const addBlankBuildForm = () => {
        const copy = {...activeDemo};
        copy.builds.unshift({
            id: undefined,
            demo_id: activeDemo.id,
            system_build_name: undefined,
            system_build_description: undefined,
            system_build_ini_files: {
                files: []
            }
        });
        setActiveDemo(() => copy);
    }

    const removeBuildFromDemo = (index) => {
        const copy = {...activeDemo};
        copy.builds.splice(index, 1);
        setActiveDemo(() => copy);
    }

    const changeBuildValue = (index, key, value) => {
        const copy = {...activeDemo};
        copy.builds[index][key] = value;
        setActiveDemo(() => copy)
    }

    const handleFileDrop = (event, index) => {
        event.preventDefault();
        event.stopPropagation();
      
        const files = event.dataTransfer.files;
        processFiles(files, index);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        setBuildDragMap({...buildDragMap, [index]: true})
    }

    const handleDragEnd = (e, index) => {
       console.log("ended")
        setBuildDragMap({...buildDragMap, [index]: false});
    }

    const processFiles = (files, index) => {
        // Here you can handle the file processing, validation, and updating the state
        console.log(`Received ${files.length} files for build index ${index}`);
        console.log(files)

        // Add file handling logic here
        const arr = [];
        for (let i = 0, len = files.length; i < len; i++){
            if (!files[i].name.toLowerCase().endsWith('.ini')) continue;
            arr.push({
                file: files[i],
                side: undefined,
                url: undefined
            })
        }

        const copy = {...activeDemo};
        copy.builds[index].system_build_ini_files.files = arr.concat(copy.builds[index].system_build_ini_files.files);
        setActiveDemo(() => copy)
    };

    const handleBuildFileMetaDataChange = (buildIndex, fileIndex, key, value) => {
        const copy = {...activeDemo};
        copy.builds[buildIndex].system_build_ini_files.files[fileIndex][key] = value;
        setActiveDemo(() => copy);
    }

    const removeFileFromBuild = (buildIndex, fileIndex) => {
        const copy = {...activeDemo};
        copy.builds[buildIndex].system_build_ini_files.files.splice(fileIndex, 1);
        setActiveDemo(() => copy)
    }

    const buildForm = () => {
        //TODO: ACCOUNT FOR BUILDS
        return (
            <div>
                <Form onSubmit={saveDemo}>
                    <Form.Group>
                        <Form.Label>
                            Demo Name
                        </Form.Label>
                        <Form.Control type="text" value={activeDemo.demo_name} 
                                                  onChange={evt => setActiveDemo({...activeDemo, demo_name: evt.target.value})}
                                                  required/>
                    </Form.Group>
                    <Form.Group className="mt-5">
                        <Form.Label>
                            Builds
                        </Form.Label>

                        <Table variant='dark' striped>
                            <thead>
                                <tr>
                                    <th>Build Name</th>
                                    <th>Description</th>
                                    <th>INI Files</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    activeDemo.builds.map((b, i) => {
                                        const dragClass = buildDragMap[i] ? 'drop-area over' : 'drop-area'
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <Form.Control type="text" value={b.system_build_name}
                                                                              onChange={evt => changeBuildValue(i, 'system_build_name', evt.target.value)}
                                                                              required/>
                                                    <a onClick={evt => removeBuildFromDemo(i)}>(REMOVE -)</a>
                                                </td>
                                                <td>
                                                    <textarea className="form-control" value={b.system_build_description} 
                                                              onChange={evt => changeBuildValue(i, 'system_build_description', evt.target.value)}
                                                              required>

                                                    </textarea>
                                                </td>
                                                <td>
                                                    <div className={dragClass}
                                                         onDragOver={e => handleDragOver(e, i)}
                                                         onDragLeave={e => handleDragEnd(e, i)}
                                                         onDrop={e => handleFileDrop(e, i)}>
                                                        Drop INI Files Here
                                                    </div>

                                                    <hr/>

                                                    <div className="mt-2">
                                                        {b.system_build_ini_files.files.map((f, j) => {
                                                            return (
                                                                <div className="mt-3">
                                                                    {f.file.name}
                                                                    <Form.Select value={f.side} 
                                                                                 onChange={evt => handleBuildFileMetaDataChange(i, j, 'side', evt.target.value)} 
                                                                                 required>
                                                                        <option value={undefined}></option>
                                                                        {
                                                                            locationOptions.map((lo, loi) => {
                                                                                return <option key={loi} value={lo}>{lo}</option>
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                    <a onClick={evt => removeFileFromBuild(i, j)}>REMOVE -</a>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <Button onClick={addBlankBuildForm} variant="success">ADD BUILD + </Button>
                    </Form.Group>
                    <Form.Group className="mt-5">
                        <Form.Label>
                            Approved Devices
                        </Form.Label> <br/>
                        <input placeholder='Device Serial Number'
                               value={serialNumber}
                               onChange={evt => setSerialNumber(evt.target.value.toUpperCase())}  />
                        <Button onClick={() => addDeviceToActiveList()} variant="success">ADD DEVICE</Button>

                       

                        <ul>
                            {activeDemo.demo_devices.map(d => {
                                return (
                                    <li>{d.serial_number}</li>
                                )
                            })}
                        </ul>
                    </Form.Group>
                    <div className="text-center mt-5">
                        <hr/>
                        <Button type="submit">Save Demo</Button>
                    </div>
                </Form>
            </div>
        )
    }

    const buildDemos = () => {
        return (
            <div>
                {buildHeader()}
                {buildForm()}
            </div>
        )
    }
    
    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header className='spartan-modal' closeButton>
                View Demo
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                {buildDemos()}
            </Modal.Body>
        </Modal>
    )
}