import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api'
import CreateSiteModal from './modals/CreateSiteModal';
import { Button } from 'react-bootstrap';


export default function TestSites () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [createModalOpen, setCreateModalOpen] = useState(() => false);
    const testSiteData = useSelector(s => s.testSite);
    
  
    const loadData = async() => {
        try {
            await api.attemptCheckOnline();
            await api.attemptGetTestSites();
        } catch(err) {
            console.log(err);
        }
    }

    const closeModal = async() => {
        setCreateModalOpen(false);
        await api.attemptGetTestSites();
    }

    const gotoSite = (id) => {
        navigate('/test-site/' + id);
    }   

    

    useEffect(() => {
        loadData();
    }, []);

    if (createModalOpen){
        return <CreateSiteModal closeCB={closeModal}/>
    }

    if (!testSiteData.sites || !testSiteData.sites.length) {
        return (
            <div>
                You have no test sites created... Please <button onClick={() => setCreateModalOpen(!createModalOpen)}>Create One</button>
            </div>
        )
    }
    return (
        <div>
            <h4 className="text-center">Test Sites</h4>
            <div className="text-right">
            <Button onClick={() => setCreateModalOpen(!createModalOpen)}> ADD NEW SITE </Button>
            </div>
           
            <table className="table table-dark">
                
                <thead>
                    <tr>
                        <th>Site Name</th>
                        <th>Site Id</th>
                        <th>Site City</th>
                        <th>Site State</th>
                        <th>Site Country</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        testSiteData.sites.map((s, i) => {
                            return (
                                <tr className="clicky" key={i} onClick={() => gotoSite(s.id)}>
                                    <td>{s.site_name}</td>
                                    <td>{s.id}</td>
                                    <td>{s.site_city}</td>
                                    <td>{s.site_state}</td>
                                    <td>{s.site_country}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}