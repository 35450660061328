import {Card, Table, Container, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import SpartanApi from '../helpers/spartan-api';
import moment from 'moment';
import FulFillModal from './modals/FulfillModal';

export default function SalesPurchaseOrders(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [mode, setMode] = useState(() => 'sales');
    const [selectedOrderData, setSelectedOrderData] = useState(() => null);
    const [selectedOrderId, setSelectedOrderId] = useState(() => null);
    const [modalOpen, setModalOpen] = useState(() => false);
    const salesOrders = useSelector(s => s.business.salesOrders);
    const purchaseOrders = useSelector(s => s.business.purchaseOrders);

    const loadSales = async () => {
        console.log("Loading Sales");
        await api.attemptGetAllSalesOrders();
    }

    const loadPurchases = async () => {
        console.log("Loading Purchases");
        await api.attemptGetAllPurchaseOrders();
    }

    const handleOrderClick = (orderData, orderId) => {
        setSelectedOrderData(() => orderData);
        setSelectedOrderId(() => orderId);
        setModalOpen(() => true)
    }

    const buildSalesSection = () => {
        if(!salesOrders.length){
            return (
                <div className="mt-5 text-center">
                    <hr/>
                    There are no Sales Orders entered from NetSuite at this time.
                </div>
            )
        }

        return (
            <Table variant="dark" className="mt-5 text-center" striped bordered hover>
                <thead>
                    <tr>
                        <th>Sales Order Id</th>
                        <th>Transaction Number</th>
                        <th>Customer Id</th>
                        <th>Line Items</th>
                        <th>Customer Information</th>
                        <th>Fullfilled On</th>
                        <th>Created On</th>
                    </tr>
                </thead>
                <tbody>
                    {salesOrders.map((s, i) => {
                        
                        let customerInformation = null;

                        if (s.full_request && s.full_request.customerShipTo) {
                            let customerToShipTo = s.full_request.customerShipTo.split('\n');
                            customerInformation = (
                                <div>
                                    {customerToShipTo.map((c, i) => {
                                        return (
                                            <div key={i}>
                                                {c}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        return (
                            <tr className="clicky" key={i} onClick={() => handleOrderClick(s, s.id)}>
                                <td>{s.sales_order_id}</td>
                                <td>{s.transaction_number}</td>
                                <td>{s.external_customer_id}</td>
                                <td>
                                    <ul>
                                        {s.line_items && s.line_items.map((sli, i) => {
                                            return (
                                                <li style={{listStyle: 'none'}} key={i}>{sli.item_name} ({sli.quantity})</li>
                                            )
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    {customerInformation}
                                </td>
                                <td>
                                    {s.fulfilled_on ? moment(s.fulfilled_on).format('MM/DD/YYYY hh:mma'): "Not yet Fulfilled"}
                                </td>
                                <td>{moment(s.created_on).format('MM/DD/YYYY hh:mma')}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    const buildPurchaseSection = () => {
        if (!purchaseOrders.length){
            return (
                <div className="mt-5 text-center">
                    <hr/>
                    No Purchase Orders have been submitted via NetSuite.
                </div>
            )
        }

        return (
            <Table variant="dark" className="mt-5 text-center" striped bordered hover>
                <thead>
                    <tr>
                        <th>Purchase Order Id</th>
                        <th>Transaction Number</th>
                        <th>Vendor Id</th>
                        <th>Line Items</th>
                        <th>Formally Received On</th>
                        <th>Created On</th>
                    </tr>
                </thead>

                <tbody>
                    {purchaseOrders.map((p, i) => {
                        return (
                            <tr className="clicky" onClick={() => handleOrderClick(p, p.id)} key={i}>
                                <td>{p.purchase_order_id}</td>
                                <td>{p.transaction_number}</td>
                                <td>{p.external_vendor_id}</td>
                                <td>
                                    <ul>
                                        {p.line_items && p.line_items.map((pli, i) => {
                                            return (
                                                <li style={{listStyle: 'none'}}>
                                                    {pli.item_name} ({pli.quantity})
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </td>
                                <td>
                                    {p.fulfilled_on ? moment(p.fulfilled_on).format('MM/DD/YYYY hh:mma'): 'Not Yet Formally Received'}
                                </td>
                                <td>
                                    {moment(p.created_on).format('MM/DD/YYYY hh:mma')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    const closeModal = async () => {
        await loadPurchases()
        await loadSales()
        setModalOpen(() => false);
        setSelectedOrderData(() => null);
        setSelectedOrderId(() => null);
    }

    let section = buildSalesSection();

    if (mode == 'purchase') {
        section = buildPurchaseSection();
    }

    let modal = null;

    if (modalOpen && selectedOrderData && selectedOrderId) {
        modal = <FulFillModal close={closeModal} mode={mode} orderData={selectedOrderData} id={selectedOrderId} />
    }

    useEffect(() => {
        if (mode == 'sales') {
            loadSales();
        } else {
            loadPurchases();
        }
    }, [mode]);

    return (
        <Container>
            {modal}
            <Card bg="dark" text="white">
                <Card.Header>
                    {mode == 'sales' ? 'Sales Orders': 'Purchase Orders'}
                </Card.Header>
                <Card.Body>
                    <Button onClick={() => setMode('purchase')}>Purchase Orders</Button> <Button onClick={() => setMode('sales')}>Sales Orders</Button>
                    {section}
                </Card.Body>
            </Card>
        </Container>
    )
}