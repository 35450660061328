import {Modal, Form, Button} from 'react-bootstrap'
import SpartanApi from '../../helpers/spartan-api'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react';

export default function UpdateCustomerOrganizationModal({close, organization}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [formData, setFormData] = useState(() => {
        return {
            id: undefined,
            organization_name: '',
            advanced_settings_access: false
        }
    });

    useEffect(() => {
        setFormData({...organization})
    }, [organization])

    const updateOrganization = async(evt) => {
        evt.preventDefault();
        if (!formData.id) return;
        await api.attemptUpdateCustomerOrganization(formData);
        close();
    }

    if (!organization) return null;

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton className="spartan-modal">
                UPDATING {organization.organization_name.toUpperCase()}
            </Modal.Header>
                <Form onSubmit={updateOrganization}>
                <Modal.Body className="spartan-modal">
                    
                        <Form.Group>
                            <Form.Label>
                                Organization Name
                            </Form.Label>
                            <Form.Control value={formData.organization_name} onChange={evt => setFormData({...formData, organization_name: evt.target.value})} required/>
                        </Form.Group>

                        <Form.Group className="mt-5">
                            <Form.Label>
                                Allow Advanced Settings Access?
                            </Form.Label>
                            <Form.Check checked={formData.advanced_settings_access} onChange={evt => setFormData({...formData, advanced_settings_access: evt.target.checked})} />
                        </Form.Group>

                    
                </Modal.Body>
                <Modal.Footer className="spartan-modal text-center">
                    <Button type="submit">
                        UPDATE ORGANIZATION
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}