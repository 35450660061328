import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    devicesUnderWarranty: (state = [], action) => {
        switch(action.type){
            case(ACTIONS.SET_DEVICES_UNDER_WARRANTY): {
                return action.payload;
            }
            default: return state;
        }
    },
    salesOrders: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_SALES_ORDERS: {
                return action.payload;
            }
            default: return state;
        }
    },
    purchaseOrders: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_PURCHASE_ORDERS: {
                return action.payload;
            }
            default: return state;
        }
    },
    unfulfilledPurchaseOrders: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_UNFULFILLED_PURCHASE_ORDERS: {
                return action.payload;
            }
            default: return state;
        }
    }
})