import {Modal, Table} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import { useDispatch} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';

export default function ViewOrganizationLicensesModal({close, organization}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [licenses, setLicenses] = useState(() => []);

    const loadData = async () => {
        const l = await api.attemptGetOrganizationLicensesById({organization_id: organization.id});
        console.log(l)
        setLicenses(l);
    }

    useEffect(() => {
        if (!organization.id) return;
        loadData();
    },[organization]);

    if (!licenses.length) return null;

    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header className="spartan-modal">
                {organization.organization_name} License Count
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Table variant='dark' striped>
                    <thead>
                        <tr>
                            <th>License Type</th>
                            <th>Remaining</th>
                        </tr>
                    </thead>
                    <tbody>
                        {licenses.map((l,i) => {
                            return (
                                <tr key={i}>
                                    <td>{l.profile_name}</td>
                                    <td>{l.sum}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}