import { Page, Text, View, Document, StyleSheet, Image, Line } from '@react-pdf/renderer';
import logo from '../spartan-full-logo.png'
import moment from 'moment';

const styles = StyleSheet.create({
    page: {
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
    },
    logo: {
        margin: '0 auto',
        width: 300
    },
    sectionHeading: {
        fontSize: 14
    },
    addressText: {
        fontSize: 12
    },
    subSection: {
        margin: 5,
        padding: 5
    },
    spaceTop: {
      marginTop: 10
    }
  });


const ShippingReport = ({bundle}) => {

  const buildRecipient = () => {
    if (!bundle.shipping_address) return null;
    const {shipping_address} = bundle;
    return (
        <View style={styles.section}>
            <Text style={styles.sectionHeading}>Recipient</Text>
            <Text style={styles.addressText}>{shipping_address.recipient}</Text>
            <Text style={styles.addressText}>{shipping_address.address_one}</Text>
            <Text style={styles.addressText}>{shipping_address.address_two}</Text>
            <Text style={styles.addressText}>{shipping_address.city}, {shipping_address.state}, {shipping_address.country}, {shipping_address.zipcode}</Text>
        </View>
    )
  }

  const buildShippedFrom = () => {
    if (!bundle.shipped_from_location) return null;
    const {shipped_from_location} = bundle;
    return (
        <View style={styles.section}>
            <Text style={styles.sectionHeading}>Shipped From</Text>
            <Text style={styles.addressText}>{shipped_from_location.site_name}</Text>
            <Text style={styles.addressText}>{shipped_from_location.site_city}, {shipped_from_location.site_state}, {shipped_from_location.site_country}</Text>
        </View>
    )
  }

  const buildShippingBundleItems = () => {
    if (!bundle.shipping_bundle_items || !Array.isArray(bundle.shipping_bundle_items)) return null;
    const {shipping_bundle_items} = bundle;
    return (
        <View style={styles.section} >
            <Text style={styles.sectionHeading}>Devices: </Text>
            <Text style={styles.addressText}>Quantity {shipping_bundle_items.length}</Text>

            <View style={styles.subSection}>
                {shipping_bundle_items.map((sbi, i) => {
                    return (
                        <View key={i} style={styles.spaceTop}>
                        <Text key={i} style={styles.addressText}>
                           
                           SN: {sbi.device_serial_number} - {sbi.device_line} | {sbi.model} | {sbi.device_name}  
                        </Text>
                        <Text style={styles.addressText}>{sbi.shipped_firmware_version ? `Firmware Version:  ${sbi.shipped_firmware_version.firmware_version}` : ''}</Text>
                        <Text style={styles.addressText}>{sbi.shipped_base_configuration ? `Base Configuration: ${sbi.shipped_base_configuration.title} (${sbi.shipped_base_configuration.github_url})` : ''}</Text>
                        <Line/>
                        </View>
                    )
                })}
            </View>
        </View>
    )
  }

  const buildStandAloneParts = () => {
    if (!bundle.stand_alone_parts_count || !Array.isArray(bundle.stand_alone_parts_count)) return null;
    const {stand_alone_parts_count} = bundle;
    return (
        <View style={styles.section}>
            <Text style={styles.sectionHeading}>Parts: </Text>
            <View style={styles.subSection}>
                {stand_alone_parts_count.map((sapc, i) => {
                return (
                    <Text key={i} style={styles.addressText}>
                        {sapc.count}x - {sapc.model}: {sapc.part_name}
                    </Text>
                )
                })}
            </View>
            
        </View>
    )
  }
  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image src={logo} style={styles.logo} />
      </View>
      {buildRecipient()}
      {buildShippedFrom()}
      {buildShippingBundleItems()}
      {buildStandAloneParts()}
    </Page>
  </Document>
  );
};

export default ShippingReport;