import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Form, Button, Row } from 'react-bootstrap';
import SpartanApi from '../helpers/spartan-api';
import ViewLostInventoryModal from '../admin/modals/LostInventoryModals';
import { ViewCheckedOutDevicesModal } from '../admin/modals/ViewCheckedOutDevicesModal';
import AddSubAssemblyPartsModal from '../admin/modals/AddSubAssemblyPartsModal';
import RemoveSubAssemblyPartsModal from '../admin/modals/RemoveSubAssemblyPartsModal';
import FulfillPurchaseOrderModal from '../admin/modals/FulfilPurchaseOrderModal';

export default function Inventory(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [siteId, setSiteId] = useState(() => undefined);
    const inventory = useSelector(s => s.inventory);
    const testSites = useSelector(s => s.testSite.sites);
    const purchaseOrders = useSelector(s => s.business.unfulfilledPurchaseOrders);
    const [section, setSection] = useState(() => 'Available Devices');
    const [sectionOptions, setSectionOptions] = useState(() => {
        return [
            {title: 'Available Devices'},
            {title: 'Sub-Assembly Availability'},
            {title: 'Sub-Assembly Part Count'}
        ]
    })
    const [lostDevicesModalOpen, setLostDevicesModalOpen] = useState(() => false);
    const [checkedOutDevicesModalOpen, setCheckedOutDevicesModalOpen] = useState(() => false);
    const [addSubAssemblyPartsModalOpen, setAddSubAssemblyParsModalOpen] = useState(() => false);
    const [removeSubAssemblyPartsModalOpen, setRemoveSubAssemblyPartsModalOpen] = useState(() => false);
    const [fulfillPurchaseOrderModalOpen, setFulfillPurchaseOrderModalOpen] = useState(() => false);

    const loadSites = async() => {
        await api.attemptGetTestSites();
    }

    const loadInventory = async () => {
        await api.attemptGetAvailableDeviceCountBySite(siteId);
        await api.attemptGetAvailableSubAssemblies(siteId);
        await api.attemptGetAvailableSubAssemblyPartCounts(siteId);
        await api.attemptGetUnfulfilledPurchaseOrders();
    }

    const closeModals = () => {
        setLostDevicesModalOpen(() => false);
        setCheckedOutDevicesModalOpen(() => false);
        setAddSubAssemblyParsModalOpen(() => false);
        setRemoveSubAssemblyPartsModalOpen(() => false);
        setFulfillPurchaseOrderModalOpen(() => false)
        loadInventory();
    }
    

    const buildDeviceCount = () => {
      
        return (
            <Card bg="dark" text="white">
                <Card.Header>
                    <h5 className="text-center">Available Devices</h5>
                </Card.Header>

                <Card.Body>

                    <table className="table table-dark">
                        <thead>
                            <tr>
                                <th>Device Line</th>
                                <th>Device name</th>
                                <th>Model</th>
                                <th>Units Available</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inventory.available_devices.map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{d.device_line}</td>
                                            <td>{d.device_name}</td>
                                            <td>{d.model}</td>
                                            <td>{d.count}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    

                </Card.Body>
            </Card>
        )
    }

    const buildSubAssemblyCount = () => {
        return (
            <Card bg="dark" text="white" className="mt-5">
                <Card.Header>
                    <h5 className="text-center">Sub-Assembly Availability</h5>
                </Card.Header>
                <Card.Body>
                    <table className="table table-dark">
                        <thead>
                            <tr>
                                <th>Assembly Name</th>
                                <th>Model</th>
                                <th>Required Parts Available</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                inventory.available_sub_assemblies.map((a, i) => {
                                    const color = a.has_available_parts ? 'green' : 'red';
                                    return (
                                        <tr key={i}>
                                            <td>{a.assembly_name}</td>
                                            <td>{a.model}</td>
                                            <td style={{color}}>{a.has_available_parts ? 'Required Parts Available' : 'Parts unavailable at location'}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        )
    }

    const buildSubAssemblyPartCount = () => {
        return (
            <Card bg="dark" text="white" className="mt-5">
                <Card.Header className="text-center">
                    <h5 className="text-center">Sub-Assembly Part Count</h5>
                    <Button onClick={() => setAddSubAssemblyParsModalOpen(() => true)}>Add New Parts</Button> <Button onClick={() => setRemoveSubAssemblyPartsModalOpen(() => true)}>Remove Parts</Button>
                </Card.Header>
                <Card.Body>
                    <table className='table table-dark'>
                        <thead>
                            <tr>
                                <th>Part Name</th>
                                <th>Model</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                inventory.available_sub_assembly_parts.map((a, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{a.part_name}</td>
                                            <td>{a.model}</td>
                                            <td>{a.count}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    
                </Card.Body>
            </Card>
        )
    }

    useEffect(() => {
        loadSites()
    }, []);

    useEffect(() => {
        if (!siteId) return;
        loadInventory();
    }, [siteId]);

    if (!siteId) {
        return (
            <Container>
                <Card bg="dark" text="white">
                    <Card.Header>
                        <h4>Please Select a Site</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form.Select value={siteId} onChange={evt => setSiteId(evt.target.value)}>
                            <option></option>
                            {testSites.map((site, i) => {
                                return (
                                    <option key={i} value={site.id}>{site.site_name} | {site.site_city}, {site.site_state}, {site.site_country}</option>
                                )
                            })}
                        </Form.Select>
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    

    let modal = null;

    if (lostDevicesModalOpen) {
        modal = <ViewLostInventoryModal close={closeModals} />
    } else if (checkedOutDevicesModalOpen) {
        modal = <ViewCheckedOutDevicesModal close={closeModals} />
    } else if (addSubAssemblyPartsModalOpen) {
        modal = <AddSubAssemblyPartsModal close={closeModals} testingLocationId={siteId} />
    } else if (removeSubAssemblyPartsModalOpen) {
        modal = <RemoveSubAssemblyPartsModal close={closeModals} testingLocationId={siteId}/>
    } else if (fulfillPurchaseOrderModalOpen) {
        modal = <FulfillPurchaseOrderModal close={closeModals} locationId={siteId} />
    }



    return (
        <Container>
            {modal}
            <Row className="mb-5">
            <Card bg="dark" text="white">
                <Card.Header>
                    SELECTED LOCATION
                </Card.Header>
                <Card.Body>
                    <Form.Select value={siteId} onChange={evt => setSiteId(evt.target.value)}>
                            
                            {testSites.map((site, i) => {
                                return (
                                    <option key={i} value={site.id}>{site.site_name} | {site.site_city}, {site.site_state}, {site.site_country}</option>
                                )
                            })}
                    </Form.Select>
                </Card.Body>
            </Card>
            
            </Row>

            <Row className="mb-5">
                <div>
                   {!purchaseOrders.length ? null : <Button onClick={() => setFulfillPurchaseOrderModalOpen(() => true)} variant='success'>Fulfill Purchase Orders</Button>} <Button onClick={() => setLostDevicesModalOpen(() => true)}>View Lost Devices</Button> <Button onClick={() => setCheckedOutDevicesModalOpen(() => true)}>View Checked Out Items</Button> 
                </div>
                
            </Row>
            <Row>
                {buildDeviceCount()}
            </Row>
            <Row>
                {buildSubAssemblyCount()}
            </Row>

            <Row>
                {buildSubAssemblyPartCount()}
            </Row>
        </Container>
    )


}