import {Modal, Form, Row, Col, Card, Button} from 'react-bootstrap'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';


export default function AdvancedBundleSearchModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const [query, setQuery] = useState(() => {
        return {
            shipping_recipient: undefined,
            shipping_address_one: undefined,
            shipping_address_two: undefined,
            shipping_address_city: undefined,
            shipping_address_zipcode: undefined,
            shipping_address_country: undefined,
            shipping_address_state: undefined,
            shipped_by_first_name: undefined,
            shipped_by_last_name: undefined,
            shipped_by_location: undefined,
            shipped_delivery_status: undefined,
            tracking_number: undefined,
            tracking_carrier: undefined,
            shipping_status: undefined,
            shipped_from_id: undefined
        }
    })
    const appState = useSelector(s => s);
    console.log("APP STATE: ", appState);

    const search = async (evt) => {
        evt.preventDefault();
        await api.attemptSearchShippingBundles({searchQuery: query});
        close();
    }

    const handleTextChange = (key, value) => {
        setQuery({...query, [key]: value})
    }
    
    const loadInitialData = async () => {
        await api.attemptGetTestSites();
    }

    useEffect(() => {
        loadInitialData();
    }, [])

    return (
        <Modal bg="dark" show={true} onHide={close}>
            <Modal.Header className="spartan-modal">
                Advanced Shipping Bundle Search
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={search}>
                    <h4>Recipient Info</h4>
                    <Form.Group>
                        <Form.Label>
                            Recipient Name
                        </Form.Label>
                        <Form.Control type="text" value={query.shipping_recipient} onChange={evt => handleTextChange('shipping_recipient', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Address Line One
                        </Form.Label>
                        <Form.Control type="text" value={query.shipping_address_one} onChange={evt => handleTextChange('shipping_address_one', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Address Line Two
                        </Form.Label>
                        <Form.Control type="text" value={query.shipping_address_two} onChange={evt => handleTextChange('shipping_address_two', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            City
                        </Form.Label>
                        <Form.Control type="text" value={query.shipping_address_city} onChange={evt => handleTextChange('shipping_address_city', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            State
                        </Form.Label>
                        <Form.Control type="text" value={query.shipping_address_state} onChange={evt => handleTextChange('shipping_address_state', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Zip Code
                        </Form.Label>
                        <Form.Control type="text" value={query.shipping_address_zipcode} onChange={evt => handleTextChange('shipping_address_zipcode', evt.target.value)}/>
                    </Form.Group>
                    <hr/>
                    <h4>Shipped From</h4>
                    <Form.Group>
                        <Form.Label>
                            Shipped From
                        </Form.Label>
                        <Form.Select value={query.shipped_from_id} onChange={evt => handleTextChange('shipped_from_id', evt.target.value)}>
                            <option></option>
                            {
                                appState.testSite.sites.map((s, i) => {
                                    return (
                                        <option key={i} value={s.id}>{s.site_name} | {s.site_city}, {s.site_state}, {s.site_country}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <hr/>
                    <h4>Shipped By</h4>
                    <Form.Group>
                        <Form.Label>
                            Shipper First Name
                        </Form.Label>
                        <Form.Control type="text" value={query.shipped_by_first_name} onChange={evt => handleTextChange('shipped_by_first_name', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Shipper Last Name
                        </Form.Label>
                        <Form.Control type="text" value={query.shipped_by_last_name} onChange={evt => handleTextChange('shipped_by_last_name', evt.target.value)}/>
                    </Form.Group>

                    <hr/>
                    <h4>Tracking Information</h4>
                    <Form.Group>
                        <Form.Label>
                            Delivery Carrier
                        </Form.Label>
                        <Form.Control type="text" value={query.tracking_carrier} onChange={evt => handleTextChange('tracking_carrier', evt.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Tracking Number
                        </Form.Label>
                        <Form.Control type="text" value={query.tracking_number} onChange={evt => handleTextChange('tracking_number', evt.target.value)} />
                    </Form.Group>
                    <hr/>
                    
                    <Button type="submit">Search</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}