import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';


export default combineReducers({
    organizations: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_CUSTOMER_ORGANIZATIONS: {
                return action.payload;
            }
            default: return state;
        }
    }
})