import {useState} from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';
import AlertHelper from '../../helpers/alert-helper';
import { Card,Container, Form, Button } from 'react-bootstrap';

export default function ForgotPassword(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [alertHelper] = useState(() => new AlertHelper({dispatch}))
    const [email, setEmail] = useState(() => '')
    const [isUpdating, setIsUpdating] = useState(() => false);
    const [isSuccess, setIsSuccess] = useState(() => false);

    const attemptTriggerResetPassword = async (evt) => {
        evt.preventDefault();
        setIsUpdating(true)
        const success = await api.attemptForgotPassword({email});

        if (!success){
            alertHelper.addAlert({
                title: 'Unable to Trigger Forgot Password Email',
                body: 'Please try again later.'
            })
            setIsUpdating(false);
            return
        }

        setIsSuccess(true);
    }

    if (isSuccess) {
        return (
            <Container>
                <Card bg="dark" text="white" className="text-center">
                    <Card.Header>
                        <h4>Password Reset Email Sent</h4>
                        <p>Please check your email and follow the instructions for changing your password.</p>
                    </Card.Header>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            <Card bg="dark" text="white" className="text-center">
                <Card.Header>
                    <h4>Forgot Password</h4>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={attemptTriggerResetPassword}>
                        <Form.Group>
                            <Form.Label>
                                Account Email Address
                            </Form.Label>
                            <Form.Control type="email"  value={email} onChange={ evt => setEmail(evt.target.value)} required/>
                        </Form.Group>

                        <Button type="submit" disabled={isUpdating}>Send Password Reset Email</Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}