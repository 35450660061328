import * as ACTIONS from '../constants/actions';


export default class AlertHelper {
    constructor({dispatch}){
        this.dispatch = dispatch;
    }

    addAlert = (alert) => {
        this.dispatch({type: ACTIONS.ADD_ALERT, payload: alert})
    }

    removeAlert = (index) => {
        this.dispatch({type: ACTIONS.REMOVE_ALERT, payload: index})
    }

    clearAlerts = () => {
        this.dispatch({type: ACTIONS.CLEAR_ALERTS})
    }
}