import {useState, useEffect} from 'react'
import { Form, Button } from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux'
import SpartanApi from '../helpers/spartan-api'
import CreateDeviceTypeFirmwareModal from './modals/CreateDeviceTypeFirmwareModal';
import UpdateDeviceTypeFirmwareModal from './modals/updateDeviceTypeFirmware';

/*
    CREATE FIRMWARE
    UPDATE FIRMWARE
    GET FIRMWARE BY DEVICE TYPE
*/

export default function DeviceTypeFirmware(){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [deviceTypeId, setDeviceTypeId] = useState(() => undefined);
    const [firmwareToUpdate, setFirmwareToUpdate] = useState(() => undefined);
    const [createModalOpen, setCreateModalOpen] = useState(() => false);
    const [updateModalOpen, setUpdateModalOpen] = useState(() => false);
    const [newOrder, setNewOrder] = useState(() => {return {}})
    const deviceData = useSelector(s => s.device);

    const loadInitialData = async() => {
        await api.attemptGetDeviceTypes()
    }

    const handleDeviceTypeChange = async(evt) => {

        if (evt.target.value == "Select a Device type"){
            setDeviceTypeId(undefined)
            return;
        }
        setDeviceTypeId(evt.target.value);
        await api.attemptGetDeviceTypeFirmwareByDeviceTypeId(evt.target.value);
    }

    const closeCreateModal = async(device_type_id) => {
        setCreateModalOpen(false);
        await api.attemptGetDeviceTypeFirmwareByDeviceTypeId(device_type_id);
    }

    const closeUpdateModal = async(device_type_id) => {
        setUpdateModalOpen(false);
        setFirmwareToUpdate(undefined);
        await api.attemptGetDeviceTypeFirmwareByDeviceTypeId(device_type_id);
    }

    const handleOpenUpdateModal = async(firmware) => {
        setUpdateModalOpen(true);
        setFirmwareToUpdate(firmware);
    }

    const changeOrder = (id, number) => {
        console.log(newOrder)
        console.log("FIRING: ", id, " ", number)
        setNewOrder({...newOrder, [id]: number})
    }

    const reorderFirmware = async() => {
        if (!Object.keys(newOrder).length) return;
        const firmwareVersions = [];
        Object.keys(newOrder).forEach(id => {
            firmwareVersions.push({
                id: id,
                release_sequence: newOrder[id]
            })
        });
        await api.attemptFirmwareReorder({firmwareVersions});
        setNewOrder(() => {
            return {}
        });
        await api.attemptGetDeviceTypeFirmwareByDeviceTypeId(deviceTypeId);
    }

    
    

    const buildFirmwareRows = () => {
      if (!deviceTypeId) return null;
      const firmware = deviceData.deviceTypeFirmware[deviceTypeId]
      if (!firmware) return null;

      return (
        <tbody>
            {firmware.sort((a, b) => b.release_sequence - a.release_sequence).map((f, i) => {
                const formValue = (newOrder[f.id] >= -1 ) ? newOrder[f.id] : f.release_sequence
                
                return (
                    <tr className="text-center" key={i}>
                        <td>{f.firmware_version}</td>
                        <td>{f.firmware_github_url} <br/> <a target="_blank" href={f.firmware_github_url}>check here</a></td>
                        <td>{f.zip_download_url ? <a target='_blank' href={f.zip_download_url} download> Download </a> : 'NOT SET'}</td>
                        <td>{f.default_config_url ? <a target='_blank' href={f.default_config_url} download>Download</a>: 'NOT SET'}</td>
                        <td>{f.is_active ? "ACTIVE" : "INACTIVE"}</td>
                        <td>
                            <Form.Control className="mx-auto" 
                                          style={{width: 100}} 
                                          type={'number'}
                                          value={formValue}
                                          onChange={evt => changeOrder(f.id, Number(evt.target.value))}/>
                        </td>
                        <td>{f.created_on}</td>
                        <td><button onClick={() => handleOpenUpdateModal(f)} className="btn btn-primary">UPDATE</button></td>
                    </tr>
                )
            })}
        </tbody>
      )
    }

    useEffect(() => {
        //LOAD NEEDED DATA
        loadInitialData();
    }, []);

    if (createModalOpen) {
        return <CreateDeviceTypeFirmwareModal close={closeCreateModal} />
    }

    if (updateModalOpen) {
        return <UpdateDeviceTypeFirmwareModal close={closeUpdateModal} firmware={firmwareToUpdate}/>
    }

    
    return (
        <div>
            <select onChange={handleDeviceTypeChange} value={deviceTypeId}>
                <option value={undefined}>Select a Device Type</option>
                {deviceData.deviceTypes.map((dt, i) =>  {
                    return <option key={i} value={dt.id}>{dt.device_line} | {dt.device_name} | {dt.model}</option>
                })}
            </select>
            <button className="btn btn-primary" onClick={() => setCreateModalOpen(true)}>Create Firmware Version</button>

            <br/><br/> <br/>
            <table className="table table-dark text-center">
                <thead>
                    <tr className="text-center">
                        <th>Firmware Version</th>
                        <th>Firmware Github Url</th>
                        <th>Firmware Zip Download Url</th>
                        <th>INI File</th>
                        <th>Firmware Active</th>
                        <th>Release Sequence</th>
                        <th>Created On</th>
                        <th>Update</th>
                    </tr>
                </thead>
                {buildFirmwareRows()}
            </table>
            {Object.keys(newOrder).length > 0 ? (
                <div className="text-center">
                    <Button onClick={reorderFirmware}>Re-Order Firmware</Button>
                </div>
            ): null}
        </div>
    )
}