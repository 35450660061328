import {Modal, Button, Table} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';
import moment from 'moment';

export default function ViewLostInventoryModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const lostDevices = useSelector(s => s.device.lostDevices);

    const loadLostDevices = async() => {
        await api.attemptGetMissingDevices();
    }

    const reportFound = async (serial_number) => {
        await api.attemptReportFound({serial_number})
        loadLostDevices();
    }

    const buildLostDevices = () => {
        if (!lostDevices.length) return (
            <div className="mt-5 mb-5 text-center">
                No Devices reported missing at this time.
            </div>
        )

        return (
            <div className="mt-5">
                <Table variant="dark" striped>
                    <thead>
                        <tr>
                            <th>Serial Number</th>
                            <th>Model</th>
                            <th>Reported by</th>
                            <th>Reported on</th>
                            

                        </tr>
                    </thead>

                    <tbody>
                        {
                            lostDevices.map((ld, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{ld.serial_number}</td>
                                        <td>{ld.model}</td>
                                        <td>{ld.user_first_name} {ld.user_last_name}</td>
                                        <td>{moment(ld.created_on).format("MM/DD/YYYY hh:mma")}</td>
                                        <td><Button onClick={() => reportFound(ld.serial_number)}>Report Found</Button></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    useEffect(() => {
        loadLostDevices();
    }, [])


    

    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header className="spartan-modal" closeButton>
                LOST DEVICES
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                {buildLostDevices()}
            </Modal.Body>
            
        </Modal>
    )
}