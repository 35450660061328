import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api'


export default function CreateDeviceTypeModal ({closeCB}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));

    const [newDeviceType, setNewDeviceType] = useState(() => {
        return {
           device_line: '',
           device_name: '',
           model: ''
        }
    });


    const createNewDeviceType = async(evt) => {
        evt.preventDefault();
        await api.attemptCreateDeviceType(newDeviceType)
        closeCB();
    }


    return (
        <div className="spartan-modal">
            <form onSubmit={createNewDeviceType}>
                <h6>Device/Product Line</h6>
                <input name="device_line" 
                       type="text" 
                       value={newDeviceType.device_line} 
                       onChange={evt => setNewDeviceType({...newDeviceType, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>Device Name</h6>
                <input name="device_name" 
                       type="text" 
                       value={newDeviceType.device_name} 
                       onChange={evt => setNewDeviceType({...newDeviceType, [evt.target.name]: evt.target.value})} 
                       required />

                <h6>Model</h6>
                <input name="model" 
                       type="text" 
                       value={newDeviceType.model} 
                       onChange={evt => setNewDeviceType({...newDeviceType, [evt.target.name]: evt.target.value})} 
                       required />

               
                <button type="submit">Create New Device Type</button>
            </form>
        </div>
    )
}