import {Modal, Form, Button} from 'react-bootstrap'
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import { getDisplayPriceFromCents } from '../../helpers/formatters';

export default function CreatePremiumProfileModal({close}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [formData, setFormData] = useState(() => {
        return {
            profile_name: undefined,
            profile_description: undefined,
            price_in_cents: 0,
            profile_meta_data: {}
        }
    });

    const save = async(evt) => {
        evt.preventDefault();
        await api.attemptCreateExternalConfigToolPremiumProfile(formData);
        close();
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal" closeButton>
                CREATE A PREMIUM PROFILE
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={save}>
                    <Form.Group>
                        <Form.Label>Profile Name</Form.Label>
                        <Form.Control type="text" 
                                      value={formData.profile_name} 
                                      onChange={evt => setFormData({...formData, profile_name: evt.target.value})}
                                      required/>
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>Profile Description</Form.Label><br/>
                        <textarea value={formData.profile_description}
                                  onChange={evt => setFormData({...formData, profile_description: evt.target.value})}
                                  required/>
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>Price in Cents</Form.Label>
                        <br/>
                        <strong>{getDisplayPriceFromCents(formData.price_in_cents || 0)}</strong>
                        <Form.Control type="number" value={formData.price_in_cents} onChange={evt => setFormData({...formData, price_in_cents: evt.target.valueAsNumber})} />
                    </Form.Group>

                    <div className="text-center mt-3 mb-5">
                        <Button type="submit">CREATE PROFILE</Button>
                    </div>
                </Form>
            </Modal.Body> 
        </Modal>
    )
}