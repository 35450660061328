import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import AlertHelper from "../../helpers/alert-helper";

export default function AlertContainer() {
    const dispatch = useDispatch();
    const [alertHelper] = useState(() => new AlertHelper({dispatch}));
    const alerts = useSelector(s => s.alerts.alerts)
    const closeAlert = (index) => {
        alertHelper.removeAlert(index)
    }
    return (
        <div>
            {alerts.map((a, i) => {
                return (
                    <Alert variant="danger" key={i} onClose={() => closeAlert(i)} dismissible>
                        <Alert.Heading>
                            {a.title}
                        </Alert.Heading>
                        <p>{a.body}</p>
                    </Alert>
                )
            })}
        </div>
    )
}