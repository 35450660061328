import {Modal, Form, Button} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';

export default function CreateDeviceTypeBaseConfigModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api]    = useState(() => new SpartanApi({dispatch, navigate}));
    const deviceTypes = useSelector(s => s.device.deviceTypes);
    const [baseConfig, setBaseConfig] = useState(() => {
        return {
            title: undefined,
            github_url: undefined,
            device_type_id: undefined,
            description: undefined
        }
    });

    const loadDeviceTypes = async() => {
        await api.attemptGetDeviceTypes();
    }

    const save = async (evt) => {
        evt.preventDefault();
        await api.attemptCreateDeviceTypeBaseConfigurations(baseConfig);
        close();
    }

    const handleChange = (key, value) => {
        setBaseConfig({...baseConfig, [key]: value});
    }

    useEffect(() => {
        loadDeviceTypes();
    }, []);

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal">
                Create New Device Type Base Configuration
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={save}>

                    <Form.Group className="mb-3">
                        <Form.Label>Configuration Title</Form.Label>
                        <Form.Control value={baseConfig.title} onChange={evt => handleChange('title', evt.target.value)} required/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Device Type</Form.Label>
                        <Form.Select value={baseConfig.device_type_id} onChange={evt => handleChange('device_type_id', evt.target.value)} required>
                            <option></option>
                            {deviceTypes.map((dt, i) => {
                                return (
                                    <option key={i} value={dt.id}>{dt.model} | {dt.device_name} | {dt.device_line}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Github Url</Form.Label>
                        <Form.Control type="text" value={baseConfig.github_url} onChange={evt => handleChange('github_url', evt.target.value)} required/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <textarea className="form-control" value={baseConfig.description} onChange={evt => handleChange('description', evt.target.value)} required />
                    </Form.Group>

                    <div className="text-center">
                        <Button type="submit">CREATE BASE CONFIGURATION</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )

}