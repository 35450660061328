import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpartanApi from "../helpers/spartan-api";
import { decipherQrData } from "../helpers/qr-reader";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import CreateDeviceModal from '.././admin/modals/CreateDeviceModal';
import ViewTestResultsModal from "../shared/modals/ViewTestResultsModal";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import moment from "moment";

export default function TestDevice () {
    const dispatch = useDispatch();
    const state    = useSelector(s => s);
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [calibrationFile, setCalibrationFile] = useState(() => undefined);
    const [isUploading, setIsUploading] = useState(() => false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(() => false);
    const [isViewResultsModalOpen, setIsViewResultsModalOpen] = useState(() => false);
    const [isAddingDevice, setIsAddingDevice] = useState(() => false);
    const [barcode, setBarcode] = useState(() => '');

    const [qrData, setQrData] = useState(() => {
        return {
            model: undefined,
            serialNumber: undefined
        }
    });

    const [deviceData, setDeviceData] = useState(() => {
        return {
            deviceTypeData: null,
            deviceData: null
        }
    });

    const [testCase, setTestCase] = useState(() => undefined);
    const [test, setTest] = useState(() => null)
    
    const resetData = () => {
        setQrData({
            model: undefined,
            serialNumber: undefined
        });

        setDeviceData({
            deviceTypeData: null,
            deviceData: null
        })
    }

    const loadInitialData = async() => {
        await api.attemptCheckOnline();
        await api.attemptGetSitesByUser();
        await api.attemptGetDevices();
        await api.attemptGetDeviceTypes();
    }

    const load = async() => {
        await api.attemptGetDeviceTestCasesBySite(deviceData.deviceTypeData.id, state.user.currentSite.id);
        await api.attemptGetDeviceTestResults(deviceData.deviceData.device_id);
        await api.attemptGetDeviceCalibrationFile(deviceData.deviceData.device_id);
        await api.attemptGetDeviceTypeFirmwareByDeviceTypeId(deviceData.deviceTypeData.id)
        await api.attemptGetDeviceTypeBaseConfigurationsByDeviceTypeId({device_type_id: deviceData.deviceTypeData.id})
        await api.attemptGetToken();
    }

    const onBarcodeScan = (evt) => {
        evt.preventDefault();
        if (!barcode) return;
        const data = decipherQrData(barcode);
        setQrData(data);
    }

    const decipher = (result, error) => {
        if (error) return;
        const data = decipherQrData(result.text);
        setQrData(data);
    }

    const closeModal = async () => {
        setIsCreateModalOpen(false);
        searchSerialNumber();
    }

    const addDeviceToSystem = async() => {
        const deviceTypeData = state.device.deviceTypes.filter(dt => dt.model === qrData.model)[0];
        const requestData = {
            serial_number: qrData.serialNumber,
            device_type_id: deviceTypeData.id,
            current_site: state.user.currentSite.id
        }

        await api.attemptCreateDevice(requestData);
        setIsAddingDevice(false);
        searchSerialNumber();
    }

    const searchSerialNumber = async() => {
        if (!qrData.serialNumber || isAddingDevice) return;
        const searchedDevice = await api.attemptGetDeviceBySerialNumber(qrData.serialNumber);
        
        //SERIAL NUMBER DOESN'T EXIST
        if (!searchedDevice) {
            setIsAddingDevice(true);
            addDeviceToSystem();
            return;
        }

        setDeviceData({
            deviceData: searchedDevice,
            deviceTypeData:  [...state.device.deviceTypes].filter(dt => dt.model == searchedDevice.model)[0]
        })
    }

    const handleFirmwareChange = async(evt) =>  {
        const value = evt.target.value;

        if (value.toLowerCase() ==  'select a firmware') return null;

        setDeviceData({
            deviceData: {...deviceData.deviceData, firmware_version_id: evt.target.value},
            deviceTypeData: {...deviceData.deviceTypeData}
        });

    }

    const handleBaseConfigurationChange = async(evt) => {
        const value = evt.target.value;

        setDeviceData({
            deviceData: {...deviceData.deviceData, base_configuration_id: value},
            deviceTypeData: {...deviceData.deviceTypeData}
        });
    }

    const saveFirmwareToDevice = async(evt) => {
        if (!deviceData.deviceData.firmware_version_id) return;

        const requestData = {
            device_id: deviceData.deviceData.device_id,
            firmware_version_id: deviceData.deviceData.firmware_version_id
        }

        await api.attemptUpdateDevice(requestData)
        searchSerialNumber();
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    useEffect(() => {
        searchSerialNumber();    
    }, [qrData]);

    useEffect(() => {
        if (!deviceData.deviceData) return;
       //LOAD DEVICE SPECIFIC DATA
       load();
    }, [deviceData]);

    const onTestChange = (id) => {

        const site_id = state.user.currentSite.id
        const device_type_id = deviceData.deviceTypeData.id
        const testCases= state.device.siteTestCases[site_id][device_type_id]
        setTestCase(id);
        let t = testCases.filter(tc => tc.id == id)[0];
        if (!t) return setTest(null);
        if (t && t.test_case && t.test_case.tests && Array.isArray(t.test_case.tests)){
            t.test_case.tests = t.test_case.tests.map(newTest => {
                newTest.passed = true; //Defealt to true
                return newTest;
            })
        }
        setTest({...t});
    }

    const buildQrCode = () => {
        if (deviceData.deviceData) return null;
        return (
            <div>
                <h3 className="text-center">Scan the QR Code</h3>
            
            <div style={{width: 300, height: 200, margin: '0px auto'}}>
                <QrReader  constraints={{facingMode: 'environment'}} onResult={decipher} style={{ width: '100%' }}/>
                <br/> 
                        <h5>You can also use the barcode scanner. Click the input box below and scan the QR Code</h5>
                        <Form onSubmit={onBarcodeScan}>
                            <Form.Control value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                        </Form>
            </div>
            </div>
        )
    }

    const buildTestCaseOptions = () => {
        if (!state.user.currentSite || !state.user.currentSite.id) return null;
        if (!deviceData || !deviceData.deviceTypeData || !deviceData.deviceTypeData.id) return null;
        const site_id = state.user.currentSite.id
        const device_type_id = deviceData.deviceTypeData.id
        if (!state.device.siteTestCases[site_id] || !state.device.siteTestCases[site_id]) return null;
        if (!state.device.siteTestCases[site_id][device_type_id]) return null;
        if (!deviceData.deviceData) return null;
      
       
        const testCases= state.device.siteTestCases[site_id][device_type_id]
    
        return (
            <Card bg="dark" text="white" className="text-center mt-5">
                <Card.Header>
                    <h3>Run Device Testing</h3>
                    <p>Select a Test Below</p>
                </Card.Header>
                <Card.Body>
                <Form.Select value={testCase} onChange={evt => onTestChange(evt.target.value)}>
                <option>Select A Test Case</option>
                {
                    testCases.map((tc, i) => {
                        return (
                            <option key={i} value={tc.id}>{tc.test_name}</option>
                        );
                    })
                }
                </Form.Select>
                </Card.Body>
            
            </Card>
        )
    }
    const constructLocationString = () => {
        if (!state.user.currentSite) return;
        return `${state.user.currentSite.site_city}, ${state.user.currentSite.site_state} ${state.user.currentSite.site_country}`
    }

    const buildDeviceDataSection = () => {
        if (!deviceData.deviceData) return null;
        return (
            <Card bg="dark" color="white" className=" mb-5" >
                <Card.Header className="text-center">
                    <h5>Device Information</h5>
                </Card.Header>
                <Card.Body>
                    <strong>Device Type: </strong> <span>{deviceData.deviceData.device_name}</span> <br/>
                    <strong>Device Serial Number: </strong> <span>{deviceData.deviceData.device_serial_number}</span> <br/>
                    <strong>Device Line: </strong> <span>{deviceData.deviceData.device_line}</span><br/>
                    <strong>Device Model: </strong> <span>{deviceData.deviceData.model}</span><br/>
                    <strong>Device Location: </strong> <span>{constructLocationString()}</span> <br/>
                </Card.Body>
                <Card.Footer className="text-center">
                    <button onClick={resetData} className="btn btn-primary">Test a Different Device</button>
                </Card.Footer>
                
            </Card>
        )
    }

    const buildFirmwareSection = () => {
        if (!deviceData.deviceTypeData) return null;
        const firmwareOptions = state.device.deviceTypeFirmware[deviceData.deviceTypeData.id];
        if (!firmwareOptions || !firmwareOptions.length) return null;
        return(
        <Card bg="dark" color="white" className="text-center mt-5"  >
            <Card.Header>Device Firmware <br/>  {deviceData.deviceData.firmware_github_url ? <a target="_blank" href={deviceData.deviceData.firmware_github_url}>Download Binary</a> : null} <br/></Card.Header>
            <Card.Body>
            <Form.Select value={deviceData.deviceData.firmware_version_id} onChange={handleFirmwareChange}>
                <option>Select A Firmware</option>
                {firmwareOptions.map((f,i) => {
                    return <option key={i} value={f.id}>{f.firmware_version}</option>
                })}
            </Form.Select>
            </Card.Body>

            <Card.Footer>
                <button className="btn btn-primary" onClick={saveFirmwareToDevice}>Save Firmware</button>
            </Card.Footer>
        </Card>
        )
    }

    const buildConfigFileSection = () => {
        if (!deviceData.deviceTypeData ||
            !deviceData.deviceTypeData.device_configs ||
            !deviceData.deviceTypeData.device_configs.length) return null;
        return (
            <Card bg="dark" color="white" className="text-center mt-5 mb-2">
                <Card.Header className="card-header"><h5>Device Type Data Files</h5></Card.Header>
                <Card.Body>
                <table className="table table-dark">
                    <thead>
                        <tr>
                            <th>Device Type Data File</th>
                            <th>Uploaded On</th>
                            <th>Download</th>
                        </tr>
                    </thead>

                    <tbody>
                        {deviceData.deviceTypeData.device_configs.map((dc, i) => {
                            return (
                                <tr key={i}>
                                    <td>{dc.id}</td>
                                    <td>{dc.created_on}</td>
                                    <td><a href={dc.download_url} download>Download Here</a></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </Card.Body>
            </Card>
        )
    }

    const onTestUpdate = (key, value, index) => {
        const t = {...test};
        t.test_case.tests[index][key] = value;
        setTest(t);
    }

    const updateBaseConfiguration = async (evt)  => {
        evt.preventDefault();

        const requestData = {
            device_id: deviceData.deviceData.device_id,
            base_configuration_id: deviceData.deviceData.base_configuration_id,
        }

        await api.attemptUpdateDevice(requestData);
    }

    const saveTest = async() => {
        const requestObject = {
            test_results: {...test},
            device_passed: true,
            test_site_id: state.user.currentSite.id,
            device_id: deviceData.deviceData.device_id,
            device_test_case_id: test.id
        }

        let t = [...test.test_case.tests];

        t.forEach((c , i) => {
            if (!c.passed) {
                requestObject.device_passed = false;
            }
        })

        await api.attemptSaveDeviceTestResults(requestObject);

        setTestCase(undefined)
        setTest(null);

        await api.attemptGetDeviceTestResults(deviceData.deviceData.device_id)
    }
 

    const uploadCalibrationFile = async() => {
        
        if (!calibrationFile) return;
        await api.attemptGetToken();
   
        if (!state.user.sasToken) return;
        setIsUploading(true)
    
        const blobService = new BlobServiceClient('https://spartanproductiontest.blob.core.windows.net/?' + state.user.sasToken);

        const containerClient = blobService.getContainerClient('calibrationfiles');
        await containerClient.createIfNotExists({access: 'container'});
        
        const uploadDate = Date.now();
        const fileName = uploadDate+calibrationFile.name

    
        const blobClient = containerClient.getBlockBlobClient(fileName);
        const options = {blobHTTPHeaders: {blobContentType: calibrationFile.type}};
        await blobClient.uploadBrowserData(calibrationFile, options);

        await api.attemptSaveDeviceCalibrationFile({device_id: deviceData.deviceData.device_id,  download_url:`https://spartanproductiontest.blob.core.windows.net/calibrationfiles/${fileName}` });
     
        setIsUploading(false)
        setCalibrationFile(undefined);
        load();
    }


    const buildTestingArea = () => {
        if (!deviceData.deviceData || !testCase || !test) return null;
       
        return (
            
            <Card bg="dark" text="white" className="mt-5">
                <Card.Header className="text-center">
                    <h3>Begin Testing</h3>
                    <strong>{test.test_name}</strong>
                </Card.Header>
                <Card.Body>
                {
                    test.test_case.tests.map((t, i) => {
                        return (
                            <div key={i} className="mt-4">
                                <h4>{t.title}</h4>
                                <p>{t.description}</p>
                                <div className="form-check form-switch">
                                    
                                    <label className="form-check-label">Device Passes</label> 
                                    <input className="form-check-input" checked={t.passed} type="checkbox" onChange={evt => onTestUpdate('passed', evt.target.checked, i)}/>
                                </div>
                                <h6 className="mt-2">Testing Notes</h6>
                                <Form.Control as="textarea" value={t.notes} onChange={evt => onTestUpdate('notes', evt.target.value, i)}/>
                                <hr/>
                            </div>
                        )
                    })
                }
                </Card.Body>
                
                
                <Card.Footer className="text-center">
                    <Button onClick={saveTest}>Save Results</Button>
                </Card.Footer>
            </Card>
           
        )
    }

    const buildResultsSection = () => {
        if (!deviceData.deviceData) return null;
        const results = state.device.deviceTestResults[deviceData.deviceData.device_id];
        if (!results) return null;
      
        return (
            <Card bg="dark" text="white" className="text-center mt-5">
                <Card.Header>
                    <h3>Test Results</h3>
                    <span onClick={() => setIsViewResultsModalOpen(true)} className="clicky">View Full Results</span>
                </Card.Header>
                <Card.Body>
                <table className="table table-dark">
                    <thead>
                        <tr>
                        <th>Test Name</th>
                        <th>Test Result</th>
                        <th>Tested On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((r,i) => {
                            return (
                                <tr key={i}>
                                    <td>{r.test_results.test_name}</td>
                                    <td>{r.device_passed ? 'Passed': 'Failed'}</td>
                                    <td>{r.created_on ? moment(r.created_on).format('MM/DD/YYYY hh:mma') : ''}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </Card.Body>
            </Card>
        )
    }

    const buildFileCalibrationSection = () => {
        if (!deviceData.deviceData) return null;
     
        if (!state.device.deviceCalibrationFiles[deviceData.deviceData.device_id] ||
            !state.device.deviceCalibrationFiles[deviceData.deviceData.device_id].length) {
                return (
                    <Card bg="dark" text="white" className=" text-center mb-2 mt-5">
                        <Card.Header>
                            No Device Instance Data Files have been uploaded 
                        </Card.Header>
                        <Card.Body>
                        <strong>Add Device Instance Data Here: </strong>
                        <input onChange={evt => {
                            //console.log(evt.target.files)
                            setCalibrationFile(evt.target.files[0])
                            }} type="file"  />
                        </Card.Body>
                        <Card.Footer>
                            <button disabled={isUploading} onClick={uploadCalibrationFile} className='btn btn-primary'>{isUploading? "THE FILE IS UPLOADING: PLEASE WAIT" : "UPLOAD FILE"}</button>
                        </Card.Footer>
                    </Card>
                )
        }

        const deviceCalibrationFiles = state.device.deviceCalibrationFiles[deviceData.deviceData.device_id];

        return (
            <Card bg="dark" text="white" className=" text-center mb-2 mt-5">
                <Card.Header className="card-heading">
                    Device Instance Data Files
                </Card.Header>
                <Card.Body>
                <table className="table table-dark">
                    <thead>
                        <tr>
                            <th>File Id</th>
                            <th>Uploaded on</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deviceCalibrationFiles.map((f, i) => {
                            return (
                                <tr key={i}>
                                    <td>{f.id}</td>
                                    <td>{f.created_on}</td>
                                    <td><a href={f.download_url} download>Download Here</a></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                </Card.Body>
                <Card.Footer>
                    <strong>Add Calibation File Here: </strong>
                    <input onChange={evt => {
                            setCalibrationFile(evt.target.files[0])
                            }} type="file"/>
                    <button disabled={isUploading} onClick={uploadCalibrationFile} className='btn btn-primary'>{isUploading? "THE FILE IS UPLOADING: PLEASE WAIT" : "UPLOAD FILE"}</button>
                </Card.Footer>
            </Card>
        )
    }

    const buildDeviceBaseConfigurationSection = () => {
        if (!state.user.currentSite || !state.user.currentSite.id) return null;
        if (!deviceData || !deviceData.deviceTypeData || !deviceData.deviceTypeData.id) return null;
        const configs = state.device.deviceTypeBaseConfigurations;
        if (!configs.length) return null;
        console.log(deviceData.deviceData)
        return (
            <Card bg="dark" text='white'>
                <Form onSubmit={updateBaseConfiguration}>
                <Card.Header className="text-center">
                    Base Configuration
                </Card.Header>
                <Card.Body>
                    <Form.Group>
                        <Form.Label>
                            Select the Base Configuration
                        </Form.Label>
                        <Form.Select value={deviceData.deviceData.base_configuration_id} onChange={handleBaseConfigurationChange} required>
                            <option></option>
                            {configs.map((c, i) => {
                                return (
                                    <option key={i} value={c.id}>{c.title} | {moment(c.created_on).format('MM/DD/YYYY hh:mma')}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                </Card.Body>
                <Card.Footer className="text-center">
                    <Button type="submit">Update Base Configuration</Button>
                </Card.Footer>
                </Form>
            </Card>
        )
    }
 

    //CREATE MODAL OPEN
    if (isCreateModalOpen) {
        return <CreateDeviceModal closeCB={closeModal}/>
    }

    if (isViewResultsModalOpen){
        return <ViewTestResultsModal close={() => setIsViewResultsModalOpen(false)} deviceId={deviceData.deviceData.device_id}/>
    }

    return (
        <Container>
            
            
            {buildQrCode()}
            
            {buildDeviceDataSection()}
            {buildDeviceBaseConfigurationSection()}
            {buildFirmwareSection()}
            {buildConfigFileSection()}
            {buildFileCalibrationSection()}
            <div>
                {buildResultsSection()}
                {buildTestCaseOptions()}
                {buildTestingArea()}
            </div>
        </Container>
        
    )
}