import {Modal, Table, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';
import moment from 'moment';

export function ViewCheckedOutDevicesModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checkedOutDevices = useSelector(s => s.device.checkedOutDevices)
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));

    const loadCheckedOutDevices = async() => {
        await api.attemptGetCheckedOutDevices();
    }

    const checkInDevice = async (serial_number) => {
        await api.attemptCheckInDevice({serial_number});
        loadCheckedOutDevices();
    }

    const buildCheckedOutDeviceSection = () => {
        if (!checkedOutDevices.length) return (
            <div className="mt-5 mb-5 text-center">
                No Devices Checked out at this time.
            </div>
        )

        return (
            <div className="mt-5 mb-5">
                <Table variant='dark' striped>
                    <thead>
                        <tr>
                            <th>Serial Number</th>
                            <th>Model</th>
                            <th>Checked Out By</th>
                            <th>Checked Out On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checkedOutDevices.map((c, i) => {
                            return (
                                <tr key={i}>
                                    <td>{c.serial_number}</td>
                                    <td>{c.model}</td>
                                    <td>{c.user_first_name} {c.user_last_name}</td>
                                    <td>{moment(c.created_on).format("MM/DD/YYYY hh:mma")}</td>
                                    <td><Button onClick={() => checkInDevice(c.serial_number)}>Check in</Button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    useEffect(() => {
        loadCheckedOutDevices();
    }, []);

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton className="spartan-modal">
                Checked Out Devices
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                {buildCheckedOutDeviceSection()}
            </Modal.Body>
        </Modal>
    )


}