import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import Accordion from 'react-bootstrap/esm/Accordion';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import { Card, Modal } from 'react-bootstrap';
import moment from 'moment'

export default function ViewTestResultsModal({close, deviceId}){
    const dispatch = useDispatch();
    const[api] = useState(() => new SpartanApi({dispatch}));
    const testData = useSelector(s => s.device.deviceTestResults);
    const devices = useSelector(s => s.device.devices);
    const [device, setDevice] = useState(() => undefined);
    const loadTestResults = async()=> {
        await api.attemptGetDeviceTestResults(deviceId)
    }

    useEffect(() => {
        if (!deviceId) return;
        loadTestResults();
        const foundDevice = devices.find(d => d.device_id == deviceId);
        setDevice(foundDevice)
    }, [deviceId]);

    const buildDeviceDataSection = () => {
        if (!device) return null;
        console.log(device)
        return (
            <Card className="text-bg-dark text-center">
                <Card.Header>DEVICE TEST RESULTS</Card.Header>
                <Card.Body>
                    <strong>Serial Number: </strong>  <span>{device.device_serial_number}</span>
                    |
                    <strong>Device Name: </strong>  <span>{device.device_name}</span>
                    |
                    <strong>Device Line: </strong>  <span>{device.device_line}</span>
                    |
                    <strong>Model: </strong>  <span>{device.model}</span>
                    <br/> <br/> <br/>
                    <strong>Test Overview</strong> <br/>
                    <p>Passed {device.tests_pass_count} out of {device.test_count} Total Tests</p>
                    
                </Card.Body>
            </Card>
        )
    }
    
    const buildTest = (test, index) => {
        return (
            <AccordionItem eventKey={index}>
                <AccordionHeader>{test.test_results.test_name} -  {test.device_passed ? "Passed" : "Failed"} on {moment(test.created_on).format("MM/DD/YYYY hh:mm:ssa")} <br/> Tested By {test.tester_first_name} {test.tester_last_name}</AccordionHeader>
                <AccordionBody>
                    {
                        test.test_results.test_case.tests.map((t, i) => {
                            return (
                            <Card>
                                <Card.Header>{t.title} {`(${t.passed ? "Passed": "Failed"})`}</Card.Header>
                                <Card.Body>
                                    <h6>Description</h6>
                                    <p>{t.description}</p>

                                    <h6>Tester Notes</h6>
                                    <p>{t.notes || "None"}</p>
                                </Card.Body>
                            </Card>
                            )
                        })
                    }
                </AccordionBody>
            </AccordionItem>
        )
    }

    const buildResults = () => {
        if (!testData[deviceId]) {
            return (
                <div>Results Loading</div>
            )
        }

        return (
            <Accordion>
                {testData[deviceId].map((t, i) => {
                    return buildTest(t ,i)
                })}
            </Accordion>
        )
    }

    return (
        <Modal className="spartan-modal-backdrop" show={true} onHide={close}>
            <Modal.Header className="spartan-modal" closeButton>
                <Modal.Title>
                    VIEW TEST RESULTS
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <div>
                    {buildDeviceDataSection()}
                    {buildResults()}
                    
                </div>
    
            </Modal.Body>
        </Modal>
    )
}