import {Modal, Card} from 'react-bootstrap';
import moment from 'moment';

export default function ViewBaseConfigurationModal({close, deviceType, baseConfiguration}){
    if (!deviceType || !baseConfiguration) return null;

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal">
                {baseConfiguration.title}
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <h6>Github Url: {baseConfiguration.github_url}</h6>
                <h6>Device Type: {deviceType.device_line} | {deviceType.device_name} | {deviceType.model} </h6>
                <h6 className="mb-5">Created On: {moment(baseConfiguration.created_on).format('MM/DD/YYYY hh:mma')}</h6>

                <h6>Description: </h6>
                <p>
                    {baseConfiguration.description}
                </p>

            </Modal.Body>
        </Modal>
    )
}