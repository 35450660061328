import {Card, Table, Button} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import SpartanApi from '../helpers/spartan-api';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import CreateDeviceTypeBaseConfigModal from './modals/CreateDeviceTypeBaseConfigModal';
import ViewBaseConfigurationModal from './modals/ViewBaseConfigurationModal';
import moment from 'moment/moment';


export default function DeviceBaseConfigs(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [createModalOpen, setCreateModalOpen ] = useState(() => false);
    const [viewModalOpen, setViewModalOpen] = useState(() => false);
    const [viewDeviceType, setViewDeviceType] = useState(() => null);
    const [viewConfig, setViewConfig] = useState(() => null);
    const deviceTypeBaseConfigurations = useSelector(s => s.device.deviceTypeBaseConfigurations)
    const deviceTypes = useSelector(s => s.device.deviceTypes);

    const closeModal = async() => {
        await loadData();
        setCreateModalOpen(() => false);
        setViewModalOpen(() => false);
        setViewDeviceType(() => null);
        setViewConfig(() => null);
    }

    const openViewModal = (deviceType, config) => {
        setViewDeviceType(() => deviceType);
        setViewConfig(() => config );
        setViewModalOpen(true)
    }

    const loadData = async () => {
        await api.attemptGetDeviceTypes();
        await api.attemptGetDeviceTypeBaseConfigurations();
        
    }

    const buildBaseConfigurations = () => {
        if (!deviceTypeBaseConfigurations || !deviceTypeBaseConfigurations.length || !deviceTypes.length){
            return (
                <div className="text-center">
                    No Base Configurations Exist at this time.
                </div>
            )
        }
        
        return (
            <Table variant='dark' className="mt-5">
                <thead>
                    <tr>
                        <th>Configuration Title</th>
                        <th>Device Type</th>
                        <th>Github Url</th>
                        <th>Description</th>
                        <th>Created On</th>
                    </tr>
                </thead>
                <tbody>
                    {deviceTypeBaseConfigurations.map((d, i) => {
                        const dt = deviceTypes.find(a => a.id == d.device_type_id);
                        return (
                            <tr key={i}>
                                <td>{d.title}</td>
                                <td>{`${dt.device_line} | ${dt.device_name} | ${dt.model}`}</td>
                                <td>{d.github_url}</td>
                                <td><a className="clicky" onClick={() => openViewModal(dt, d)}>VIEW</a></td>
                                <td>{moment(d.created_on).format('MM/DD/YYYY hh:mma')}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    useEffect(() => {
        loadData();
    }, []);

    let modal = null;

    if (createModalOpen) {
        modal = <CreateDeviceTypeBaseConfigModal close={closeModal}/>
    } else if (  viewModalOpen){
        modal = <ViewBaseConfigurationModal close={closeModal} deviceType={viewDeviceType} baseConfiguration={viewConfig}/>
    }

    return (
        <Card bg="dark" text='white'>
            {modal}
            <Card.Header>
                Base Configurations
            </Card.Header>
            
            <Card.Body>
                <Button onClick={() => setCreateModalOpen(() => true)}>Create New Base Configuration</Button>
                {buildBaseConfigurations()}
            </Card.Body>
        </Card>
    )
}