import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    accessories: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_ACCESSORIES: {
                return action.payload;
            }
            default: return state;
        }
    }
})