import {Modal, Card, Button, Form} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import SpartanApi from '../../helpers/spartan-api';

export default function CreateReturnModal({device, close}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [deviceReturn, setDeviceReturn] = useState(() => {
        return {
            reason_for_return: undefined
        }
    })

    const attemptCreateReturn = async (evt) => {
        evt.preventDefault();
        await api.attemptCreateReturn({
            reason_for_return: deviceReturn.reason_for_return,
            device_id: device.device_id
        })
        close();
    }

    if(!device) return null;
    console.log("DEVICE TO RETURN: ", device);

    return (
        <Modal bg="dark" show={true} onHide={close}>
            <Modal.Body bg="dark">
                <Card bg='dark' color='white'>
                    <Card.Header>
                        CREATING A RETURN  for {device.serial_number || device.device_serial_number}
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={attemptCreateReturn}>
                            <Form.Group>
                                <Form.Label>
                                    Reason For Return
                                </Form.Label>
                                <Form.Select 
                                            value={deviceReturn.reason_for_return} 
                                            onChange={evt => setDeviceReturn({...deviceReturn, reason_for_return: evt.target.value})}
                                            required>
                                    <option></option>
                                    <option>Defect</option>
                                    <option>Damaged</option>
                                    <option>Resellable</option>
                                </Form.Select>
                            </Form.Group>
                            <div className="text-center mt-5">
                                <Button type="submit">Create Return</Button>
                            </div>
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}