import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";
import {useState, useEffect} from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpartanApi from "../helpers/spartan-api";
import CreateGlobalConfigurationModal from "./modals/CreateGlobalConfiguration";
import UpdateGlobalConfigurationModal from "./modals/updateGlobalConfiguration";
import CreateExternalFieldVisibilityModal from "./modals/CreateExternalFieldVisibilityModal";
import UpdateExternalFieldVisibilityModal from "./modals/UpdateExternalFieldVisibility";
import CreatePremiumProfileModal from "./modals/CreatePremiumProfileModal";
import UpdatePremiumProfileModal from "./modals/UpdatePremiumProfileModal";
import UploadECTVersionModal from "./modals/UploadECTVersionModal";
import { getDisplayPriceFromCents } from "../helpers/formatters";
import moment from "moment";

export default function ExternalConfigTool(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const [screen, setScreen] = useState(() => 'global_configs');
    const toolData = useSelector(s => s.externalTool);
    const [configurationToUpdate, setConfigurationToUpdate] = useState(() => null);
    const [updateConfigurationModalOpen, setUpdateConfigurationModalOpen] = useState(() => false);
    const [configurationModalOpen, setConfigurationModalOpen] = useState(() => false);
    const [visibleFieldModalOpen, setVisibleFieldModalOpen] = useState(() => false);
    const [updateVisibleFieldModalOpen, setUpdateVisibleFieldModalOpen] = useState(() => false);
    const [organizationToUpdate, setOrganizationToUpdate] = useState(() => null);
    const [premiumProfileModalOpen, setPremiumProfileModalOpen] = useState(() => false);
    const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(() => false);
    const [selectedProfile, setSelectedProfile] = useState(() => null);
    const [uploadVersionModalOpen, setUploadVersionModalOpen] = useState(() => false);
    const [appVersions, setAppVersions] = useState(() => []);

    const buildGlobalConfigs = () => {
        let message = (
            <div>
                There are no Global Configurations at this time. <a onClick={() => setConfigurationModalOpen(true)}>Create One Here</a>
            </div>
        )

        if (toolData.globalConfigurations.length) {
            message = (
                <div>
                    <Button onClick={() => setConfigurationModalOpen(true)}>CREATE NEW CONFIGURATION</Button>
                    <Table variant="dark" className="mt-2">
                        <thead>
                            <tr>
                                <th>
                                    Configuration Name
                                </th>
                                <th>
                                    View / Update
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {toolData.globalConfigurations.map((c, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{c.configuration_title}</td>
                                        <td><a onClick={() => {
                                            setConfigurationToUpdate(c);
                                            setUpdateConfigurationModalOpen(true)
                                        }}>View/Update</a></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            )
        }
        return (
            <div>
                {message}
            </div>
        )
    }

    const buildViewableFields = () => {
        console.log("where? ", toolData)
        return (
            <Table variant="dark">
                <thead>
                    <tr>
                        <th>Organization</th>
                        <th>Add / Update Fields</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        toolData.organizationsAndFields.map((o, i) => {
                            console.log(o)
                            return (
                                <tr>
                                    <td>{o.organization_name}</td>
                                    <td>
                                        {
                                            o.visible_fields ? <a onClick={() => {
                                                setUpdateVisibleFieldModalOpen(true);
                                                setOrganizationToUpdate(o)
                                            }}>Update Visible Fields</a> : <a onClick={() => {
                                                setVisibleFieldModalOpen(true);
                                                setOrganizationToUpdate(o);
                                            }}>Add Visible Fields</a>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    const loadInitialData = async () => {
        console.log("LOAD")
        await api.attemptGetGlobalExternalToolConfigurations();
        await api.attemptGetExternalToolFieldOptions();
        await api.attemptGetExternalToolCompaniesAndFields();
        await api.attemptGetExternalConfigToolPremiumProfiles();
        const versions = await api.attemptGetExternalConfigToolVersions();
        setAppVersions(versions);
    }

    const buildPremiumLicenses =  () => {
        if (!toolData.premiumProfiles || !toolData.premiumProfiles.length) {
            return (
                <div className="mt-5 mb-5 text-center">
                    No Premium Profiles have been created.
                </div>
            )
        }

        return (
            <div className="mt-5 mb-5 text-center">
                <Table variant="dark" striped>
                    <thead>
                        <tr>
                            <th>Profile Name</th>
                            <th>Profile Description</th>
                            <th>Profile Price</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {toolData.premiumProfiles.map((p, i) => {
                            return (
                                <tr key={i}>
                                    <td>{p.profile_name} <br/>
                                        <a onClick={() => {
                                            setSelectedProfile(p);
                                            setUpdateProfileModalOpen(true)
                                        }}>(Update Profile)</a>
                                    </td>
                                    <td><p>{p.profile_description}</p></td>
                                    <td>{getDisplayPriceFromCents(p.price_in_cents)}</td>
                                    <td>{moment(p.created_on).format('MM/DD/YYYY hh:mma')}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    const closeConfigurationModal = async () => {
        loadInitialData();
        setConfigurationModalOpen(false);
        setUpdateConfigurationModalOpen(false);
        setVisibleFieldModalOpen(false);
        setUpdateVisibleFieldModalOpen(false);
        setPremiumProfileModalOpen(false);
        setUpdateProfileModalOpen(false);
        setUploadVersionModalOpen(false);
        setSelectedProfile(() => null);
    }

    let modal = null;

    if (configurationModalOpen) {
        modal = <CreateGlobalConfigurationModal close={closeConfigurationModal}/>
    } else if (updateConfigurationModalOpen){
        modal = <UpdateGlobalConfigurationModal configuration={configurationToUpdate} close={closeConfigurationModal} />
    } else if (visibleFieldModalOpen){
        modal = <CreateExternalFieldVisibilityModal organization={organizationToUpdate} close={closeConfigurationModal} />
    } else if (updateVisibleFieldModalOpen){
        modal = <UpdateExternalFieldVisibilityModal organization={organizationToUpdate} close={closeConfigurationModal} />
    } else if (premiumProfileModalOpen) {
        modal = <CreatePremiumProfileModal close={closeConfigurationModal} />
    } else if (updateProfileModalOpen && selectedProfile) {
        modal = <UpdatePremiumProfileModal close={closeConfigurationModal} profile={selectedProfile} />
    } else if (uploadVersionModalOpen) {
        modal = <UploadECTVersionModal close={closeConfigurationModal} />
    }

    let section = null;

    if (screen == 'global_configs') {
        section = buildGlobalConfigs();
    } else {
        section = buildViewableFields();
    }

    return (
        <Container>
            {modal}

            <Card bg="dark" text="white">
                <Card.Header>
                    Premium License Profiles
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Button onClick={() => setPremiumProfileModalOpen(() => true)}>Create New Profile</Button>
                        </Col>
                    </Row>
                    {buildPremiumLicenses()}
                </Card.Body>
            </Card>
            <Card bg="dark" text="white" className="mt-5">
                <Card.Header>
                    APP VERSIONS
                </Card.Header>
                <Card.Body>
                    <Button onClick={() => setUploadVersionModalOpen(() => true)}>Upload New App Version</Button>
                    <Row>
                        
                        <Table variant="dark" striped>
                            <thead>
                                <tr>
                                    <th>Version</th>
                                    <th>Notes</th>
                                    <th>URL</th>
                                    <th>Created On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appVersions.map((v, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{v.version}</td>
                                            <td>{v.notes}</td>
                                            <td style={{wordWrap: 'normal'}}>{v.url}</td>
                                            <td>{moment(v.created_on).format('MM/DD/YYYY hh:mma')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Row>
                </Card.Body>
            </Card>
            <Card bg='dark' text='white' className="mt-5">
                <Card.Header>
                    GLOBAL CONFIGURATIONS
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col>
                            <Button onClick={() => setScreen('global_configs')}>Global Configurations</Button>
                        </Col>

                        <Col>
                            <Button onClick={() => setScreen('field_visibility')}>Field Visibility (By Organization)</Button>
                        </Col>
                    </Row>

                    <div className="mt-5">
                        {section}
                    </div>

                </Card.Body>
            </Card>
            
            
           
        </Container>
    )
}