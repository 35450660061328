import {Modal, Form, Button} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import Papa from 'papaparse';

export default function RemoveSubAssemblyPartsModal({close, testingLocationId}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const partTypes = useSelector(s => s.subAssembly.subAssemblyPartTypes);
    const [formData, setFormData] = useState(() => {
        return {
            model: '',
            quantity: 0, 
        }
    });
    const [isSubmitting, setIsSubmitting] = useState(() => false);

    const loadInitialData = async () => {
        await api.attemptGetSubAssemblyPartTypes();
    }

    const convertToCSV = (results) => {
        const csvData = Papa.unparse(results);
        return csvData;
    }

    const downloadCSV = (results) => {
        const data = convertToCSV(results);
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
        link.download = `DELETED-${formData.model}-${formData.quantity}-serialnumbers.csv`;
        link.click();
    }

    const handleSave = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(() => true);
        if (isSubmitting) return;
        //CREATE THE SERIAL NUMBERS
        const serialNumbers = await api.attemptRemoveSubAssemblyPartsByQuantity({...formData, locationId: testingLocationId});
        if (!serialNumbers) return close();

        console.log(serialNumbers);
        let copySN = [...serialNumbers].map(s => {
            return {
                model: formData.model,
                qstring: s.serial_number,
                sequence: s.serial_number.split(',')[1]
            }
        })


        //DOWNLOAD THE CSV FILES
        downloadCSV(copySN);

        //CLOSE AFTER DOWNLOAD
        setTimeout(() => {
            close();
        }, 3000)

    }

    useEffect(() => {
        loadInitialData();
    }, []);

    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header closeButton className="spartan-modal text-center">
                <h5>Remove Excess Parts from Armory</h5>
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={handleSave}>
                    <Form.Group>
                        <Form.Label>
                            Part Type / SKU
                        </Form.Label>
                        <Form.Select value={formData.model} onChange={evt => setFormData({...formData, model: evt.target.value})} required>
                            <option></option>
                            {partTypes.map((p, i) => {
                                return (
                                    <option key={i} value={p.model}>
                                        {p.model} = {p.part_name}
                                    </option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mt-4">
                            <Form.Label>
                                Number of Parts
                            </Form.Label>
                            <Form.Control type="number" min={1} value={formData.quantity} onChange={evt => setFormData({...formData, quantity: evt.target.valueAsNumber})} required />

                      
                    </Form.Group>

                    

                    <div className="text-center">
                        <Button disabled={isSubmitting} type="submit">
                            Remove Devices
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )



}