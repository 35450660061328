import { Card, Container, Row, Col, Table, Form} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import SpartanApi from "../helpers/spartan-api";
import { QrReader } from "react-qr-reader";
import moment from 'moment';

export default function Checkout(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [scanned, setScanned] = useState(() => '');
    const [barcode, setBarcode] = useState(() => '')
    const checkedOutDevices = useSelector(s => s.device.checkedOutUserDevices);

    const loadUserData = async() => {
        await api.attemptGetCheckedOutDevicesByUser();
    }

    const decipher = (result, error) => {
        if (error) return;
        setScanned(result.text);
    }

    const barcodeSubmit = async (evt) => {
        evt.preventDefault();
        await api.attemptCheckOutDevice({serial_number: barcode.toUpperCase()});
        setBarcode(() => '');
        loadUserData();
    }

    const submitScanned = async (code) => {
        await api.attemptCheckOutDevice({serial_number: code.toUpperCase()});
        setScanned(() => '');
        loadUserData();
    }

    const buildCheckedOutItems = () => {
        if (!checkedOutDevices.length){
            return (
                <div className="text-center mt-5">
                    You have no checked out devices at this time.
                </div>
            )
        }
        return (
            <div className="text-center mt-5">
                <h5>Your Checked Out Devices</h5>
                <Table variant="dark" striped>
                    <thead>
                        <tr>
                            <th>Serial Number</th>
                            <th>Model/Type</th>
                            <th>Checked Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checkedOutDevices.map((c, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        {c.serial_number}
                                    </td>
                                    <td>
                                        {c.model}
                                    </td>
                                    <td>
                                        {moment(c.created_on).format("MM/DD/YYYY hh:mma")}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    useEffect(() => {
        loadUserData();
    }, [])

    useEffect(() => {
        if (!scanned || !scanned.length) return;
        submitScanned(scanned)
    }, [scanned])

    return (
        <Container>
            <Card bg="dark" color="white">
                <Card.Header>
                    <h4>Checkout Devices</h4>
                    <p>Please checkout devices that you intend to use for Demo and Development purposes</p>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <div style={{width: 300, height: 200, margin: '0px auto'}}>
                                <QrReader  constraints={{facingMode: 'environment'}} onResult={decipher} style={{ width: '100%' }}/>
                            </div>
                            <br/> <br/> <br/>
                            <p>You can also use the barcode scanner. Click the input box below and scan the QR Code</p>
                            <Form onSubmit={barcodeSubmit}>
                                <Form.Control value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                            </Form>
                        </Col>
                        <Col>
                        {buildCheckedOutItems()}
                        </Col>
                    </Row>
                        
                        
                    
                </Card.Body>
            </Card>
        </Container>
    )
}