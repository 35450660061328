export const sensorTypes = [
    {
        displayValue: 'Rear',
        eepromValue: 1
    },
    {
        displayValue: 'Front',
        eepromValue: 2
    },
    {
        displayValue: 'Right',
        eepromValue: 3
    },
    {
        displayValue: 'Left',
        eepromValue: 4
    },
    {
        displayValue: 'A-Pillar Right',
        eepromValue: 5
    },
    {
        displayValue: 'A-Pillar Left',
        eepromValue: 6
    },
]


export const triggerTypes = [
    {
        displayValue: 'Disabled',
        eepromValue: 0
    },
    {
        displayValue: 'Active High',
        eepromValue: 1
    },
    {
        displayValue: 'Active Low',
        eepromValue: 2
    },
]

export const getDownRangeOffsetOptions = () => {
    const arr = new Array(101).fill(0);
    return arr.map((n, i) => {
        const modifier = i * 0.05;
        const value = n + modifier;
        return {
            displayValue: `${value.toFixed(2)}m`,
            eepromValue: value
        }
    })
}


export const mountingHeightOptions = [
    {
        displayValue: 'Nominal 2ft - 4ft',
        eepromValue: {
            snrThreshold: 17,
            rcsThreshold: 20
        },
        value: '0'
    },
    {
        displayValue: '5ft - 6ft',
        eepromValue: {
            snrThreshold: 14,
            rcsThreshold: 35
        },
        value: '1'
    },
    {
        displayValue: '7ft - 8ft',
        eepromValue: {
            snrThreshold: 14,
            rcsThreshold: 35
        },
        value: '2'
    },
    {
        displayValue: '9ft - 10ft',
        eepromValue: {
            snrThreshold: 14,
            rcsThreshold: 40
        },
        value: '3'
    },
]

export const terrainOptions = [
    {
        displayValue:  'On Road',
        eepromValue: '0'
    },
    {
        displayValue: 'Off Road',
        eepromValue: '1'
    }
]


export const audioSchemeOptions = [
    {
        displayValue: 'Side Sensor Audio',
        eepromValue:'0'
    },
    {
        displayValue: 'Dynamic Audio',
        eepromValue:'1'
    },
]