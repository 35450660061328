import {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api';
import { QrReader } from 'react-qr-reader';
import { Container, Row, Col, Card, Form} from 'react-bootstrap';
import CreateReturnModal from '../admin/modals/CreateReturnModal';

export default function Checkin(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [scanned, setScanned] = useState(() => undefined);
    const [scannedSerialNumbers, setScannedSerialNumbers] = useState(() => []);
    const [modalOpen, setModalOpen] = useState(() => false);
    const [scannedDevice, setScannedDevice] = useState(() => null);
    const [barcode, setBarcode] = useState(() => '');
    
    const decipher = (result, error) => {
        if (error) return;
        setScanned(result.text);
    }

    const barcodeSubmit = (evt) => {
        evt.preventDefault();
        attemptCheckinItem(barcode);
    }

    const closeModal = async() => {
        setScannedDevice(null);
        setModalOpen(false);
    }

    const attemptCheckinItem = async(serial_number) => {
        const device  = await api.attemptGetDeviceBySerialNumber(serial_number);
        if (device && (device.shipping_status == "Shipped" || device.shipping_status == "Delivered")){
          let result =  window.confirm("This Device has already been shipped, would you like to add it back into inventory?");
          if (result) {
            //OPEN THE RETURN MODAL
            setScannedDevice(device);
            setModalOpen(true);
          }
        }
        const success = await api.attemptCheckinPartOrDevice(serial_number);
        if (!success) return;
        
        setBarcode(() => '');
        setScannedSerialNumbers(prev => {
            return [serial_number, ...prev];
        })

        
        
    }

    useEffect(() => {
        if (!scanned) return;
        attemptCheckinItem(scanned);
    }, [scanned])

    let modal = null;

    if (modalOpen){
       modal =  <CreateReturnModal device={scannedDevice} close={closeModal} />
    }

    return (
        <Container>
            {modal}
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card bg="dark" text="white">
                        <Card.Header>
                            <h5>Scan Device or Part</h5>
                        </Card.Header>
                        <Card.Body> 
                        <div style={{width: 300, height: 200, margin: '0px auto'}}>
                            <QrReader  constraints={{facingMode: 'environment'}} onResult={decipher} style={{ width: '100%' }}/>
                        </div>
                        <br/> <br/> <br/>
                        <h5>You can also use the barcode scanner. Click the input box below and scan the QR Code</h5>
                        <Form onSubmit={barcodeSubmit}>
                            <Form.Control value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                        </Form>
                        
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Card bg="dark" text="white">
                        <Card.Header>
                            <h5>Checked In Items</h5>
                        </Card.Header>
                        <Card.Body>
                            <ul>
                                {
                                    scannedSerialNumbers.map((s, i) => {
                                        return <li key={i}>{s}</li>
                                    })
                                }
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
    


}