import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    subAssemblyTypes: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_SUB_ASSEMBLY_TYPES: {
                return action.payload;
            }
            default: return state;
        }
    },
    subAssemblyPartTypes: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_SUB_ASSEMBLY_PART_TYPES: {
                return action.payload;
            }
            default: return state;
        }
    }
})