import axios from 'axios';
import * as KEYS from '../constants/keys';
import * as ACTIONS from '../constants/actions';
import AlertHelper from './alert-helper';
axios.defaults.baseURL = "https://internal-spartan-tracking-server.westus.cloudapp.azure.com" //'http://localhost:5000' // process.env.REACT_APP_API_URL || "https://internal-spartan-tracking-server.westus.cloudapp.azure.com"

export default class SpartanApi {
    constructor({dispatch, navigate}){
        this.dispatch = dispatch;
        this.navigate = navigate;
        this.AlertHelper = new AlertHelper({dispatch});
        this.getToken();
        this.attemptCheckOnline();
    } 

    setToken = (token) => {
        window.localStorage.setItem(KEYS.USER_TOKEN, token);
        axios.defaults.headers.common.Authorization = token;
    }

    getToken = () => {
        let token = window.localStorage.getItem(KEYS.USER_TOKEN);

        if (!token && window.location.pathname !== "/"){
           this.navigate('/')
            return;
        }
       
        this.setToken(token);
        return token;
    }

    setUserData = (user) => {
        this.dispatch({type: ACTIONS.SET_USER_DATA, payload: user});
        window.localStorage.setItem(KEYS.USER_DATA, JSON.stringify(user));
    }

    attemptLogin = async (requestData) => {
        try {
            const {data} = await axios.post('/user/login', requestData);
            this.setToken(data.token)
            //SET USER DATA
            this.setUserData(data.user);
            return data.user;
        } catch (err) {
            this.AlertHelper.addAlert({
                title: 'UNABLE TO LOGIN',
                body: 'Please check your email address and password, then try again.'
            })
            console.log(err);
            return null;
        }
    }

    attemptCheckOnline = async() => {
        if (!this.getToken()) return null;
        try {
            const {data} = await axios.get('/user/check-online');
            this.setUserData(data);
            return data;
        } catch (err) {
            
            console.log(err);
            window.location.href= "/#"
            return null;
        }
    }

    attemptGetTestSites = async() => {
        try {
            const {data} = await axios.get('/test-site/get-sites');
            console.log("SITE DATA: ", data);
            this.dispatch({type: ACTIONS.SET_TEST_SITES, payload: data.sites});
            return data.sites;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO GET TEST SITES',
                body: 'Please refresh the page.'
            })
            return null;
        }
    }

    attemptCreateTestSite = async(requestData) => {
        try {
            await axios.post('/test-site/create-site', requestData);
            return true;
        } catch(err) {
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CREATE TEST SITE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptAddSiteUser = async(requestData) => {
        try {
            await axios.post('/test-site/add-site-user', requestData);
            return true;
        } catch (err) {
            this.AlertHelper.addAlert({
                title: 'UNABLE TO ADD TEST SITE USER',
                body: 'Please check your request and try again.'
            })
            console.log(err);
            return null;
        }
    }

    attemptGetSitesByUser = async() => {
        try {
           const {data} =  await axios.get('/test-site/get-sites-by-user');
           this.dispatch({type: ACTIONS.SET_USER_SITES, payload: data.sites});
           this.dispatch({type: ACTIONS.SET_USER_LOCATION, payload: data.sites[0]}); //TODO: ACCOUNT FOR USERS WITH MULTIPLE SITES
           return data.sites;
        } catch (err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO GET SITES BY USER',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetUsersBySite = async(requestData) => {
        try {
            const {data} = await axios.get("/test-site/get-users-by-site", {params: requestData});
            this.dispatch({type: ACTIONS.SET_SITE_USERS, payload: {...requestData, users: data.users}});
            return data.users;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptCreateDevice = async (requestData) => {
        try {
            const {data} = await axios.post('/device/create-device', requestData);
            return data.device;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO ADD THE DEVICE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptUpdateDevice = async (requestData) => {
        try {
            await axios.post('/device/update-device', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO UPDATE THE DEVICE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptCreateDeviceType = async (requestData) => {
        try {
            await axios.post('/device/create-device-type', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CREATE DEVICE TYPE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptUpdateDeviceType = async (requestData) => {
        try {
            await axios.post('/device/update-device-type', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO UPDATE DEVICE TYPE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptCreateDeviceTestCase = async (requestData) => {
        try {
            await axios.post('/device/create-device-test-case', requestData);
            return true;
        } catch(err) {
            console.log(err)
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CREATE DEVICE TEST CASE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptSaveDeviceWhereabouts = async (requestData) => {
        try {
            await axios.post('/device/save-device-whereabouts', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO LOG DEVICE WHEREABOUTS',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptSaveDeviceConfigs = async (requestData) => {
        try {
            await axios.post('/device/save-device-configs', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO SAVE THE CONFIGS AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptSaveDeviceTestResults = async (requestData) => {
        try {
            await axios.post('/device/save-device-test-results', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO SAVE THE DEVICE TEST RESULTS',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptSearchDeviceBySerialNumber = async (term) => {
        try {
            const {data} = await axios.get('/device/search-devices', {params: {term}})
            const {devices} = data;
            this.dispatch({type: ACTIONS.SET_DEVICES, payload: devices});
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO COMPLETE THE SEARCH AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetDevices = async(requestData) => {
        try {
            const {data} = await axios.get('/device/get-devices', {params: requestData});
            this.dispatch({type: ACTIONS.SET_DEVICES, payload: data.devices});
            this.dispatch({type: ACTIONS.SET_DEVICES_COUNT, payload: data.device_count});
            return data.devices;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO GET THE DEVICES AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetDevicesByDeviceType = async({deviceTypeId, limit = 100, offset = 0, orderBys= undefined}) => {
        try {
            const {data} = await axios.get('/device/get-devices-by-device-type-id', {params: {device_type_id: deviceTypeId, limit, offset, orderBys}})
            this.dispatch({type: ACTIONS.SET_DEVICES, payload: data.devices});
            return data.devices;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetDevicesBySite = async(testSiteId) => {
        try {
            const {data} = await axios.get('/device/get-devices-by-site', {params: {current_site: testSiteId}});
            this.dispatch({type: ACTIONS.SET_SITE_DEVICES, payload: {test_site_id: testSiteId, devices: data.devices}});
            return data.devices;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetDeviceTypes = async() => {
        try {
            const {data} = await axios.get('/device/get-device-types');
            this.dispatch({type: ACTIONS.SET_DEVICE_TYPES, payload: data.device_types});
            return data.device_types;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetDeviceConfigs = async (deviceTypeId) => {
        try {
            const {data} = await axios.get('/device/get-device-configs', {params: {device_type_id: deviceTypeId}});
            this.dispatch({type: ACTIONS.SET_DEVICE_CONFIGS, payload: {device_type_id: deviceTypeId, configs: data.configs}});
            return data.configs;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetDeviceTestCases = async(deviceTypeId) => {
        try {
            const {data} = await axios.get('/device/get-device-test-cases', {params: {device_type_id: deviceTypeId}});
            this.dispatch({type: ACTIONS.SET_TEST_CASES, payload: data.device_test_cases});
            return data.device_test_cases;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetDeviceTestCasesBySite = async(device_type_id, test_site_id) => {
        try {
            const {data} = await axios.get('/device/get-device-test-cases-by-site', {params: {device_type_id, test_site_id}});
            const payload = {
                device_type_id,
                test_site_id,
                test_cases: data.device_test_cases
            }
            this.dispatch({type: ACTIONS.SET_SITE_TEST_CASES, payload})
        } catch(err) {
            console.log(err);
            return null;
        }
    }   

    attemptGetDeviceWhereabouts = async(deviceId) => {
        try {
            const {data} = await axios.get('/device/get-device-whereabouts', {params: {device_id: deviceId}});
            this.dispatch({type: ACTIONS.SET_DEVICE_WHEREABOUTS, payload: {device_id: deviceId, whereabouts: data.whereabouts}});
            return data.whereabouts;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetDeviceTestResults = async(deviceId) => {
        try {
            const {data} = await axios.get('/device/get-device-test-results', {params: {device_id: deviceId}});
            this.dispatch({type: ACTIONS.SET_TEST_RESULTS, payload: {device_id: deviceId, results: data.results}});
            return data. results;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetToken = async() => {
        try {
            const {data} = await axios.get('/token/get-token');
            this.dispatch({type: ACTIONS.SET_SAS_TOKEN, payload: data.sasToken});
            return data.sasToken;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptSaveDeviceCalibrationFile = async(requestData) => {
        try {
            await axios.post('/device/save-device-calibration-file', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO SAVE THE CALIBRATION FILE AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetDeviceCalibrationFile = async(device_id) => {
        try {
            const {data} = await axios.get('/device/get-device-calibration-files', {params: {device_id}});
            this.dispatch({type: ACTIONS.SET_DEVICE_CALIBRATION_FILES, payload: {device_id, calibrations: data.calibrations}});
            return data.calibrations;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptUpdateDeviceTestCase = async (requestData) => {
        try {
            await axios.post('/device/update-device-test-case', requestData);
            return true;
        } catch(err) {
            console.log(err)
            return null;
        }
    }
    
    attemptGetDeviceBySerialNumber = async (serial_number) => {
        try {
            const {data} = await axios.get('/device/get-device-by-serial-number', {params:{ serial_number}})
            return data.device;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptCreateDeviceTypeFirmware = async (requestData) => {
        try {
            await axios.post('/device/create-device-type-firmware', requestData)
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CREATE THE DEVICE FIRMWARE AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptUpdateDeviceTypeFirmware = async (requestData) => {
        try {
            await axios.post('/device/update-device-type-firmware', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO UPDATE THE DEVICE TYPE FIRMWARE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetDeviceTypeFirmwareByDeviceTypeId = async(device_type_id) => {
        try {
           const {data} =  await axios.get('/device/get-device-type-firmware-by-device-type-id', {params: {device_type_id}})
           const {firmware} = data;
           this.dispatch({type: ACTIONS.SET_DEVICE_TYPE_FIRMWARE_OPTIONS, payload: {firmware, device_type_id}})
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    

    attemptCreateShippingBundle = async (requestData) =>  {
        try {
            const {data} = await axios.post('/shipping/create-shipping-bundle', requestData);
            return data.bundle;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CREATE A SHIPPING BUNDLE AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptSearchShippingBundles = async (requestData) => {
        try {
            const {data} = await axios.post('/shipping/search-shipping-bundles', requestData);
            const {bundles} = data;
            this.dispatch({type: ACTIONS.SET_BUNDLES, payload: bundles});
            return bundles;
        } catch(err){
            console.log(err);
            return null;
        }
    }

    attemptUpdateShippingBundle = async(requestData) =>  {
        try {
            await axios.post('/shipping/update-shipping-bundle', requestData);
            return true;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO UPDATE THE SHIPPING BUNDLE AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptAddItemToShippingBundle = async(requestData) => {
        try {
            await axios.post('/shipping/add-item-to-shipping-bundle', requestData);
            return true;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    attemptRemoveItemFromShippingBundle = async(requestData) =>  {
        try {
            await axios.post('/shipping/remove-item-from-shipping-bundle', requestData)
            return true;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetShippingBundleById = async(shipping_bundle_id) =>  {
        try {
           const {data} =  await axios.get('/shipping/get-shipping-bundle-by-id', {params: {shipping_bundle_id}});
           const {bundle} = data;
           this.dispatch({type: ACTIONS.SET_BUNDLE_SPECIFIC_DATA, payload: {bundle_id: shipping_bundle_id, bundle_data: bundle}})
           return bundle;
        } catch(err) {
            console.log(err);
            return null;
        }
    }

    attemptGetShippingBundles = async(params) => {
        try {
            const {data} = await axios.get('/shipping/get-shipping-bundles', {params});
            const {bundles} = data;
            this.dispatch({type: ACTIONS.SET_BUNDLES, payload: bundles});
        } catch(err) {
            console.log(err);
            return null
        }
    }

    attemptSearchShippingBundlesBySerialNumber = async (serial_number) => {
        try {
            const {data} = await axios.get('/shipping/search-shipping-bundles-by-serial-number', {params: {serial_number}})
            const {shipping_bundles} = data;
            this.dispatch({type: ACTIONS.SET_BUNDLES, payload: shipping_bundles})
        } catch(err) {
            console.log("Error: ", err);
            return null;
        }
    }

    attemptValidateItemForShipping = async(device_id) =>  {
        try {
            const {data} = await axios.post('/shipping/validate-item-for-shipping', {device_id})
            return data;
        } catch(err) {
            console.log(err);
            this.AlertHelper.addAlert({
                title: 'UNABLE VALIDATE THE ITEM AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptChangePassword = async (request) => {
        try {
            await axios.post('/user/change-initial-password', request);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptForgotPassword = async (request) => {
        try {
            await axios.post('/user/forgot-password', request);
            return true;
        } catch(err) {
            console.log("Error: ", err);
            return null;
        }
    }

    attemptResetPassword = async (request) => {
        try {
            await axios.post('/user/reset-password', request);
            return true;
        } catch(err) {
            console.log("Error: ", err);
            return null;
        }
    }

    attemptGetAccessories = async () => {
        try {
            const {data} = await axios.get('/shipping/get-accessory-items');
            this.dispatch({type: ACTIONS.SET_ACCESSORIES, payload: data.accessory_items});
            return true;
        } catch(err) {
            console.log("Error: ", err);
            return null;
        }
    }

    attemptCreateAccessoryItem = async(request) => {
        try {
            await axios.post('/shipping/create-accessory-item', request);
            return true;
        } catch(err) {
            console.log("Error: ", err);
            return null;
        }
    }

    attemptGetDevicesUnderWarranty = async() => {
        try {
            const {data} = await axios.get('/business/get-devices-under-warranty');
            this.dispatch({type: ACTIONS.SET_DEVICES_UNDER_WARRANTY, payload: data.devices});
            return true;
            
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }
    

    attemptGetDashboardDevicesAtSiteStats = async(site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-devices-at-site', {params: {site_id}})
            this.dispatch({type: ACTIONS.SET_DASHBOARD_DEVICES_AT_SITE_STATS, payload: data.stats});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDashboardDeviceTypePassToFailRatio = async(site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-pass-fail-ratio-by-device-type', {params: {site_id}});
            this.dispatch({type: ACTIONS.SET_DASHBOARD_DEVICE_TYPE_PASS_TO_FAIL, payload: data.stats});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDashboardShippingStatsByDeviceType = async (site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-shipping-stats-by-device-type', {params: {site_id}});
            this.dispatch({type: ACTIONS.SET_DASHBOARD_SHIPPING_STATS_BY_DEVICE_TYPE, payload: data.stats})
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDashboardDevicesInTesting = async(site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-devices-in-testing', {params: {site_id}})
            this.dispatch({type: ACTIONS.SET_DASHBOARD_DEVICES_IN_TESTING, payload: data.stats});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

   
    attemptGetDashboardDevicesPassedTesting = async(site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-devices-passed-testing', {params: {site_id}})
            this.dispatch({type: ACTIONS.SET_DASHBOARD_DEVICES_PASSED_TESTING, payload: data.stats});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDashboardPassFailRatio = async (site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-pass-fail-ratio', {params: {site_id}})
            this.dispatch({type: ACTIONS.SET_DASHBOARD_DEVICE_PASS_FAIL_RATIO, payload: data.stats});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDashboardDevicesShipped = async (site_id) => {
        try {
            const {data} = await axios.get('/dashboard/get-devices-shipped', {params: {site_id}})
            this.dispatch({type: ACTIONS.SET_DASHBOARD_DEVICES_SHIPPED, payload: data.stats});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            
            return null;
        }
    }

   

    attemptCreateSubAssemblyType = async (request) => {
        try {
            await axios.post('/sub-assembly/create-sub-assembly-type', request);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CREATE SUB ASSEMBLY TYPE',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptCreateSubAssemblyPartType = async (request) => {
        try {
            await axios.post('/sub-assembly/create-sub-assembly-part-type', request);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to Create Sub Assembly Part Type',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetSubAssemblyTypes = async () => {
        try {
            const {data} = await axios.get('/sub-assembly/get-sub-assembly-types');
            this.dispatch({type: ACTIONS.SET_SUB_ASSEMBLY_TYPES, payload: data.sub_assembly_types});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get Sub Assembly Part Types',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }
    

    attemptGetSubAssemblyPartTypes = async () => {
        try {
            const {data} = await axios.get('/sub-assembly/get-sub-assembly-part-types');
            this.dispatch({type: ACTIONS.SET_SUB_ASSEMBLY_PART_TYPES, payload: data.part_types});
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get the Sub Assebmly Part Types',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetAssemblyPartBySerialNumber = async(serial_number) => {
        try {
            const {data} = await axios.get('/sub-assembly/get-sub-assembly-part-by-serial-number', {params: {serial_number}});
            return data.part;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get Sub Assembly Part By Serial Number',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptValidateSubAssemblyPartForShipping = async (serial_number) => {
        try {
            const {data} = await axios.post('/shipping/validate-sub-assembly-part-for-shipping', {serial_number});
            return data;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to validate sub assembly at this time.',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetAvailableDeviceCountBySite = async(site_id) => {
        try {
            const {data} = await axios.get('/inventory/get-available-device-count-by-site', {params: {site_id}});
            this.dispatch({type: ACTIONS.SET_INVENTORY_DEVICE_COUNT, payload: data.device_count})
            return true;
        } catch(err) {
            console.log("ERRROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get available device counts at this time',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetAvailableSubAssemblies = async(site_id) => {
        try {
            const {data} = await axios.get('/inventory/get-available-sub-assemblies', {params: {site_id}});
            this.dispatch({type: ACTIONS.SET_INVENTORY_SUB_ASSEMBLY_COUNT, payload: data.assembly_count})
            return true;
        } catch(err) {
            console.log("ERRROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get available sub assemblies at this time.',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetAvailableSubAssemblyPartCounts = async(site_id) => {
        try {
            const {data} = await axios.get('/inventory/get-available-sub-assembly-part-counts', {params: {site_id}});
            this.dispatch({type: ACTIONS.SET_INVENTORY_SUB_ASSEMBLY_PART_COUNT, payload: data.part_count})
            return true;
        } catch(err) {
            console.log("ERRROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get Available Sub Assembly Part Counts',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptCheckinPartOrDevice = async(serial_number) => {
        try {
            await axios.get('/checkin/checkin-part-or-device', {params: {serial_number}})
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO CHECKIN PART OR DEVICE AT THIS TIME',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGetDeviceOrPartBySerialNumber = async(serial_number) => {
        try {
           const {data} = await axios.get('/shipping/get-device-or-part-by-serial-number', {params: {serial_number}});
           return data;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'INVALID SERIAL NUMBER',
                body: 'Please check your request and try again.'
            })
            return null;
        }
    }

    attemptGenerateTestingReport = async(requestObject) => {
        try {
            const {data} = await axios.get('/report/generate-testing-report', {params: requestObject});
            return data.results;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'ERROR GENERATING REPORT',
                body: 'UNABLE TO GENERATE REPORT AT THIS TIME'
            })
            return null;
        }
    }
    attemptGenerateInventoryReport = async(requestObject) => {
        try {
            const {data} = await axios.get('/report/inventory-report', {params: requestObject});
            return data.reports;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'ERROR GENERATING REPORT',
                body: 'UNABLE TO GENERATE REPORT AT THIS TIME'
            })
            return null;
        }
    }

    attemptCreateReturn = async (requestObject) => {
        try {
            await axios.post('/shipping/add-device-back-to-inventory', requestObject);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'ERROR CREATING THE RETURN',
                body: 'UNABLE TO CREATE THE RETURN AT THIS TIME.'
            })
            return null;
        }
    }

    attemptCreateGlobalExternalToolConfigurations = async (requestObj) => {
        try {
            await axios.post('/external-config-tool/create-global-external-tool-configurations', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to Create External Configuration at this time.',
                body: 'Please contact support and/or try again later'
            })
            return null;
        }
    }

    attemptUpdateGlobalExternalToolConfigurations = async (requestObj) => {
        try {
            await axios.post('/external-config-tool/update-global-external-tool-configurations', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to Update External Configuration at this time.',
                body: 'Please contact support and/or try again later'
            })
            return null;
        }
    }
    

    attemptGetGlobalExternalToolConfigurations = async () => {
        try {
           const {data} = await axios.get('/external-config-tool/get-global-external-tool-configurations');
           this.dispatch({type: ACTIONS.SET_EXTERNAL_TOOL_GLOBAL_CONFIGURATIONS, payload: data.configurations});
           return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'UNABLE TO GET GLOBAL CONFIGURATIONS',
                body: 'Please contact support and/or try again later'
            })
        }
    }

    attemptGetExternalToolCompaniesAndFields = async () => {
        try {
            const {data} = await axios.get('/external-config-tool/get-organizations-and-fields');
            this.dispatch({type: ACTIONS.SET_EXTERNAL_TOOL_ORGANIZATIONS_AND_FIELDS, payload: data.organizations});
            return true;
        } catch (err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: "UNABLE TO GET EXTERNAL TOOL COMPANIES AND FIELDS",
                body: 'Please contact support and / or try again later'
            })
            return null;
        }
    }

    attemptGetExternalToolFieldOptions = async() => {
        try {
            const {data} = await axios.get('/external-config-tool/get-field-options');
            this.dispatch({type: ACTIONS.SET_EXTERNAL_TOOL_FIELD_OPTIONS, payload: data.fields});
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to get external tool field options at this time',
                body: 'Please contact support and / or try again later'
            })
            return null;
        }
    }

    attemptSetOrganizationExternalToolFieldAvailability = async(requestObj) => {
        try {
            await axios.post('/external-config-tool/set-organization-field-availability', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: 'Unable to set external tool field availabilty at this time',
                body: 'Please contact support and / or try again later'
            })
            return null;
        }
    }

    attemptUpdateOrganizationExternalToolFieldAvailability = async (requestObj) => {
        try {
            await axios.post('/external-config-tool/update-organization-field-availability', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptCreateDeviceTypeBaseConfigurations = async (requestObj) => {
        try {
            await axios.post('/device/create-device-type-base-configuration', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptUpdateDeviceTypeBaseConfigurations = async (requestObj) => {
        try {
            await axios.post('/device/update-device-type-base-configuration', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDeviceTypeBaseConfigurations = async () => {
        try {
            const {data} = await axios.get('/device/get-device-type-base-configurations');
            this.dispatch({type: ACTIONS.SET_DEVICE_TYPE_BASE_CONFIGURATIONS, payload: data.device_type_base_configurations});
            return data.device_type_base_configurations;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDeviceTypeBaseConfigurationsByDeviceTypeId = async (params) => {
        try {
            const {data} = await axios.get('/device/get-device-type-base-configurations-by-device-type-id', {params});
            this.dispatch({type: ACTIONS.SET_DEVICE_TYPE_BASE_CONFIGURATIONS, payload: data.device_type_base_configurations});
            return data.device_type_base_configurations;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetCustomerOrganizations = async() => {
        try {
            const {data} = await axios.get('/customer/internal-get-customer-organizations');
            this.dispatch({type: ACTIONS.SET_CUSTOMER_ORGANIZATIONS, payload: data.organizations});
            return data.organizations;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptCreateCustomerOrganization = async (requestObj) => {
        try {
            await axios.post('/customer/internal-create-customer-organization', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptUpdateCustomerOrganization = async (requestObj) => {
        try {
            await axios.post('/customer/internal-update-customer-organization', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptAddCustomerToOrganization = async (requestObj) => {
        try {
            await axios.post('/customer/internal-add-customer-to-organization', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptFirmwareReorder = async (requestObj) => {
        try {
            await axios.post('/device/reorder-device-type-firmware', requestObj)
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetAllSalesOrders = async() => {
        try {
           const {data} =  await axios.get('/netsuite/get-all-sales-orders')
           this.dispatch({type: ACTIONS.SET_SALES_ORDERS, payload: data.results});
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetAllPurchaseOrders = async() => {
        try {
           const {data} =  await axios.get('/netsuite/get-all-purchase-orders')
           this.dispatch({type: ACTIONS.SET_PURCHASE_ORDERS, payload: data.results});
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptFulfillSalesOrder = async(payload) => {
        try {
            await axios.post('/netsuite/fulfill-sales-order', payload);
            return  true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptFulfillPurchaseOrder = async(payload) => {
        try {
            await axios.post('/netsuite/fulfill-purchase-order', payload);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptCheckOutDevice = async(payload) => {
        try {
            await axios.post('/device/check-out', payload);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptCheckInDevice = async(payload) => {
        try {
            await axios.post('/device/check-in', payload);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetCheckedOutDevices = async() => {
        try {
            const {data} = await axios.get('/device/get-checked-out-devices');
            this.dispatch({type: ACTIONS.SET_CHECKED_OUT_DEVICES, payload: data.checked_out_devices});
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetCheckedOutDevicesByUser = async() => {
        try {
            const {data} = await axios.get('/device/get-checked-out-devices-by-users');
            this.dispatch({type: ACTIONS.SET_CHECKED_OUT_USER_DEVICES, payload: data.checked_out_devices});
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptReportMissing = async (payload) => {
        try {
            await axios.post('/device/report-device-lost', payload);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptReportFound = async (payload) => {
        try {
            await axios.post('/device/report-device-found', payload)
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetMissingDevices = async () => {
        try {
            const {data} = await axios.get('/device/get-list-of-lost-devices')
            this.dispatch({type: ACTIONS.SET_LOST_DEVICES, payload: data.lost_devices});
            return data.lost_devices;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptCreateSubAssemblyPartSerialNumbers = async (payload) => {
        try {
            const {data} = await axios.post('/sub-assembly/create-sub-assembly-serial-numbers', payload);
            return data.serial_numbers;
        } catch(err) {
            console.log("ERROR: ", err)
            this.AlertHelper.addAlert({
                title: "UNABLE TO CREATE SERIAL NUMBERS",
                body: 'Please contact support and / or try again later'
            });
            return null;
        }
    }

    attemptRemoveSubAssemblyPartsByQuantity = async (payload) => {
        try {
            const {data} = await axios.post('/sub-assembly/remove-sub-assembly-parts-by-quantity', payload);
            return data.deleted_parts;
        } catch(err) {
            console.log("ERROR: ", err);
            this.AlertHelper.addAlert({
                title: "UNABLE TO REMOVE PARTS",
                body: "Please contact support and / or try again later"
            });
            return null;
        }
    }

    attemptGetUnfulfilledPurchaseOrders = async () => {
        try {
            const {data} = await axios.get('/netsuite/get-unfulfilled-purchase-orders');
            this.dispatch({type: ACTIONS.SET_UNFULFILLED_PURCHASE_ORDERS, payload: data.purchaseOrders})
            return data.purchaseOrders;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetExternalConfigToolPremiumProfiles = async () => {
        try {
            const {data} = await axios.get('/external-licenses/get-profiles');
            this.dispatch({type: ACTIONS.SET_EXTERNAL_PREMIUM_PROFILES, payload: data.profiles})
            return data.profiles;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptCreateExternalConfigToolPremiumProfile = async (requestBody) => {
        try {
            await axios.post('/external-licenses/create-profile', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptUpdateExternalConfigToolPremiumProfile = async (requestBody) => {
        try {
            await axios.post('/external-licenses/update-profile', requestBody);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetDeviceTraceHistory = async (params) => {
        try {
            const {data} = await axios.get('/device/get-device-traces-by-device-id', {params});
            return data.traces;
        } catch(err) {
            console.log("ERROR: ", err);
            return [];
        }
    }

    attemptGetCustomerSettingsChanges = async (params) => {
        try {
            const {data} = await axios.get('/device/get-device-customer-settings-by-device-id', {params})
            return data.settings;
        } catch(err) {
            console.log("ERROR: ", err);
            return [];
        }
    }

    attemptGetCustomerFirmwareChanges = async (params) => {
        try {
            const {data} = await axios.get('/device/get-device-customer-firmware-changes-by-device-id', {params})
            return data.changes;
        } catch(err) {
            console.log("ERROR: ", err);
            return [];
        }
    }

    attemptAddOrganizationLicenses = async (requestObj) => {
        try {
            await axios.post('/external-licenses/add-licenses-via-armory', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptGetOrganizationLicensesById = async(params) => {
        try {
            const {data} = await axios.get('/external-licenses/get-licenses-by-organization-id', {params});
            console.log("DATA: ", data)
            return data.licenses;
        } catch(err) {
            console.log("ERROR: ", err);
            return;
        }
    }

    attemptCreateOrganizationDemo = async(requestObj) => {
        try {
            const {data} = await axios.post('/demo/create-organization-demo', requestObj);
            return data.organization_demo;
        } catch(err) {
            console.log("ERROR: ", err);
            return null;
        }
    }

    attemptAddDeviceToOrganizationDemo = async(requestObj) => {
        try {
            const {data} = await axios.post('/demo/add-device-to-customer-organization-demo', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return false;
        }
    }

    attemptAddBuildToOrganizationDemo = async (requestObj) => {
        try {
            const {data} = await axios.post('/demo/add-build-to-customer-organization-demo', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return false;
        }
    }

    attemptExpireOrganizationDemo = async (requestObj) => {
        try {
            const {data} = await axios.post('/demo/add-build-to-customer-organization-demo', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return false;
        }
    }

    attemptGetCustomerDemosByOrganizationId = async (params) => {
        try {
            const {data} = await axios.get('/demo/get-customer-organization-demo-by-organization-id', {params});
            return data.demos;
        } catch(err) {
            console.log("ERROR: ", err);
            return false
        }
    }

    attemptGetExternalConfigToolVersions = async () => {
        try {
            const {data} = await axios.get('/external-config-tool/get-all-versions')
            return data.versions;
        } catch(err) {
            console.log("ERROR: ", err);
            return false;
        }
    }

    attemptUploadConfigurationVersion = async (requestObj) => {
        try {
            await axios.post('/external-config-tool/upload-configuration-exe', requestObj);
            return true;
        } catch(err) {
            console.log("ERROR: ", err);
            return false;
        }
    }


    
}