export const SET_USER_DATA  = "SET_USER_DATA";
export const SET_TEST_SITES = "SET_TEST_SITES";
export const SET_USER_SITES = "SET_USER_SITES";
export const SET_SITE_USERS = "SET_SITE_USERS";
export const SET_DEVICES    = "SET_DEVICES";
export const SET_DEVICES_COUNT = "SET_DEVICES_COUNT";
export const SET_SITE_DEVICES = "SET_SITE_DEVICES";
export const SET_DEVICE_TYPES = "SET_DEVICE_TYPES";
export const SET_TEST_CASES  = "SET_TEST_CASES";
export const SET_SITE_TEST_CASES = "SET_SITE_TEST_CASES";
export const SET_DEVICE_WHEREABOUTS = "SET_DEVICE_WHEREABOUTS";
export const SET_TEST_RESULTS = "SET_TEST_RESULTS";
export const SET_DEVICE_CONFIGS = "SET_DEVICE_CONFIGS";
export const SET_SAS_TOKEN = "SET_SAS_TOKEN";
export const SET_DEVICE_CALIBRATION_FILES = "SET_DEVICE_CALIBRATION_FILES";
export const SET_DEVICE_TYPE_FIRMWARE_OPTIONS = "SET_DEVICE_TYPE_FIRMWARE_OPTIONS";
export const SET_BUNDLE_SPECIFIC_DATA = "SET_BUNDLE_SPECIFIC_DATA";
export const SET_BUNDLES = "SET_BUNDLES"
export const ADD_ALERT = "ADD_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_ACCESSORIES = "SET_ACCESSORIES";
export const SET_DEVICES_UNDER_WARRANTY = "SET_DEVICES_UNDER_WARRANTY";
export const SET_DASHBOARD_DEVICES_AT_SITE_STATS = "SET_DASHBOARD_DEVICES_AT_SITE_STATS";
export const SET_DASHBOARD_DEVICES_IN_TESTING = "SET_DASHBOARD_DEVICES_IN_TESTING";
export const SET_DASHBOARD_DEVICES_PASSED_TESTING = "SET_DASHBOARD_DEVICES_PASSED_TESTING";
export const SET_DASHBOARD_DEVICE_PASS_FAIL_RATIO = "SET_DASHBOARD_DEVICE_PASS_FAIL_RATIO";
export const SET_DASHBOARD_DEVICES_SHIPPED = "SET_DASHBOARD_DEVICES_SHIPPED";
export const SET_SUB_ASSEMBLY_TYPES = "SET_SUB_ASSEMBLY_TYPES";
export const SET_SUB_ASSEMBLY_PART_TYPES = "SET_SUB_ASSEMBLY_PART_TYPES";
export const SET_INVENTORY_SUB_ASSEMBLY_COUNT = "SET_INVENTORY_SUB_ASSEMBLY_COUNT";
export const SET_INVENTORY_SUB_ASSEMBLY_PART_COUNT = "SET_INVENTORY_SUB_ASSEMBLY_PART_COUNT";
export const SET_INVENTORY_DEVICE_COUNT = "SET_INVENTORY_DEVICE_COUNT";
export const SET_DASHBOARD_DEVICE_TYPE_PASS_TO_FAIL = "SET_DASHBOARD_DEVICE_TYPE_PASS_TO_FAIL";
export const SET_DASHBOARD_SHIPPING_STATS_BY_DEVICE_TYPE = "SET_DASHBOARD_SHIPPING_STATS_BY_DEVICE_TYPE";
export const SET_EXTERNAL_TOOL_GLOBAL_CONFIGURATIONS = "SET_EXTERNAL_TOOL_GLOBAL_CONFIGURATIONS";
export const SET_EXTERNAL_TOOL_ORGANIZATIONS_AND_FIELDS = "SET_EXTERNAL_TOOL_ORGANIZATIONS_AND_FIELDS";
export const SET_EXTERNAL_TOOL_FIELD_OPTIONS = "SET_EXTERNAL_TOOL_FIELD_OPTIONS";
export const SET_DEVICE_TYPE_BASE_CONFIGURATIONS = "SET_DEVICE_TYPE_BASE_CONFIGURATIONS";
export const SET_CUSTOMER_ORGANIZATIONS = "SET_CUSTOMER_ORGANIZATIONS";
export const SET_SALES_ORDERS = "SET_SALES_ORDERS";
export const SET_PURCHASE_ORDERS = "SET_PURCHASE_ORDERS";
export const SET_UNFULFILLED_PURCHASE_ORDERS = "SET_UNFULFILLED_PURCHASE_ORDERS";
export const SET_CHECKED_OUT_DEVICES = "SET_CHECKED_OUT_DEVICES";
export const SET_CHECKED_OUT_USER_DEVICES = "SET_CHECKED_OUT_USER_DEVICES";
export const SET_LOST_DEVICES = "SET_LOST_DEVICES"
export const SET_EXTERNAL_PREMIUM_PROFILES = "SET_EXTERNAL_PREMIUM_PROFILES";