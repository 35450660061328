import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api';
import CreateCustomerOrganizationModal from './modals/CreateCustomerOrganizationModal';
import AddCustomerToOrganizationModal from './modals/AddCustomerToOrganizationModal';
import UpdateCustomerOrganizationModal from './modals/UpdateCustomerOrganizationModal';
import AddOrganizationLicensesModal from './modals/AddOrganizationLicensesModal';
import ViewOrganizationLicensesModal from './modals/ViewOrganizationLicensesModal';
import ViewOrganizationDemoModal from './modals/ViewUpdateOrganizationDemosModal';

export default function Customer(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const organizations = useSelector(s => s.customer.organizations);
    const [showModal, setShowModal] = useState(() => false);
    const [showAddCustomerModal, setShowAddCustomerModal] = useState(() => false);
    const [showUpdateCustomerModal, setShowUpdateCustomerModal] = useState(() => false);
    const [selectedOrganization, setSelectedOrganization] = useState(() => undefined);
    const [showAddLicenseModal, setShowAddLicenseModal] = useState(() => undefined);
    const [showViewLicenseModal, setShowViewLicenseModal] = useState(() => undefined);
    const [showViewDemoModal, setShowViewDemoModal] = useState(() => true)
    const [showCreateDemoModal, setShowCreateDemoModal] = useState(() => true);

    const closeModal = async() => {
        await api.attemptGetCustomerOrganizations();
        setShowModal(() => false);
        setShowAddCustomerModal(() => false);
        setShowUpdateCustomerModal(() => false);
        setSelectedOrganization(() => false);
        setShowAddLicenseModal(() => false);
        setShowViewLicenseModal(() => false);
    }

    const loadInitialData = async() => {
        await api.attemptGetCustomerOrganizations();
    }

    const buildUserSection = (users) => {
        if (!users) return null;
        let activeUsers = users.filter(u => u.is_active);
        return (
            <Row>
                <h6>Active Users</h6>
                {activeUsers.map((u, i) => {
                    return (
                        <Col xs="12" sm="6" md="6" lg="6" key={i}>
                            {u.first_name} {u.last_name}
                        </Col>
                    )
                })}
            </Row>
        )
    }

    

    useEffect(() => {
        loadInitialData()
    }, [])

    let modal = null;

    if (showModal) {
        modal = <CreateCustomerOrganizationModal close={closeModal}/>
    } else if (showAddCustomerModal && selectedOrganization) {
        modal = <AddCustomerToOrganizationModal close={closeModal} organization={selectedOrganization}/>
    } else if (showUpdateCustomerModal && selectedOrganization){
        modal = <UpdateCustomerOrganizationModal close={closeModal} organization={selectedOrganization}/>
    } else if (showAddLicenseModal && selectedOrganization){
        modal = <AddOrganizationLicensesModal close={closeModal} organization={selectedOrganization} />
    } else if (showViewLicenseModal && selectedOrganization){
        modal = <ViewOrganizationLicensesModal close={closeModal} organization={selectedOrganization} />
    } else if (showViewDemoModal && selectedOrganization) {
        modal = <ViewOrganizationDemoModal close={closeModal} organization={selectedOrganization}/>
    }

    return (
        <Container>
            {modal}
            <Button className="mb-5" variant="success" onClick={() => setShowModal(() => true)}>CREATE NEW ORGANIZATION</Button>
            <Card bg="dark" text="white">
                <Card.Header>
                    <h4>CUSTOMER ORGANIZATIONS</h4>
                </Card.Header>
                <Card.Body>
                    {organizations.map((o, i) => {
                    
                        return (
                            <Card bg="dark" text="white" className="mt-5" key={i}>
                                <Card.Header>
                                    <h5>{o.organization_name}</h5>
                                </Card.Header>
                                <Card.Body>
                                    {buildUserSection(o.organization_users)}
                                    <br/>
                                    <Button onClick={() => {
                                        setSelectedOrganization(() => o)
                                        setShowAddCustomerModal(() => true);
                                    }}>
                                        ADD USER
                                    </Button>
                                    <Button onClick={() => {
                                        setSelectedOrganization(() => o);
                                        setShowViewLicenseModal(() => true);
                                    }}>
                                        VIEW LICENSES
                                    </Button>
                                    <Button onClick={() => {
                                        setSelectedOrganization(() => o);
                                        setShowAddLicenseModal(() => true);
                                    }}>
                                        ADD LICENSES
                                    </Button>

                                    <Button onClick={() => {
                                        setSelectedOrganization(() => o)
                                        setShowViewDemoModal(() => true)
                                    }}>
                                        VIEW DEMOS
                                    </Button>

                                    <hr/>
                                    <p>
                                        {o.advanced_settings_access ? "This organization has advanced settings access." : "This organization does not have advanced settings access."}
                                    </p>
                                    <a className="clicky" onClick={
                                        () => {
                                            setSelectedOrganization(() => o)
                                            setShowUpdateCustomerModal(() => true)
                                        }
                                    }>UPDATE {o.organization_name.toUpperCase()}</a>
                                </Card.Body>
                            </Card>
                        )
                    })}
                </Card.Body>
            </Card>
        </Container>
    )
}