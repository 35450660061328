import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';
import {Card, Form, Button, Row, Col} from 'react-bootstrap'


export default function CreateTestCaseModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const deviceTypes = useSelector(s => s.device.deviceTypes);
    const appState    = useSelector(s => s);
    const [testCase, updateTestCase] = useState(() => {
        return {
            device_type_id: undefined,
            test_case: {
                tests: []
            },
            test_sites: [],
        }
    });
    console.log(testCase)
    const changeDeviceType = (value) => {
        updateTestCase({...testCase, device_type_id: value});
    };

    const addBlankTest = () => {
        const tc = {...testCase};
        tc.test_case.tests.push({
            title: '',
            description: '',
            passed: false,
            notes: ''
        })
        updateTestCase(tc);
    }

    const onTestChange = (key, value, index) => {
        const tc = {...testCase};
        tc.test_case.tests[index] = {...tc.test_case.tests[index], [key]: value};
        updateTestCase(tc);
    }

    const saveTestCase = async(evt) => {
        evt.preventDefault();
        await api.attemptCreateDeviceTestCase(testCase);
        close(testCase.device_type_id);
    }

    const removeTest = (index) => {
        const tc = {...testCase};
        tc.test_case.tests.splice(index, 1);
        updateTestCase(tc);
    }

    const load = async () => {
        await api.attemptGetTestSites(); //NEEDED FOR OPTIONS
    }

    const addRemoveSite = (isChecked, siteId) => {
        if (isChecked){
            updateTestCase({...testCase, test_sites: [...testCase.test_sites, siteId]})
            return
        }

        let copy = [...testCase.test_sites].filter(id => id != siteId);
        updateTestCase({...testCase, test_sites: copy})
    }

    useEffect(() => {
        addBlankTest();
        load();
    }, [])

   

    return (
        <Card bg="dark" text="white" className="text-center">

            <Card.Header>
                <h3>CREATE A TEST CASE</h3>
            </Card.Header>

            <Card.Body>
            <Form onSubmit={saveTestCase}>
            
            <Form.Group>
            <h5>Test Name</h5>
            <Form.Control type="text"
                   value={testCase.test_name}
                   onChange={evt => updateTestCase({...testCase, test_name: evt.target.value})}/>
            </Form.Group>

            <Form.Group className="mt-5">
            <h5>Device Type</h5>
                <Form.Select value={testCase.device_type_id} onChange={evt => changeDeviceType(evt.target.value)} required>
                    <option>Select a Device Type</option>
                    {deviceTypes.map((dt, i) => {
                        return <option key={i} value={dt.id}>{`${dt.device_name}|${dt.model}`}</option>
                    })}
                </Form.Select>
            </Form.Group>  

            <h5 className="mt-5">Test Sites</h5>
            <p>Select the sites, where this test can be conducted.</p>
            <Row>
                {
                    appState.testSite.sites.map((site, i) => {
                        let checked = testCase.test_sites.find(ts => ts == site.id)
                        checked = checked ? true : false
                        return (
                            <Col key={i} xs="6" sm="6" md="6" lg="6" xl="6" xxl="6">
                                <Form.Check checked={checked} onChange={evt => addRemoveSite(evt.target.checked, site.id)}/> <Form.Label>
                                    {site.site_city}, {site.site_state} {site.site_country}
                                </Form.Label>
                            </Col>
                        )
                    })
                }
            </Row>
          
            <h5 className="mt-5">Tests</h5>
            {
                testCase.test_case.tests.map((t, i) => {
                    return (
                        <div className="spartan-test-form">
                            <strong>Title</strong>
                            <p>(Title of the test)</p>
                            <input type="text" 
                                   value={t.title}
                                   onChange={evt => onTestChange("title", evt.target.value, i)}
                                    required/>
                            <br/>
                            <strong>Description</strong>
                            <p>Description of the test</p>
                            <textarea
                                   value={t.description}
                                   onChange={evt => onTestChange("description", evt.target.value, i)} 
                                   required
                                   />
                            <br/>
                            <Button variant="danger" onClick={() => removeTest(i)}>REMOVE TEST</Button>
                            <hr/>
                        </div>
                    )
                })
            }

            <Button variant="success" onClick={addBlankTest}>Add Another Test</Button>
            <br/>
            <Button className="mt-5" type="submit">Save To Device</Button>
            </Form>
            </Card.Body>
        </Card>
    )
}