import {Modal, Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';

export default function CreateCustomerOrganizationModal({close}){
    const dispatch = useDispatch();
    const navigate  = useNavigate();
    const [api]    = useState(() => new SpartanApi({dispatch, navigate}));
    const [org, setOrg] = useState(() => {
        return {
            organization_name: '',
            admin_email: '',
            admin_password: '',
            admin_first_name: '',
            admin_last_name: ''
        }
    });

    const save = async (evt) => {
        evt.preventDefault();
        await api.attemptCreateCustomerOrganization(org);
        close();
    }

    const handleChange = (key, value) => {
        setOrg({...org, [key]: value});
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className='spartan-modal' closeButton>
                <h3>Create Customer Organization</h3>
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={save}>
                    <Form.Group>
                        <Form.Label>
                            Organization Name
                        </Form.Label>
                        <Form.Control type="text" 
                                      value={org.organization_name}
                                      onChange={evt => handleChange('organization_name', evt.target.value)}
                                      required />
                    </Form.Group>
                    <hr/>
                    <Form.Group>
                        <Form.Label>
                            Admin First Name
                        </Form.Label>
                        <Form.Control type="text" 
                                      value={org.admin_first_name}
                                      onChange={evt => handleChange('admin_first_name', evt.target.value)}
                                      required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Admin Last Name
                        </Form.Label>
                        <Form.Control type="text" 
                                      value={org.admin_last_name}
                                      onChange={evt => handleChange('admin_last_name', evt.target.value)}
                                      required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Admin Email Address
                        </Form.Label>
                        <Form.Control type="text" 
                                      value={org.admin_email}
                                      onChange={evt => handleChange('admin_email', evt.target.value)}
                                      required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Admin Initial Password
                        </Form.Label>
                        <Form.Control type="password" 
                                      value={org.admin_password}
                                      minLength={8}
                                      onChange={evt => handleChange('admin_password', evt.target.value)}
                                      required />
                    </Form.Group>

                    <Form.Group className="text-center mt-5">
                        <Button type="submit">CREATE ORGANIZATION</Button>
                    </Form.Group>

                </Form>
            </Modal.Body>
        </Modal>
    )
}