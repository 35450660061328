import {useDispatch, useSelector} from 'react-redux';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api';
import { Container, Card, Table, Button } from 'react-bootstrap';
import CreateAccessoryModal from './modals/CreateAccessoryModal';


export default function Accessory(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}))
    const [modalOpen, setModalOpen] = useState(() => false);
    const appState = useSelector(s => s)

    
    const load = async () => {
        await api.attemptGetAccessories();
    }

    const closeModal = () => {
        load();
        setModalOpen(false);
    }

    useEffect(() => {
        load();
    }, [])

    let modal = null;

    if (modalOpen){
        modal = <CreateAccessoryModal close={closeModal}/>
    }

    return (
        <Container>
            {modal}
            <Button onClick={() => setModalOpen(true)}>Create New Accessory Item</Button>
            <Card bg="dark" text="white" className="text-center mt-5">
                <Card.Header>
                    <h4>Accessory Items</h4>
                </Card.Header>
                <Card.Body>
                    <Table variant='dark' >
                        <thead>
                            <th>Part Number</th>
                            <th>Description</th>
                        </thead>

                        {appState.accessory.accessories.map((a, i) => {
                            return (
                                <tr key={i}>
                                    <td>{a.part_number}</td>
                                    <td>{a.description}</td>
                                </tr>
                            )
                        })}
                    </Table>
                </Card.Body>
            </Card>
        </Container>
    )


}