import { combineReducers } from "redux";
import user from "./user";
import testSite from "./test-site";
import device from "./device";
import shipping from "./shipping";
import alerts from './alerts';
import accessory from "./accessory";
import business from "./business";
import dashboard from "./dashboard";
import subAssembly from "./sub-assembly";
import inventory from './inventory'
import externalTool from "./external-tool";
import customer from './customer';

export default combineReducers({
    user,
    testSite,
    device,
    shipping,
    alerts,
    accessory,
    business,
    dashboard,
    subAssembly,
    inventory,
    externalTool,
    customer
});