import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../../../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';
import {Modal, Form, Card, Button} from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';

export default function AddSubAssembly({cancel, save, close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [selectedAssembly, setSelectedAssembly] = useState(() => null);
    const [requirements, setRequirements] = useState(() => []);
    const [scannedParts, setScannedParts] = useState(() => []);
    const [assemblyId, setAssemblyId] = useState(() => undefined);
    const [scanned, setScanned] = useState(() => null);
    const subAssemblyTypes = useSelector(s => s.subAssembly.subAssemblyTypes);

    const load = async () => {
        await api.attemptGetSubAssemblyTypes()
        await api.attemptGetSubAssemblyPartTypes();
    }

    const selectAssembly = () => {
        const assembly = subAssemblyTypes.find(s => s.id == assemblyId);
        setRequirements(assembly.requirements);
        setSelectedAssembly(assembly)
    }

    const decipher = async(result, error) => {
        if (error)return;
        setScanned(result.text)
    }

    const saveAssembly = async () => {
        let partsLeft = requirements.reduce((t, r) => t + r.quantity, 0);
        if (partsLeft > 0) return;

        save({
            id: assemblyId,
            sub_assembly_type_id: assemblyId,
            assembly_name: selectedAssembly.assembly_name,
            model: selectedAssembly.model,
            parts: scannedParts
        });
        
    }

    const resetAssembly = () => {
        setSelectedAssembly(null);
        setRequirements([])
        setScannedParts([])
    }

    const removePart = (index) => {
        let part = scannedParts[index];
        let rIndex = requirements.findIndex(r => r.sub_assembly_part_id == part.sub_assembly_part_type_id);
        let newRequirements = [...requirements];
        newRequirements[rIndex].quantity++;
        setRequirements(newRequirements);
        let newParts = [...scannedParts];
        newParts.splice(index, 1);
        setScannedParts(newParts);

    }

    const buildRequirements = () => {
        let partsLeft = requirements.reduce((t, r) => t + r.quantity, 0);

        if (!partsLeft) {
            return (
                <div className="text-center">
                    Ready To Ship
                </div>
            )
        }

        return (
            <div>
                <strong>{partsLeft}</strong> parts remaining
                <ul>
                    {requirements.map((r, i) => {
                        if (r.quantity <= 0) return null;
                        return (
                            <li key={i}>{r.quantity}x - {r.model} | {r.part_name}</li>
                        )
                    })}
                </ul>
            </div>
            
        )
    }

    const buildScannedParts = () => {
        if (!scannedParts.length) {
            return (
                <div className="text-center">
                    No Parts Have Been Scanned
                </div>
            )
        }

        return (
            <ul>
                {scannedParts.map((part, i) => {
                    return <li key={i}>{part.model} | {part.part_name} <br/> ({part.serial_number}) <br/>
                    <Button variant="danger" onClick={() => removePart(i)}>Remove Part From Assembly</Button></li>
                })}
            </ul>
        )
    }

    useEffect(() => {
        load();
    }, []);

    const validateAndAddPart = async(serial_number) => {
        if (!serial_number) return;
        //MAKE SURE IT DOESN'T EXIST IN SCANNED PARTS ARRAY
        const alreadyScanned = scannedParts.find(p => p.serial_number == serial_number);
        if (alreadyScanned) return; //TODO: GIVE MESSAGING TO END USER
        const part = await api.attemptGetAssemblyPartBySerialNumber(serial_number);
        if (!part) return;
        const valid = await api.attemptValidateSubAssemblyPartForShipping(serial_number);
        if (!valid.valid) return; //TODO: GIVE MESSAGING TO THE SCREEN

        setScannedParts(prev => {
            return [...prev, part];
        })

        setRequirements(prev => {
            const rIndex = prev.findIndex(p => p.sub_assembly_part_id == part.sub_assembly_part_type_id);
            if (rIndex <= -1) return prev;
            
            let copy = [...prev];
            copy[rIndex].quantity --;
            return copy;
        })
        
    }

    useEffect(() => {
        if (!scanned) return;
        console.log(scanned);
        validateAndAddPart(scanned);
    }, [scanned])

    
   
    if(!selectedAssembly){
        return (
            <Modal.Body className="spartan-modal">
                <Card bg="dark" text="white">
                    <Card.Header>
                        <h5>Select the Sub-Assembly Type</h5>
                        
                    </Card.Header>
                    <Card.Body>
                        <Form.Group>
                            <Form.Select value={assemblyId} onChange={evt => setAssemblyId(evt.target.value)}>
                                <option></option>
                                {subAssemblyTypes.map((s, i) => {
                                    return (
                                        <option key={i} value={s.id}>{s.model} | {s.assembly_name}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer className="text-center">
                        <Button variant="danger" onClick={cancel}>Cancel</Button>
                        <Button variant="success" onClick={selectAssembly}>Scan Parts</Button>
                        
                    </Card.Footer>
                </Card>
            </Modal.Body>
        )
    }

    return (
        <Modal.Body className="spartan-modal">
            <h5>Scanning Parts for {selectAssembly.model} | {selectedAssembly.assembly_name}</h5>
            <a onClick={() => resetAssembly()}>SELECT A DIFFERENT TYPE</a>
            <div style={{width: 300, height: 200, margin: '0 auto'}}>
                <QrReader constraints={{facingMode: 'environment'}}  onResult={decipher} style={{ width: '100%' }}/>
            </div>
            <br/> <br/> <br/>
            <Card bg="dark" text="white">
                <Card.Header>
                    <h5>Remaining Required Parts</h5>
                </Card.Header>
                <Card.Body>
                    {buildRequirements()}
                </Card.Body>
            </Card>

            <Card bg="dark" text="white">
                <Card.Header>
                    <h5>Scanned Parts</h5>
                </Card.Header>
                <Card.Body>
                    {buildScannedParts()}
                </Card.Body>

                <Card.Footer className="text-center">
                     
                    <Button variant="success" onClick={() => {saveAssembly()}}>Add Sub-Assembly To Bundle</Button> <br/> <br/>
                    <Button variant="danger" onClick={cancel}>Cancel Assembly</Button>
                </Card.Footer>
            </Card>
        </Modal.Body>
    )
}