import {Modal, Row, Col, Accordion, Table} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';
import moment from 'moment';


export default function ViewDeviceModal({device, close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [traces, setTraces] = useState(() => [])
    const [firmware, setFirmware] = useState(() => []);
    const [settings, setSettings] = useState(() => []);

    const reportMissing = async () => {
        await api.attemptReportMissing({device_id: device.device_id});
        close();
    }

    const loadData = async () => {
        const t = await api.attemptGetDeviceTraceHistory({id: device.device_id})
        const f = await api.attemptGetCustomerFirmwareChanges({id: device.device_id});
        const s = await api.attemptGetCustomerSettingsChanges({id: device.device_id});
        setTraces(t);
        setFirmware(f);
        setSettings(s);
        console.log(s)
    }

    useEffect(() => {
        if (!device || !device.device_id) return;
        loadData();
    }, [device])

    if (!device) return null;
   
    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header className="spartan-modal" closeButton>
                SN: {device.device_serial_number}
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Row>
                    <Col>
                        <div>
                            <strong>Armory Id</strong> <br/>
                            <span>{device.device_id}</span>
                        </div>
                        
                        <div className="mt-2">
                            <strong>
                                Serial Number
                            </strong> <br/>
                            {device.device_serial_number}
                        </div>

                        <div className="mt-2">
                            <strong>
                                Device Line
                            </strong> <br/>
                            {device.device_line}
                        </div>

                        <div className="mt-2">
                            <strong>
                                Model
                            </strong> <br/>
                            {device.model}
                        </div>

                        <div className="mt-2">
                            <strong>
                                Device Name
                            </strong> <br/>
                            {device.device_name}
                        </div>
                       
                          
                       
                       
                        {!device.firmware_version ? null : (

                                <div className="mt-2">
                                    <strong>
                                        Original Firmware Version
                                    </strong> <br/>
                                    {device.firmware_version}
                                </div>
                        )}
                        
                    </Col>
                    <Col>
                        
                    </Col>
                    
                </Row>

                <Row className="mt-4">
                    <Accordion>
                        <Accordion.Header>
                            Device History
                        </Accordion.Header>
                        <Accordion.Body>
                            <Table variant='dark' striped={true}>
                                <thead>
                                    <tr>
                                        <th>
                                            Test Site
                                        </th>
                                        <th>
                                            Tester
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                        <th>
                                            Date/Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {traces.map((t, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{t.site_name}</td>
                                                <td>{t.tester_first_name} {t.tester_last_name}</td>
                                                <td><p>{t.notes}</p></td>
                                                <td>{moment(t.created_on).format("MM/DD/YYYY - hh:mma")}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion>
                </Row>
                <Row className="mt-4">
                    <Accordion>
                        <Accordion.Header>
                            Firmware Changes
                        </Accordion.Header>

                        <Accordion.Body>
                            <Table variant='dark' striped={true}>
                                <thead >
                                    <tr>
                                        <th>
                                            Organization
                                        </th>
                                        <th>
                                            Updated By
                                        </th>
                                        <th>
                                            Firmware Version
                                        </th>
                                        <th>
                                            Updated On
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {firmware.map((f, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{f.organization_name}</td>
                                                <td>{f.first_name} {f.last_name}</td>
                                                <td>{f.firmware_version}</td>
                                                <td>{moment(f.created_on).format('MM/DD/YYYY - hh:mma')}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion>
                </Row> 

                <Row className="mt-4">
                    <Accordion>
                        <Accordion.Header>
                            Settings Changes
                        </Accordion.Header>
                        <Accordion.Body>
                            {
                                settings.map((s, i) => {
                                    return (
                                        <div className="mt-3" key={i}>
                                            <strong>{s.organization_name}</strong><br/>
                                            <strong>{s.first_name} {s.last_name}</strong><br/>
                                            <span>{moment(s.created_on).format('MM/DD/YYYY - hh:mma')}</span>
                                            <br/> <br/>
                                            <pre>
                                                {JSON.stringify(s.presets, null, 4)}
                                            </pre>
                                            <hr/>
                                        </div>
                                    )
                                })
                            }
                        </Accordion.Body>
                    </Accordion>
                </Row> 
                <hr/>
            </Modal.Body>
            <Modal.Footer className="spartan-modal">
                <a onClick={reportMissing} className="clicky">Report this device as lost</a>
            </Modal.Footer>
        </Modal>
    )
}