import {Modal, Form, Card, Button} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import SpartanApi from '../../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';

export default function CreateSubAssemblyTypeModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const [subAssemblyType, setSubAssemblyType] = useState(() => {
        return {
            assembly_name: '',
            model: '',
            requirements: [
                {
                    sub_assembly_part_id: '',
                    quantity: 1
                }
            ]
        }
    })
    const subAssemblyPartTypes = useSelector(s => s.subAssembly.subAssemblyPartTypes);

    const load = async () => {
        await api.attemptGetSubAssemblyPartTypes();
    }

    const attemptSaveSubAssemblyType = async(evt) => {
        evt.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        await api.attemptCreateSubAssemblyType(subAssemblyType);
        close();
    }
    
    const onChangeRequirements = (key, value, index) => {
        let newRequirements = [...subAssemblyType.requirements];
        newRequirements[index][key] = value;
        setSubAssemblyType({
            ...subAssemblyType,
            requirements: newRequirements
        })
    }

    const removeRequirement = (index) => {
        let newRequirements = [...subAssemblyType.requirements];
        if (newRequirements.length <= 1) return;
        newRequirements.splice(index, 1);
        setSubAssemblyType({
            ...subAssemblyType,
            requirements: newRequirements
        });
    }

    const addBlankRequirement = () => {
        let newRequirements = [...subAssemblyType.requirements];
        newRequirements.push({
            sub_assembly_part_id: '',
            quantity: 1
        });
        setSubAssemblyType({
            ...subAssemblyType,
            requirements: newRequirements
        });
    }

    const buildRequirementsSection = () => {
        if (!subAssemblyPartTypes || !subAssemblyPartTypes.length) return null;
        return (
            <div className="mt-5">
                <h3>Requirements</h3>

                {subAssemblyType.requirements.map((r, i) => {
                    return (

                        <div  className="mt-3" key={i}>
                        <h5>Part #{i + 1}</h5>
                        <Form.Group>
                            <Form.Label>
                                Sub-Assembly Part
                            </Form.Label>
                            <Form.Select value={r.sub_assembly_part_id}
                                         onChange={evt => onChangeRequirements('sub_assembly_part_id', evt.target.value, i)}
                                         required>
                                <option></option>
                                {subAssemblyPartTypes.map((part, i) => {
                                    return <option key={i} value={part.id}>{part.model} | {part.part_name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Units in Assembly
                            </Form.Label>
                            <Form.Control type="number" value={r.quantity} onChange={evt => onChangeRequirements('quantity', evt.target.valueAsNumber, i)} required/>
                        </Form.Group>
                        <Button variant="danger" onClick={() => removeRequirement(i)}>Remove Part from Assembly</Button>
                        </div>
                    )
                })}

                <Button className="mt-3" variant="success" onClick={() => addBlankRequirement()}>ADD ANOTHER PART</Button>
            </div>
        )
    }

    useEffect(() => {
        load();
    }, []);


    return (
        <Modal bg="dark" show={true} onHide={close}>
            <Modal.Body bg="dark">
                <Card bg="dark" color="white">
                    <Card.Header>
                        CREATE SUB-ASSEMBLY TYPE
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={attemptSaveSubAssemblyType}>
                            <Form.Group>
                                <Form.Label>
                                    Assembly Name
                                </Form.Label>
                                <Form.Control type="text"
                                              value={subAssemblyType.assembly_name}
                                              onChange={evt => setSubAssemblyType({...subAssemblyType, assembly_name: evt.target.value.toUpperCase()})}
                                              required/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>
                                    Model
                                </Form.Label>
                                <Form.Control type="text"
                                              value={subAssemblyType.model}
                                              onChange={evt => setSubAssemblyType({...subAssemblyType, model: evt.target.value.toUpperCase()})}
                                              required/>
                            </Form.Group>

                            {buildRequirementsSection()}

                            <div className="mt-5 text-center">
                                <Button type="submit">Create Sub-Assembly</Button>
                            </div>
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}