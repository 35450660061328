import { useState, useEffect } from "react";
import { useSelector, useDispatch} from 'react-redux'
import SpartanApi from "../../helpers/spartan-api";

export default function CreateDeviceTypeFirmwareModal({close}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const deviceData = useSelector(s => s.device);
    const [newFirmware, setNewFirmware] = useState(() => {
        return {
            device_type_id: undefined,
            firmware_version: undefined,
            firmware_github_url: undefined,
            is_active: true,
            notes: ''
        }
    })

    const handleFormChange = (key, value) => {
        setNewFirmware({...newFirmware, [key]: value})
    }

    const handleSubmit = async(evt) => {
        evt.preventDefault();
        await api.attemptCreateDeviceTypeFirmware(newFirmware);
        close(newFirmware.device_type_id)
    }   

    return (
        <div className="spartan-modal">
            <div className="spartan-modal-heading">
                <h3 className="text-center">Create Device Type Firmware</h3>
            </div>
            <hr/>
            <div className="spartan-modal-body text-center">
                <form onSubmit={handleSubmit}>
                <h5>Device Type</h5>
                <select value={newFirmware.device_type_id} onChange={evt => handleFormChange('device_type_id', evt.target.value)}>
                    <option value={undefined}>Select a Device Type</option>
                {deviceData.deviceTypes.map((dt, i) =>  {
                    return <option key={i} value={dt.id}>{dt.device_line} | {dt.device_name} | {dt.model}</option>
                })}
                </select>

                <br/> <br/>
                <h5>Firmware Version</h5>
                <input type="text" value={newFirmware.firmware_version} onChange={evt => handleFormChange('firmware_version', evt.target.value)}/>
                <br/><br/>

                <h5>Firmware Github URL</h5>
                <input type="text" value={newFirmware.firmware_github_url} onChange={evt => handleFormChange('firmware_github_url', evt.target.value)}/>
                <br/> <br/>

                <h5>Firmware is Active </h5>
                <input type="checkbox" checked={newFirmware.is_active} onChange={evt => handleFormChange('is_active', evt.target.checked)} />
                <br/> <br/>

                <h5>Firmware Notes</h5>
                <textarea value={newFirmware.notes} onChange={evt => handleFormChange('notes', evt.target.value)}/>
                <br/> <br/>

                <button type="submit">Save Firmware</button>
                </form>
            </div>
        </div>
    )
}