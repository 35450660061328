import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import SpartanApi from "../../helpers/spartan-api";
import { QrReader } from "react-qr-reader";
import { Container, Row, Col, Button, Card, Form, Modal } from "react-bootstrap";
import AlertHelper from "../../helpers/alert-helper";
import AddSubAssembly from "./sub-modals/AddSubAssembly";
import AlertContainer from "../../shared/alerts/alerts";
const editable_value = 'Awaiting Delivery'


export default function UpdateShippingModal({close, bundle, bundleId}) {
    const dispatch = useDispatch();
    const [api]    = useState(() => new SpartanApi({dispatch}));
    const [alertHelper] = useState(() => new AlertHelper({dispatch}));
    const [partsToAdd, setPartsToAdd] = useState(() => []);
    const [partsToShip, setPartsToShip] = useState(() => []);
    const [partsToRemove, setPartsToRemove] = useState(() => []);
    const [devicesToShip, setDevicesToShip] = useState(() => []);
    const [accessoriesToShip, setAccessoriesToShip] = useState(() => []);
    
    const [accessoriesToRemove, setAccessoriesToRemove] = useState(() => [])
    const [devicesToAdd, setDevicesToAdd] = useState(() => []);
    const [devicesToRemove, setDevicesToRemove] = useState(() => []);
    const [subAssembliesToShip, setSubAssembliesToShip] = useState(() => []);
    const [subAssembliesToRemove, setSubAssembliesToRemove] = useState(() => []);
    const [subAssembliesToAdd, setSubAssembliesToAdd] = useState(() => []);
    const [inSubAssemblyMode, setInSubAssemblyMode] = useState(() => false);
    const [shippingAddress, setShippingAddress] = useState(() => {
        return {
            recipient: '',
            address_one: '',
            address_two: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            
        }
    });

    const [initialStatus, setInitialStatus] = useState(() => undefined)
    const appState = useSelector(s => s);

    const [bundleInformation, setBundleInformation] = useState(() =>  {
        return {
            shipment_location_id: undefined,
            shipping_carrier: undefined,
            shipping_tracking_number: undefined,
            delivery_status: undefined
        }
    })
    const [barcode, setBarcode] = useState(() => undefined);
    const [scanned, setScanned] = useState(() => undefined);
    const [isSaving, setIsSaving] = useState(() => false);

    const attemptAddPartToArray = async(part) => {
        const valid = await  api.attemptValidateSubAssemblyPartForShipping(part.serial_number);
        if (!valid.valid) {
            return alertHelper.addAlert(
                {
                    title: "SCANNED PART DOESN'T PASS VALIDATION",
                    body: valid.reasonForFailure
                }
            )
        }
        const exists = partsToShip.find(p => p.part_id == part.part_id);
        if (exists) return;
        setPartsToShip(prev => {
            return [part, ...prev]
        });

        setPartsToAdd(prev => {
            return [...prev, part];
        })
    }

    const attemptAddToArray = async (sn) => {
        let device = await api.attemptGetDeviceOrPartBySerialNumber(sn);
        setBarcode(() => '')
        if (!device) return;
        if (device.part) {
            //THIS IS A SUB ASSEMBLY PART, NOT A DEVICE
            attemptAddPartToArray(device.part);
            return;
        }
        
        device = device.device;
        const exists = devicesToShip.find(d => d.device_id == device.device_id);
        const valid = await api.attemptValidateItemForShipping(device.device_id);
        if (!valid.valid) {
            alertHelper.addAlert(
                {
                    title: "SCANNED DEVICE ITEM DOESN'T PASS VALIDATION",
                    body: valid.reasonForFailure
                }
            )
            return
        }
        if (exists) return; // Already Added
        setDevicesToShip((prev) => {
            return [device, ...prev]
        })

        setDevicesToAdd((prev) => {
            return [...prev, device]
        })
    }



    

    useEffect(() =>  {
        if (!scanned) return;
        attemptAddToArray(scanned);
    }, [scanned])

    
    const onBarcodeScan = (evt) => {
        evt.preventDefault();
        if (!barcode) return;
        setScanned(barcode);
    }

    const decipher = async(result, error) => {
        if (error) return;
        setScanned(result.text)
    }

    const removeItem = async(index) => {
        const newArr = [...devicesToShip];
        const device = newArr.splice(index, 1);
        setDevicesToShip(newArr);
        setDevicesToRemove(prev => {
            return [...prev, device[0]];
        })
    }

    const removeSubAssembly = async (index) => {
        const newArr = [...subAssembliesToShip];
        const assembly = newArr.splice(index, 1);
        setSubAssembliesToShip(newArr);

        //Account For Arrays that Aren't in the System Yet
        if (!assembly[0].assembly_part_counts){
            const addIndex = subAssembliesToAdd.findIndex(s => s.id == assembly[0].id);
            if (addIndex >= 0){
                let newSubArray = [...subAssembliesToAdd];
                newSubArray.splice(addIndex, 1);
                setSubAssembliesToAdd(newSubArray);
                return;
            }
        }

        setSubAssembliesToRemove(prev => {
            return [...prev, assembly[0]]
        })
    }

    const removePartFromList = (index) => {
        let newArr = [...partsToShip];
        let part = newArr.splice(index, 1)[0];
        setPartsToRemove(prev => {
            return [...prev, part]
        })
        setPartsToShip(newArr);

        //REMOVE PART TO ADD IF IT IS THERE
        const existingIndex = partsToAdd.findIndex(pta => pta.part_id == part.part_id);
        if (existingIndex <= -1) return;
        let newAddArr = [...partsToAdd];
        newAddArr.splice(existingIndex, 1);
        setPartsToAdd(newAddArr);
    }

    const loadData = async() =>  {
        await api.attemptGetAccessories();
        await api.attemptGetTestSites();
    }

    const loadViaApi = async () => {
       const bundle =  await api.attemptGetShippingBundleById(bundleId);
       
       if (!bundle) return;
       setBundleInformation({
           ...bundle
       })

       setShippingAddress({...bundle.shipping_address})
       if (bundle.shipping_bundle_items){
           setDevicesToShip([...bundle.shipping_bundle_items]);
       }

       if (bundle.accessory_items) {
           setAccessoriesToShip([...bundle.accessory_items])
       }
       

       setInitialStatus(bundle.delivery_status);

    }



    const saveShipment = async (evt) =>  {
        evt.preventDefault();
        setIsSaving(true);
        
        const requestData = {
            shipping_bundle: bundleInformation,
            shipping_address: shippingAddress,
            shipment_bundle_items: devicesToShip,
            items_to_add: devicesToAdd,
            items_to_remove: devicesToRemove,
            shipment_bundle_accessories: accessoriesToShip,
            accessories_to_remove: accessoriesToRemove,
            accessories: accessoriesToShip.filter(a => {
                return a.quantity && a.accessory_item_id != "SELECT AN ACCESSORY ITEM"
            }),
            sub_assemblies_to_add: subAssembliesToAdd,
            sub_assemblies_to_remove: subAssembliesToRemove,
            stand_alone_parts_to_add: partsToAdd,
            stand_alone_parts_to_remove: partsToRemove
        }
       

        await api.attemptUpdateShippingBundle(requestData);

        close();
        
    }

    const addAccessoryForm = () => {
        let blank = { 
            accessory_item_id: '',
            quantity: 0
        };
        setAccessoriesToShip([...accessoriesToShip, blank])
    }

    const handleAccessoryChange = (index, key, value) => {
        let copy = [...accessoriesToShip];
        copy[index][key] = value;
        setAccessoriesToShip(copy);
    }
    
    const handleRemoveAccessoryItem = (index) => {
        let copy = [...accessoriesToShip];
        const accessory = copy.splice(index, 1);
        console.log(accessory)
        setAccessoriesToShip(copy);
        setAccessoriesToRemove(prev => {
            return [...prev, accessory[0]]
        });
        
    }


    useEffect(() =>  {
        loadData();
    }, [])

    useEffect(() =>  {
        if (!bundle) return;
        setBundleInformation({
            ...bundle
        })

        setShippingAddress({...bundle.shipping_address})
        if (bundle.shipping_bundle_items){
            setDevicesToShip([...bundle.shipping_bundle_items]);
        }

        if (bundle.accessory_items) {
            setAccessoriesToShip([...bundle.accessory_items])
        }

        if (bundle.sub_assemblies){
            setSubAssembliesToShip([...bundle.sub_assemblies])
        }

        if (bundle.stand_alone_parts) {
            setPartsToShip([...bundle.stand_alone_parts]);
        }
        

        setInitialStatus(bundle.delivery_status);
    }, [bundle])

    useEffect(() => {
        if (!bundleId) return;
        loadViaApi();
    }, [bundleId])

    const cancelAssembly = () => {
        setInSubAssemblyMode(false);
    }

    const addSubAssemblyToList = (assembly) => {
        setSubAssembliesToShip(prev => {
           return [assembly, ...prev];
        })

        setSubAssembliesToAdd(prev => {
            return [...prev, assembly]
        })

        setInSubAssemblyMode(false);
    }

    const buildSubAssemblyAdd = () => {
        return (
            <AddSubAssembly cancel={cancelAssembly} save={addSubAssemblyToList}/>
        )
    }

    const buildStandAloneParts = () => {
        if (!partsToShip.length) return null;

        return (
            <div>
                <Card bg="dark" text='white'>
                    <Card.Header>
                        <h3>Standalone Parts</h3>
                    </Card.Header>
                    <Card.Body>
                        
                            {
                                partsToShip.map((p, i) => {
                                    return (
                                        <Row key={i}>
                                            <Col>({p.serial_number})</Col>
                                            <Col>{p.model} | {p.part_name}</Col>
                                            <Col><Button variant='danger' onClick={evt => removePartFromList(i)}>REMOVE</Button></Col>
                                            <hr className="mt-3"/>
                                        </Row>
                                    )
                                })
                            }
                       
                    </Card.Body>
                </Card>
            </div>
        )
    }


    const buildMainSection = () => {
        if (inSubAssemblyMode) return buildSubAssemblyAdd();
        return (
            <Modal.Body className="spartan-modal">
                <AlertContainer/>
        <div className="text-center">
            {initialStatus == editable_value ?
            <div className="qr-reader-container">
                <div style={{width: 300, height: 200, margin: '0 auto'}}>
                    <QrReader constraints={{facingMode: 'environment'}} onResult={decipher} style={{ width: '100%' }}/>
                </div>
                <br/> <br/> <br/>
                <h5>You can also use the barcode scanner. Click the input box below and scan the QR Code</h5>
                            <Form onSubmit={onBarcodeScan}>
                                <Form.Control value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                            </Form>
            </div> : null}
            <hr className="mt-5 mb-5"/>
            <Card bg="dark" text="white" className="mb-5">
                <Card.Header><h3>Items to Ship</h3></Card.Header>
                <Card.Body>
                    {devicesToShip.map((d, i) => {
                        console.log(d)
                        return (
                            <Row>
                                <Col>({d.device_serial_number || d.serial_number})</Col>
                                <Col>{d.model} | {d.device_line} | {d.device_name}</Col>
                                <Col>
                                {initialStatus === editable_value ? <Button variant="danger" onClick={() => removeItem(i)}>Remove</Button>: null} 
                                </Col>
                                <hr className="mt-3"/>
                            </Row>
                            //<li className='mt-4' key={i}><strong>{d.device_serial_number || d.serial_number} | {d.model} | {d.device_line} | {d.device_name}</strong> {initialStatus == editable_value ?<div> <Button variant="danger" onClick={() => removeItem(i)}>Remove</Button></div> : null}</li>
                        )
                    })}
                </Card.Body>
            </Card>
           
            
                
       
            {buildStandAloneParts()}
            <hr/>
            <Card bg="dark" text="white">
                <Card.Header>
                    <h5>Sub-Assemblies</h5>
                </Card.Header>
                <Card.Body>
                    <ul>
                    {subAssembliesToShip.map((s, i) => {
                        return <li key={i}> 
                            {s.model} | {s.assembly_name} <br/>
                        {s.parts.length} Parts Included <br/>
                        <Button disabled={initialStatus != editable_value} variant="danger" onClick={() => removeSubAssembly(i)}>Remove From Bundle</Button></li>
                    })}
                    </ul>
                   
                </Card.Body>
                <Card.Footer className="mt-3">
                    <Button onClick={() => setInSubAssemblyMode(true)}>ADD SUB-ASSEMBLY TO BUNDLE</Button>
                </Card.Footer>
            </Card>

            <hr/>
            <Card bg="dark" text="white">
                <Card.Header>
                    <h3>Accessory Items To Ship</h3>
                </Card.Header>

                <Card.Body>
                    <Container>
                {accessoriesToShip.map((ats, i) => {
                return (
                    <Row key={i}>
                      <Col >
                      <Form.Group>
                        <Form.Label>Accessory</Form.Label>
                        <Form.Select disabled={initialStatus != editable_value} value={ats.accessory_item_id} onChange={evt => handleAccessoryChange(i, 'accessory_item_id', evt.target.value)}>
                            <option value={undefined}>SELECT AN ACCESSORY ITEM</option>
                            {appState.accessory.accessories.map((accessory, j) =>  {
                                return (
                                    <option key={j} value={accessory.id}>{`${accessory.part_number}|${accessory.description}`}</option>
                                )
                            })}
                        </Form.Select>
                      </Form.Group>
                      
                       </Col>

                       <Col>
                            <Form.Group>
                                <Form.Label>
                                    Quantity
                                </Form.Label>

                                <Form.Control readOnly={initialStatus != editable_value} type="number" value={ats.quantity} onChange={evt => handleAccessoryChange(i, 'quantity', evt.target.valueAsNumber)} />
                            </Form.Group>
                        
                        
                        </Col>
                        {(initialStatus == editable_value) ?
                        (<Col>
                            <Form.Group>
                                <br/> 
                                 <Button onClick={() => handleRemoveAccessoryItem(i)} variant="danger">Remove Accessory</Button> 
                            </Form.Group>
                            
                        </Col>) : null }
                        
                   
                    </Row>
                )
            })}

                </Container>
                </Card.Body>

                <Card.Footer>
                {(initialStatus == editable_value) ? <Button className="mt-5" onClick={addAccessoryForm}>ADD ACCESSORY ITEM TO BUNDLE</Button> : null }
                    
                </Card.Footer>
            </Card>

            <Container>
                <hr/>
            <form onSubmit={saveShipment}>
                <Row>
                    
                    <Col>
                    <h6>Recipient</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="recipient"
                            value={shippingAddress.recipient}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
                   <br/> <br/>
                    <h6>Address Line One</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="address_one"
                            value={shippingAddress.address_one}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/> <br/>
                    <h6>Address Line Two</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="address_two"
                            value={shippingAddress.address_two}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            />
<br/> <br/>
                    <h6>City</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="city"
                            value={shippingAddress.city}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/> <br/>
                    <h6>State</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="state"
                            value={shippingAddress.state}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/> <br/>
                    <h6>Zipcode</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="zipcode"
                            value={shippingAddress.zipcode}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
<br/> <br/>
                    <h6>Country</h6>
                    <input  readOnly={initialStatus != editable_value}
                            name="country"
                            value={shippingAddress.country}
                            type="text"
                            onChange={evt => setShippingAddress({...shippingAddress, [evt.target.name]: evt.target.value})} 
                            required/>
                            <br/> <br/>
                    </Col>

                    <Col>
                        <h6>Shipping From</h6>
                        <select readOnly={initialStatus != editable_value} value={bundleInformation.shipment_location_id} onChange={evt => setBundleInformation({...bundleInformation, shipment_location_id: evt.target.value})} required>
                                    <option></option>
                            {appState.testSite.sites.map((s, i) =>  {
                                return (
                                    <option key={i} value={s.id}>{s.site_city}, {s.site_state}, {s.site_country}</option>
                                )
                            })}
                        </select>
                        <br/> <br/>
                        <h6>Tracking Number (Optional)</h6>
                        <input  readOnly={initialStatus != editable_value}
                                name="shipping_tracking_number"
                                value={bundleInformation.shipping_tracking_number}
                                type="text"
                                onChange={evt => setBundleInformation({...bundleInformation, [evt.target.name]: evt.target.value})} />
                        <br/> <br/>
                        <h6>Shipping Carrier (Optional)</h6>
                        <input readOnly={initialStatus != editable_value}
                               name="shipping_carrier"
                               value={bundleInformation.shipping_carrier}
                               type="text"
                               onChange={evt => setBundleInformation({...bundleInformation, [evt.target.name]: evt.target.value})}
                                />
                        <br/> <br/>

                        <h6>Delivery Status</h6>
                        <select value={bundleInformation.delivery_status}
                                onChange={evt => setBundleInformation({...bundleInformation, delivery_status: evt.target.value})} 
                                required>
                            <option value={undefined}></option>
                            <option value="Awaiting Delivery">Awaiting Delivery</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Delivered">Delivered</option>
                        </select>
                        <br/> <br/>

                       
                    </Col>
                    
                    
                </Row>

                <Row>
                    <hr className="mt-5"/>
                <Col>
                <h6>Purchase Info</h6>
                        <select value={bundleInformation.payment_info}
                                onChange={evt => setBundleInformation({...bundleInformation, payment_info: evt.target.value})}>
                            <option></option>
                            <option value="Zero Cost">Zero Cost</option>
                            <option value="PO Received">PO Received</option>
                            <option value="CC Information Received">CC Information Received</option>
                        </select>
                        <br/> <br/>

                        <h6>Purchase Order Number</h6>
                        <input type="text" 
                               value={bundleInformation.purchase_order_number} 
                               onChange={evt => setBundleInformation({...bundleInformation, purchase_order_number: evt.target.value})}/>
                        <br/> <br/>

                        <h6>Purchase Order URL</h6>
                        <input type="text"
                               value={bundleInformation.purchase_order_url}
                               onChange={evt => setBundleInformation({...bundleInformation, purchase_order_url: evt.target.value})}/>
                </Col>
                    <Button disabled={isSaving } type="submit" className="mt-5">Update Bundle</Button>
                </Row>
                </form>
            </Container>
        </div>
        </Modal.Body>
        )
    }

   

    return (
        <Modal className="spartan-modal-backdrop" dialogClassName="modal-90w" show={true} onHide={close}>
        <Modal.Header className="spartan-modal" closeButton>
            <Modal.Title>Update Shipping Bundle</Modal.Title>    
        </Modal.Header>     
        {buildMainSection()}
        </Modal>
    )
}