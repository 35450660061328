import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import SpartanApi from "../helpers/spartan-api";
import CreateUserModal from "./modals/CreateUserModal";

export default function TestSiteLocation() {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [createUserModalOpen, setCreateUserModalOpen] = useState(() => false);
    const siteData = useSelector(s => s.testSite);
    

    const closeModal = async () => {
        setCreateUserModalOpen(false);
        await api.attemptGetUsersBySite({test_site_id: params.siteId});
    }
    const loadData = async () => {
        await api.attemptGetUsersBySite({test_site_id: params.siteId});
    }

    const buildUserSection = () => {
        if (!params || !params.siteId || !siteData.siteUsers[params.siteId]) return null;
        const siteUsers = siteData.siteUsers[params.siteId];

        if (!siteUsers.length){
            return (
                <div>
                    No Users are associated with this site, please <button onClick={() => setCreateUserModalOpen(!createUserModalOpen)}>Create One</button>
                </div>
            )
        }
       
        return (
            <div>
                <table className="table table-dark">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Id</th>
                        </tr>
                    </thead>
                    <tbody>
                        {siteUsers.map((su, i) => {
                            return (
                                <tr>
                                    <td>{su.first_name}</td>
                                    <td>{su.last_name}</td>
                                    <td>{su.email}</td>
                                    <td>{su.id}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <br/>
                <button onClick={() => setCreateUserModalOpen(!createUserModalOpen)}>Create New User</button>
            </div>
        )
        //WE HAVE USERS TO DISPLAY
    }
    useEffect(() => {
        if (!params || !params.siteId) return;
        loadData();
    }, [params]);

    const siteUserSection = buildUserSection()

    if (createUserModalOpen) return <CreateUserModal siteId={params.siteId} closeCB={closeModal} />
    return (
        <div>
            {siteUserSection}
            <button onClick={() => navigate('/admin')}>Back to Admin Page</button>
        </div>
    )
}