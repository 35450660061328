import {Modal, Form, Button, Row, Col} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useState, useEffect} from 'react';
import SpartanApi from '../../helpers/spartan-api';
import {spartanUploadHelper} from '../../helpers/upload-helper';

export default function UpdatePremiumProfileModal({close, profile}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}))
    const appState = useSelector(s => s);
    const [newFile, setNewFile] = useState(() => null);
    const [formData, setFormData] = useState(() => {
        return {
            formFieldParameters: {
                downrangeLength: {
                    defaultSettings: true,
                    min: .5,
                    max: 30
                },
                downrangeOffset: {
                    defaultSettings: true,
                    min: 0,
                    max: 5
                },
                crossRangeWidth: {
                    defaultSettings: true,
                    min: .5,
                    max: 30
                },
                crossRangeOffset: {
                    defaultSettings: true,
                    min: -1.3,
                    max: 1.3
                },
                sensorLocation: {
                    defaultSettings: true,
                    locations: [
                        'left', 'right', 'front', 'rear'
                    ]
                },
                terrain: {
                    defaultSettings: true,
                    options: ['on-road', 'off-road']
                },
                triggerInput: {
                    defaultSettings: true,
                    options: ['high', 'low', 'always']
                }

            }
        }
    });

    const locationOptions = ['left', 'right', 'front', 'rear'];
    const terrainOptions =['on-road', 'off-road'];
    const triggerOptions = ['high', 'low', 'always'];

    useEffect(() => {
        setFormData(() => {
            return {...formData, ...profile.profile_meta_data}
        })
    }, [profile]);

    const handleFormFieldChange = (topic, key, value) => {
        const copy = {...formData};
        copy.formFieldParameters[topic][key] = value;
        setFormData(copy);
    }

    const handleOptionChange = (topic, key, value, add) => {
        const copy = {...formData};
        let copyArray = [...copy.formFieldParameters[topic][key]]
        console.log(add);
        if (add) {
            //Make sure it is not already in the array
            if (copyArray.indexOf(value) <= -1){
                console.log("Making it here")
                copyArray.push(value)
            }
        } else {
            copyArray = copyArray.filter(v => v != value);
        }
            copy.formFieldParameters[topic][key] = copyArray
            setFormData(copy);
        
    }

    const onSave = async (evt) => {
        evt.preventDefault();
        const requestObj = {
            id: profile.profile_id,
            profile_meta_data: formData
        };

        if (newFile){
            await api.attemptGetToken();
            requestObj.base_ini_url = await spartanUploadHelper({sasToken: appState.user.sasToken, containerName: 'premiuminifiles', file: newFile});
        }
       
        await api.attemptUpdateExternalConfigToolPremiumProfile(requestObj);
        close();
    }

    const handleFileChange = evt => {
        setNewFile(evt.target.files[0])
    }

    if (!profile) return null;
   
    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header className="spartan-modal">
                UPDATE PROFILE - {profile.profile_name.toUpperCase()}
            </Modal.Header>
            <Modal.Body className="spartan-modal">
               
                <Form onSubmit={onSave}>
                    <h5>Base INI File</h5>
                    <input type="file" accept=".ini"  onChange={handleFileChange}/>

                    <hr className="mb-4"/>
                    <h5>Form Field Settings</h5>
                    <Form.Group className="mt-5">
                        <Form.Label>
                            Downrange Length
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.downrangeLength.defaultSettings}
                            onChange={evt => handleFormFieldChange('downrangeLength', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-4">
                            <Col>
                                <Form.Label className="">Min Length</Form.Label>
                                <Form.Control type="number"
                                              value={formData.formFieldParameters.downrangeLength.min}
                        
                                              onChange={evt => handleFormFieldChange('downrangeLength', 'min', evt.target.valueAsNumber)}/>
                            </Col>

                            <Col>
                                <Form.Label className="">Max Length</Form.Label>
                                <Form.Control type="number"
                                          value={formData.formFieldParameters.downrangeLength.max}
                                          onChange={evt => handleFormFieldChange('downrangeLength', 'max', evt.target.valueAsNumber)}/>
                            </Col>
                        </Row>
                        
                    </Form.Group>
                    <hr/>
                    <Form.Group className="mt-5">
                        <Form.Label>
                            Downrange Offset
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.downrangeOffset.defaultSettings}
                            onChange={evt => handleFormFieldChange('downrangeOffset', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-4">
                            <Col>
                                <Form.Label className="">Min Length</Form.Label>
                                <Form.Control type="number"
                                              value={formData.formFieldParameters.downrangeOffset.min}
                        
                                              onChange={evt => handleFormFieldChange('downrangeOffset', 'min', evt.target.valueAsNumber)}/>
                            </Col>

                            <Col>
                                <Form.Label className="">Max Length</Form.Label>
                                <Form.Control type="number"
                                          value={formData.formFieldParameters.downrangeOffset.max}
                                          onChange={evt => handleFormFieldChange('downrangeOffset', 'max', evt.target.valueAsNumber)}/>
                            </Col>
                        </Row>
                        </Form.Group>
                        <hr/>
                        <Form.Group className="mt-5">
                        <Form.Label>
                            Crossrange Width
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.crossRangeWidth.defaultSettings}
                            onChange={evt => handleFormFieldChange('crossRangeWidth', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-4">
                            <Col>
                                <Form.Label className="">Min Length</Form.Label>
                                <Form.Control type="number"
                                              value={formData.formFieldParameters.crossRangeWidth.min}
                        
                                              onChange={evt => handleFormFieldChange('crossRangeWidth', 'min', evt.target.valueAsNumber)}/>
                            </Col>

                            <Col>
                                <Form.Label className="">Max Length</Form.Label>
                                <Form.Control type="number"
                                          value={formData.formFieldParameters.crossRangeWidth.max}
                                          onChange={evt => handleFormFieldChange('crossRangeWidth', 'max', evt.target.valueAsNumber)}/>
                            </Col>
                        </Row>
                        </Form.Group>
                        <hr/>
                        <Form.Group className="mt-5">
                        <Form.Label>
                            Crossrange Offset
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.crossRangeOffset.defaultSettings}
                            onChange={evt => handleFormFieldChange('crossRangeOffset', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-4">
                            <Col>
                                <Form.Label className="">Min Length</Form.Label>
                                <Form.Control type="number"
                                              value={formData.formFieldParameters.crossRangeOffset.min}
                        
                                              onChange={evt => handleFormFieldChange('crossRangeOffset', 'min', evt.target.valueAsNumber)}/>
                            </Col>

                            <Col>
                                <Form.Label className="">Max Length</Form.Label>
                                <Form.Control type="number"
                                          value={formData.formFieldParameters.crossRangeOffset.max}
                                          onChange={evt => handleFormFieldChange('crossRangeOffset', 'max', evt.target.valueAsNumber)}/>
                            </Col>
                        </Row>
                        </Form.Group>
                        <hr/>
                        <Form.Group className="mt-5">
                        <Form.Label>
                            Sensor Location
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.sensorLocation.defaultSettings}
                            onChange={evt => handleFormFieldChange('sensorLocation', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-3">
                            <h6>Location Options</h6>
                            {locationOptions.map((o, i) => {
                                const selectedOptions = [...formData.formFieldParameters.sensorLocation.locations]
                                const isChecked = selectedOptions.indexOf(o) > -1;
                                return (
                                    <Col key={i}>
                                        <Form.Check 
                                            label={o}
                                            checked={isChecked}
                                            onChange={evt => handleOptionChange('sensorLocation', 'locations', o, evt.target.checked)}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        </Form.Group>
                        <hr/>

                        <Form.Group className="mt-5">
                        <Form.Label>
                            Terrain
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.terrain.defaultSettings}
                            onChange={evt => handleFormFieldChange('terrain', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-3">
                            <h6> Terrain Options</h6>
                            {terrainOptions.map((o, i) => {
                                const selectedOptions = [...formData.formFieldParameters.terrain.options]
                                const isChecked = selectedOptions.indexOf(o) > -1;
                                return (
                                    <Col key={i}>
                                        <Form.Check 
                                            label={o}
                                            checked={isChecked}
                                            onChange={evt => handleOptionChange('terrain', 'options', o, evt.target.checked)}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        </Form.Group>
                        <hr/>

                        <Form.Group className="mt-5">
                        <Form.Label>
                            Trigger Input
                        </Form.Label>
                        
                        <Form.Check
                            label="Use Default Settings"
                            checked={formData.formFieldParameters.triggerInput.defaultSettings}
                            onChange={evt => handleFormFieldChange('triggerInput', 'defaultSettings', evt.target.checked)}
                        />
                        <Row className="mt-3">
                            <h6> Trigger Options</h6>
                            {triggerOptions.map((o, i) => {
                                const selectedOptions = [...formData.formFieldParameters.triggerInput.options]
                                const isChecked = selectedOptions.indexOf(o) > -1;
                                return (
                                    <Col key={i}>
                                        <Form.Check 
                                            label={o}
                                            checked={isChecked}
                                            onChange={evt => handleOptionChange('triggerInput', 'options', o, evt.target.checked)}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                        </Form.Group>
                        <hr/>
                        <div className="text-center">
                            <Button type="submit">Update Profile</Button>
                        </div>
                </Form>
            </Modal.Body>

        </Modal>
    )
}