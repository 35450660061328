import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions'

export default combineReducers({
    currentDeviceCount: (state = 0, action) => {
        switch (action.type){
            case ACTIONS.SET_DEVICES_COUNT: {
                return action.payload;
            }
            default: return state;
        }
    },
    devices: (state = [] , action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICES: {
                return action.payload;
            }
            default: return state;
        }
    },

    siteDevices: (state = {}, action) => {
        switch(action.type){
            case ACTIONS.SET_SITE_DEVICES: {
                return {...state,
                [action.payload.test_site_id]: action.payload.devices}
            }
            default: return state;
        }
    },

    deviceTypes: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICE_TYPES: {
                return action.payload;
            }
            default: return state;
        }
    },
    siteTestCases: (state = {}, action) => {
        switch(action.type){
            case ACTIONS.SET_SITE_TEST_CASES: {
                const newState = {...state};
                const {test_site_id, device_type_id, test_cases} = action.payload;
                if (!newState[test_site_id]){
                    newState[test_site_id] = {};
                }

                newState[test_site_id][device_type_id] = test_cases;
                return newState;
            }
            default: return state;
        }
    },
    testCases: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_TEST_CASES: {
                return action.payload;
            }
            default: return state;
        }
    },

    deviceWherabouts: (state = {} , action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICE_WHEREABOUTS: {
                return {
                    ...state,
                    [action.payload.device_id]: action.payload.whereabouts
                }
            }
            default: return state;
        }
    },

    deviceTestResults: (state = {}, action) => {
        switch(action.type){
            case ACTIONS.SET_TEST_RESULTS: {
                return {
                    ...state,
                    [action.payload.device_id]: action.payload.results
                }
            }
            default: return state;
        }
    },

    deviceConfigs: (state = {}, action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICE_CONFIGS: {
                return {
                    ...state,
                    [action.payload.device_type_id]: action.payload.configs
                }
            }
            default: return state;
        }
    },
    deviceCalibrationFiles: (state = {}, action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICE_CALIBRATION_FILES: {
                return {
                    ...state,
                    [action.payload.device_id]: action.payload.calibrations
                }
            }
            default: return state;
        }
    },
    deviceTypeFirmware: (state = {}, action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICE_TYPE_FIRMWARE_OPTIONS: {
                return {
                    ...state,
                    [action.payload.device_type_id]: action.payload.firmware
                }
            }
            default: return state;
        }
    },
    deviceTypeBaseConfigurations: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_DEVICE_TYPE_BASE_CONFIGURATIONS:{
                return action.payload;
            }
            default: return state;
        }
    },
    checkedOutDevices: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_CHECKED_OUT_DEVICES: {
                return action.payload;
            }
            default: return state;
        }
    },
    checkedOutUserDevices: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_CHECKED_OUT_USER_DEVICES: {
                return action.payload;
            }
            default: return state;
        }
    },
    lostDevices: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_LOST_DEVICES: {
                return action.payload;
            }
            default: return state;
        }
    }
})