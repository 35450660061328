import React from 'react';
import ReactDOM from 'react-dom/client';
import './bootstrap.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from './reducers';
import TestSiteLocation from './admin/TestSiteLocation';

//COMPONENTS
import AdminMain from './admin/AdminMain';
import TesterDashboard from './tester/TesterDashboard';
import TestDevice from './tester/TestDevice';
import ShippingList from './admin/Shipping';
import Devices from './admin/Devices';
import AlertContainer from './shared/alerts/alerts';
import Navigation from './shared/navigation/Navigation';
import ChangePassword from './shared/components/Change-Password';
import ForgotPassword from './shared/components/Forgot-Password'
import ResetPassword from './shared/components/Reset-Password';
import Business from './business/Business';
import Dashboard from './dashboard';
import Inventory from './inventory';
import Checkin from './checkin';
import Report from './report';
import ShippingBundlePDF from './admin/ShippingBundlePDF';
import UpdateShippingBundle from './admin/UpdateShippingBundle';
import SalesPurchaseOrders from './business/SalesPurchaseOrders';
import Checkout from './checkout';

const store = createStore(reducers);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
      
      <AlertContainer/>
    <Router>
    <Navigation/>
      <Routes>
        <Route exact path="/" element={<App/>}/>
        <Route exact path="/admin" element={<AdminMain/>} />
        <Route path="/test-site/:siteId" element={<TestSiteLocation/>} />
        <Route path="/home" element={<TesterDashboard/>}/>
        <Route path="/device/test-device" element={<TestDevice/>}/>
        <Route path="/shipping" element={<ShippingList/>}/>
        <Route path="/device-list" element={<Devices/>}/>
        <Route path="/change-password" element={<ChangePassword/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/reset-password" element={<ResetPassword/>} />
        <Route path="/business" element={< Business/>} />
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/inventory" element={<Inventory/>} />
        <Route path="/checkin" element={<Checkin/>} />
        <Route path="/report" element={<Report/>} />
        <Route path="/shipping-bundle-pdf/:shippingBundleID" element={<ShippingBundlePDF/>} />
        <Route path="/update-shipping-bundle/:shippingBundleID" element={<UpdateShippingBundle/>}/>
        <Route path="/sales-and-purchase-orders" element={<SalesPurchaseOrders/>} />
        <Route path="/checkout" element={<Checkout/>} />
      </Routes>
    </Router>
    </Provider>
);


