import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import UpdateShippingModal from '../modals/UpdateShippingModal';
import {useState} from 'react';
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function ShippingListRow({bundle, overview, onUpdateModalClose}){
    const navigate  = useNavigate();
    const [modalOpen, setModalOpen] = useState(() => false);
    
    const closeUpdateModal = async() => {
        setModalOpen(() => false);
        if (onUpdateModalClose){
            onUpdateModalClose();
        }
    }

    const buildPurchaseInfo = () => {
        if (!bundle) return null;
        const info = bundle.payment_info;
        if (!info || !info.length) return null;
        return (
            <Row className="text-center" style={{border: '1px solid gray', paddingTop: 10, paddingBottom: 10}}>
                <h6>Purchase Information</h6>
                <span>{info}</span>
            </Row>
        )
    }
    
    const buildShippingAddress = () => {
        if (!bundle) return null;
        const address = bundle.shipping_address;
        if (!address) return null;

        return (
            <Row className="text-center" style={{border: '1px solid gray', paddingTop: 10, paddingBottom: 10}}>
                <h6>Shipped To</h6>
                <span>{address.recipient}</span> <br/>
                <span>{address.address_one}</span> <br/>
                <span>{address.address_two || ""}</span> <br/>
                <span>{address.city}, {address.state}, {address.country}, {address.zipcode}</span>
            </Row>
        )
    }

    const buildFromSection = () => {
        if (!bundle) return null;
        const site = bundle.shipped_from_location;
        const spartan_user = bundle.spartan_user;
        if (!site || !spartan_user) return null;

        return (
            <Row className="text-center" style={{border: '1px solid gray', paddingTop: 10, paddingBottom: 10}}>
                <h6>Shipped From</h6>
                <span>{site.site_name}</span> <br/>
                <span>{site.site_city}, {site.site_state}, {site.site_country}</span> <br/> <br/>

                <h6>Shipped By</h6>
                <span>{spartan_user.first_name} {spartan_user.last_name}</span>
            </Row>
        )
    }

    const buildTitle = () => {
        if (!bundle) return null;
        const address = bundle.shipping_address;
        if (!address) return null;
        return (
            <div className="text-center">
                <h4>Shipment to {address.recipient}</h4>
                <h6>Created On {moment(bundle.created_on).format('MM/DD/YYYY hh:mma')}</h6>
                <h6>Delivery Status: {bundle.delivery_status}</h6>
            </div>
        )
    }

    const buildShippingBundleItems = () => {
        if (!bundle) return null;
        const items = bundle.shipping_bundle_device_counts;
        if (!items) return null;

        return (
            <Row className="text-center" style={{border: '1px solid gray', paddingTop: 10, paddingBottom: 10}}>
                <h6>Devices Shipped</h6>
                <ul style={{listStyle:'none', textAlign: 'left'}}>
                    {
                        items.map((d, i) => {
                            return (
                                <li key={i}>
                                    {d.count}x - {d.model} | {d.device_line} | {d.device_name}
                                </li>
                            )
                        })
                    }
                </ul>
            </Row>
        )
    }

    const buildStandAlonePartSection = () => {
        if (!bundle) return null;
        const items = bundle.stand_alone_parts_count;
        if (!items) return null;
        return (
            <Row className="text-center" style={{border: '1px solid gray', paddingTop: 10, paddingBottom: 10}}>
                <h6>Stand Alone Parts Shipped</h6>
                <ul style={{listStyle:'none', textAlign: 'left'}}>
                    {
                        items.map((d, i) => {
                            return (
                                <li>
                                    {d.count}x - {d.model} | {d.part_name}
                                </li>
                            )
                        })
                    }
                </ul>
            </Row>
        )
    }

    const buildAccessorySection = () => {
        if (!bundle) return null;
        const items = bundle.accessory_items;
        if (!items) return null;
        return (
            <Row className="text-center" style={{border: '1px solid gray', paddingTop: 10, paddingBottom: 10}}>
                <h6>Accessory Items Shipped</h6>
                <ul style={{listStyle:'none', textAlign: 'left'}}>
                    {
                        items.map((d, i) => {
                            return (
                                <li>
                                    {d.quantity}x - {d.part_number} | {d.description}
                                </li>
                            )
                        })
                    }
                </ul>
            </Row>
        )
    }

    const buildEditButton = () => {
        if (!overview) return null;
        return (
            <Button onClick={() => setModalOpen(true)} style={{position:'absolute', right: 10, top: 10}}>Edit Bundle <FontAwesomeIcon icon={faPencil} /> </Button>
        )
    }

    let modal = null;

    if (modalOpen) {
        modal = <UpdateShippingModal bundle={bundle} bundleId={bundle.id} close={closeUpdateModal} />
    }

    if (!bundle) return null;
    return (
        <div>
            {modal}
            <Card bg='dark' text='white' className="mt-4">
                <Card.Header className="text-center">{buildTitle()} {buildEditButton()}</Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                            <Row style={{paddingLeft: 15}}>
                                {buildShippingAddress()}
                                {buildFromSection()}
                                {buildPurchaseInfo()}
                            </Row>
                        </Col>
                        <Col xs="6" sm="6" md="6" lg="6" xl="6">
                                {buildShippingBundleItems()}
                                {buildStandAlonePartSection()}
                                {buildAccessorySection()}
                        </Col>
                        
                    </Row>
                </Card.Body>
            {!overview && (<Card.Footer className="text-center">
                    <Button onClick={() => navigate(`/update-shipping-bundle/${bundle.id}`)}>View Full Details</Button><br/> <br/>
                    <a className="clicky" onClick={() => navigate(`/shipping-bundle-pdf/${bundle.id}`)}>Download PDF</a>
                </Card.Footer>)}
            {overview && (<Card.Footer className="text-center">
                <a className="clicky" onClick={() => navigate(`/shipping-bundle-pdf/${bundle.id}`)}>Download PDF</a>
            </Card.Footer>)}
            </Card>
        </div>
        
    )
}