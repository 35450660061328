import { combineReducers } from "redux";
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    bundles: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_BUNDLES: {
                return action.payload;
            }
            default: return state;
        }
    },
    bundleMap: (state = {}, action) =>  {
        switch(action.type){
            case ACTIONS.SET_BUNDLE_SPECIFIC_DATA: {
                return {
                    ...state,
                    [action.payload.bundle_id]: action.payload.bundle_data
                }
            }
            default: return state;
        }
    }
})