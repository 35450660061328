import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SpartanApi from "../../helpers/spartan-api";
import { Modal, Card, Button, Form } from "react-bootstrap";

export default function CreateAccessoryModal ({close}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [accessory, setAccessory] = useState(() => {
        return {
            part_number: '',
            description: ''
        }
    })

    const attemptCreateAccessory = async (evt) => {
        evt.preventDefault();
        await api.attemptCreateAccessoryItem(accessory);
        close();
    }

    return (
        <Modal bg="dark" show={true} onHide={close}>
            <Modal.Body bg="dark">
            <Card bg="dark" text="white" className="text-center">
                <Card.Header>CREATE AN ACCESSORY</Card.Header>
                <Card.Body>
                    <Form onSubmit={attemptCreateAccessory}>
                        <Form.Group className="mt-5">
                            <Form.Label>Part Number</Form.Label>
                            <Form.Control type="text"
                                          value={accessory.part_number}
                                          onChange={evt => setAccessory({...accessory, part_number: evt.target.value})}
                                          required/>
                        </Form.Group>

                        <Form.Group className="mt-5">
                            <Form.Label>
                                Description
                            </Form.Label>
                            <Form.Control type="text"
                                          value={accessory.description}
                                          onChange={evt => setAccessory({...accessory, description: evt.target.value })}
                                          required/>
                        </Form.Group>

                        <Button type="submit">Create Accessory</Button>
                    </Form>
                </Card.Body>
            </Card>
            </Modal.Body>
        </Modal>
    )
}