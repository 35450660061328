import {Modal, Form, Button, Row, Col} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';


export default function AddCustomerToOrganizationModal ({close, organization}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [newCustomer, setNewCustomer] = useState(() => {
        return {
            email: '',
            user_password: '',
            first_name: '',
            last_name: '',
            access_level: 'admin'
        }
    });

    const attemptAddCustomer = async (evt) => {
        evt.preventDefault();
        await api.attemptAddCustomerToOrganization({...newCustomer, organization_id: organization.id});
        close();
    }

    console.log("ORGANIZATION: ", organization);

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="spartan-modal" closeButton>
               ADD USER TO  {organization.organization_name.toUpperCase()}
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                <Form onSubmit={attemptAddCustomer}>
                    <Form.Group>
                        <Form.Label>
                            First Name
                        </Form.Label>
                        <Form.Control value={newCustomer.first_name}
                                      type="text"
                                      name="first_name" 
                                      onChange={evt => setNewCustomer({...newCustomer, [evt.target.name]: evt.target.value })}
                                      required/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Last Name
                        </Form.Label>
                        <Form.Control value={newCustomer.last_name}
                                      type="text"
                                      name="last_name" 
                                      onChange={evt => setNewCustomer({...newCustomer, [evt.target.name]: evt.target.value })}
                                      required/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control value={newCustomer.email}
                                      type="email"
                                      name="email" 
                                      onChange={evt => setNewCustomer({...newCustomer, [evt.target.name]: evt.target.value })}
                                      required/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Initial Password
                        </Form.Label>
                        <Form.Control value={newCustomer.user_password}
                                      type="password"
                                      name="user_password" 
                                      onChange={evt => setNewCustomer({...newCustomer, [evt.target.name]: evt.target.value })}
                                      required/>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>
                            Access Level
                        </Form.Label>
                        <Form.Select value={newCustomer.access_level}
                                     onChange={evt => setNewCustomer({...newCustomer, access_level: evt.target.value})} 
                                     required>
                                <option value="admin">ADMIN</option>
                                <option value="user">USER</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mt-4 text-center">
                        <Button type="submit">
                            ADD CUSTOMER
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    )
}