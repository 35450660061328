import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import SpartanApi from "../helpers/spartan-api";
import CreateTestCaseModal from "./modals/CreateTestCaseModal";
import UpdateTestCaseModal from "./modals/UpdateTestCaseModal";

export default function TestCases() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const state = useSelector(s => s.device);
    const [deviceType, setDeviceType] = useState(() => undefined);
    const [modalOpen, setModalOpen] = useState(() => false);
    const [updateModalOpen, setUpdateModalOpen] = useState(() => false);
    const [activeTestCase, setActiveTestCase] = useState(() => undefined);
   
    const loadData = async () => {
        await api.attemptGetDeviceTypes();
    }

    const loadTestCases = async () => {
        await api.attemptGetDeviceTestCases(deviceType);
    }

    const close = async(deviceTypeId) => {
        setModalOpen(false);
        setDeviceType(deviceTypeId);
        loadTestCases();
    }

    const closeUpdate = async() => {
        setActiveTestCase(undefined)
        setUpdateModalOpen(false)
        await api.attemptGetDeviceTestCases(deviceType);
    }

    useEffect(() => {
        console.log("Load Initial Data");
        loadData();
    }, [])

    useEffect(() => {
        if (!deviceType) return;
        loadTestCases();
    }, [deviceType]);

    if (modalOpen){
        return <CreateTestCaseModal close={close}/>
    }

    if (updateModalOpen && activeTestCase){
        return <UpdateTestCaseModal close={closeUpdate} test_case={activeTestCase}/>
    }

    return (
        <div>
            <select value={deviceType} onChange={evt => setDeviceType(evt.target.value)}>
                <option>Select Device Type</option>
                {
                    state.deviceTypes.map((dt, i) => {
                        return <option key={i} value={dt.id}>{dt.model}</option>
                    })
                }
            </select>
            <button className="btn btn-primary" onClick={() => setModalOpen(true)}>Create New Test Case</button>

            <h5 className="text-center">Tests</h5>
            <table className="table table-dark">
                <thead>
                    <tr>
                        <th>Test Name</th>
                    </tr>
                </thead>
                <tbody>
                {state.testCases.map((tc, i) => {
                return (
                    <tr key={i}>
                        <td>{tc.test_name} (<a className="clicky" onClick={() => {
                            setActiveTestCase(tc)
                            setUpdateModalOpen(true);
                        }}>View/Update</a>)
                        </td>
                    </tr>
                )
            })}
                </tbody>
            </table>
            
        </div>
    )
}