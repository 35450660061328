import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions'
export default combineReducers({
    devices_at_site: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_DASHBOARD_DEVICES_AT_SITE_STATS: {
                return action.payload;
            }
            default: return state;
        }
    },
    devices_in_testing: (state = null, action) => {
        switch(action.type) {
            case ACTIONS.SET_DASHBOARD_DEVICES_IN_TESTING: {
                return action.payload;
            }
            default: return state;
        }
    },
    devices_passed_testing: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_DASHBOARD_DEVICES_PASSED_TESTING: {
                return action.payload;
            }
            default: return state;
        }
    },
    devices_pass_to_fail_ratio: (state = null, action) => {
        switch(action.type) {
            case ACTIONS.SET_DASHBOARD_DEVICE_PASS_FAIL_RATIO: {
                return action.payload;
            }
            default: return state;
        }
    },
    device_types_pass_to_fail_ratio: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_DASHBOARD_DEVICE_TYPE_PASS_TO_FAIL: {
                return action.payload;
            }
            default: return state;
        }
    },
    devices_shipped: (state = null, action) => {
        switch(action.type) {
            case ACTIONS.SET_DASHBOARD_DEVICES_SHIPPED: {
                return action.payload;
            }
            default: return state;
        }
    },
    devices_shipped_by_device_type: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_DASHBOARD_SHIPPING_STATS_BY_DEVICE_TYPE: {
                return action.payload;
            }
            default: return state;
        }
    }
});
