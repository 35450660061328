import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../helpers/spartan-api';
import { useNavigate } from 'react-router-dom';
import {Container, Card, Row, Button} from 'react-bootstrap';
import CreateSubAssemblyTypeModal from './modals/CreateSubAssemblyTypeModal';

export default function SubAssemblyTypes(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const subAssemblyTypes = useSelector(s => s.subAssembly.subAssemblyTypes);
    const [modalOpen, setModalOpen] = useState(() => false);

    const load = async() => {
        await api.attemptGetSubAssemblyPartTypes();
        await api.attemptGetSubAssemblyTypes();
    }

    const closeModal = async() => {
        await load();
        setModalOpen(false);
    }

    useEffect(() => {
        load();
    }, [])

    let modal = null;

    if (modalOpen) {
        modal = <CreateSubAssemblyTypeModal close={closeModal}/>
    } 

    if (!subAssemblyTypes || !subAssemblyTypes.length) {
        return (
            <Container>
                {modal}
                <Card bg="dark" color="white">
                    <Card.Header>
                        NO SUB ASSEMBLY TYPES EXIST
                    </Card.Header>
                    <Card.Body>
                        <Button onClick={() => setModalOpen(true)}>Click Here</Button> to create one
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            {modal}
            <Button onClick={() => setModalOpen(true)} className="mb-4">CREATE A SUB-ASSEMBLY</Button>
            <table className="table table-dark">
                <thead>
                    <tr>
                        <th>Assembly Name</th>
                        <th>Model</th>
                        <th>Requirements</th>
                    </tr>
                </thead>

                <tbody>
                    {subAssemblyTypes.map((sat, i) => {
                        return (
                            <tr key={i}>
                                <td>{sat.assembly_name}</td>
                                <td>{sat.model}</td>
                                <td>
                                    <ul>
                                        {sat.requirements && sat.requirements.map((r, j) => {
                                            return (
                                                <li key={j}>
                                                    {r.quantity} - {r.model} |{r.part_name}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </Container>
    )
}