import * as ACTIONS from '../constants/actions';
import { combineReducers } from 'redux';

export default combineReducers({
    available_sub_assemblies: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_INVENTORY_SUB_ASSEMBLY_COUNT: {
                return action.payload;
            }
            default: return state;
        }
    },
    available_devices: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_INVENTORY_DEVICE_COUNT: {
                return action.payload;
            }
            default: return state;
        }
    },
    available_sub_assembly_parts: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_INVENTORY_SUB_ASSEMBLY_PART_COUNT: {
                return action.payload;
            }
            default: return state;
        }
    }
})