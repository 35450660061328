import {Modal, Card, Button, Form} from 'react-bootstrap';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../../helpers/spartan-api';

export default function CreateSubAssemblyPartTypeModal({close}){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [partType, setPartType] = useState(() => {
        return {
            model: '',
            part_name: ''
        }
    });
    const [isSubmitting, setIsSubmitting] = useState(() => false);

    const attemptSavePartType = async(evt) => {
        evt.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        await api.attemptCreateSubAssemblyPartType(partType);
        close();
    }

    return (
        <Modal bg="dark" show={true} onHide={close}>
            <Modal.Body bg="dark">
                <Card bg="dark" color="white">
                    <Card.Header>
                        CREATE A SUB-ASSEMBLY PART
                    </Card.Header>

                    <Card.Body>
                        <Form onSubmit={attemptSavePartType}>
                            <Form.Group>
                                <Form.Label>
                                    Model
                                </Form.Label>
                                <Form.Control type="text"
                                              value={partType.model}
                                              onChange={evt => setPartType({...partType, model: evt.target.value.toUpperCase()})} 
                                              required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Part Name
                                </Form.Label>
                                <Form.Control type="text"
                                              value={partType.part_name}
                                              onChange={evt => setPartType({...partType, part_name: evt.target.value.toUpperCase()})} 
                                              required/>
                            </Form.Group>
                            <Button disabled={isSubmitting} type='submit'>CREATE NEW PART</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
    
}