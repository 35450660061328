import {Modal, Form, Row, Button, Col} from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import SpartanApi from '../../helpers/spartan-api';
import moment from 'moment';

export default  function FulfillPurchaseOrderModal({close, locationId}) {
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(() => null);
    const [formData, setFormData] = useState(() => {
        return {
            purchaseOrderId: undefined,
            items: []
        }
    });
    const purchaseOrders = useSelector(s => s.business.unfulfilledPurchaseOrders);
    const [isSubmitting, setIsSubmitting] = useState(() => false);

    const loadUnfulfilledOrders = async () => {
        await api.attemptGetUnfulfilledPurchaseOrders()
    }

    const fulfillOrder = async (evt) => {
        evt.preventDefault();
        setIsSubmitting(() => true);

        //Fulfill The Purchase Order
        await api.attemptFulfillPurchaseOrder({purchase_order_id: formData.purchaseOrderId});

        //Create The Serial Numbers
        
        
    }
    
    const handlePoChange = (purchaseOrderId) => {
        if (!purchaseOrderId || !purchaseOrderId.length) return;
        const po = purchaseOrders.find(p => p.id == purchaseOrderId)
        let quantities = [];

        po.line_items.forEach(li => {
            quantities.push({
                model: li.item_name,
                quantity: li.quantity,
                generate_serial_numbers: false
            })
        })

        setFormData({
            purchaseOrderId: purchaseOrderId,
            items: [...quantities]
        })
    }

    const buildForm = () => {
        if (!purchaseOrders.length) {
            return (
                <div className="mt-5 text-center">
                    There are no purchase orders to fulfill at this time.
                </div>
            )
        }

        return (
            <div className="mt-5">
                <Form onSubmit={fulfillOrder}>
                    <Form.Group>
                        <Form.Label>
                            Select a Purchase Order to Fulfill
                        </Form.Label>
                        <Form.Select value={formData.purchaseOrderId}
                                     onChange={evt => handlePoChange(evt.target.value)} required>
                            <option></option>
                            {purchaseOrders.map((p, i) => {
                                const totalItems = (p.line_items && p.line_items.length ) ? p.line_items.reduce((t, c) => t + c.quantity, 0) : 0
                                const itemNames  = (p.line_items && p.line_items.length ) ? p.line_items.map(p => p.item_name).join(" | ") : ''
                                const poTitle = `CREATED ON (${moment(p.created_on).format('MM/DD/YYYY hh:mma')})`
                                return (
                                    <option key={i} value={p.id}>
                                      {totalItems} Parts ({itemNames}) -  {poTitle}
                                    </option>
                                )
                            })}

                        </Form.Select>
                    </Form.Group>

                    {buildItems()}
                    <div className="mt-4 text-center">
                            <hr/>
                            <Button type="submit" disabled={isSubmitting}>Fulfill Order</Button>
                            <p className="mt-3">Once you click, the full order will be deemed fulfilled.</p>
                    </div>
                </Form>
            </div>
        )
    }

    const handleSerialChange = (index, checked) => {
        const items = [...formData.items];
        items[index].generate_serial_numbers = checked;
        setFormData({...formData, items})
    }

    const buildItems = () => {
        if (!formData.items || !formData.items.length) return null;

        return (
            <Form.Group className="mt-3">
                <Form.Label>
                    Received Items
                </Form.Label>
                {
                    formData.items.map((d, i) => {
                        return (
                            <Row key={i}>
                                <Col>
                                    {d.model}
                                </Col>
                                <Col>
                                    {d.quantity} received
                                </Col>
                                <Col>
                                    <Form.Check
                                        label="Generate Serial Numbers?"
                                        checked={d.generate_serial_numbers}
                                        onChange={evt => handleSerialChange(i, evt.target.checked)}
                                    />

                                    
                                </Col>
                            </Row>
                        )
                    })
                }
            </Form.Group>
        )
    }

    useEffect(() => {
        loadUnfulfilledOrders();
    }, []);

    return (
        <Modal size='xl' show={true} onHide={close}>
            <Modal.Header className="spartan-modal" closeButton>
                Formally Accept a Purchase Order
            </Modal.Header>
            <Modal.Body className="spartan-modal">
                {buildForm()}
            </Modal.Body>
        </Modal>
    )
}