import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import SpartanApi from '../helpers/spartan-api'
import TestSites from './TestSites';
import DeviceTypes from './DeviceTypes';
import Devices from './Devices';
import TestCases from './TestCases';
import DeviceTypeFirmware from './DeviceTypeFirmware';
import ShippingList from './Shipping';
import Accessory from './Accessory';
import SubAssemblyTypes from './SubAssemblyTypes';
import SubAssemblyPartTypes from './SubAssemblyPartTypes';
import ExternalConfigTool from './ExternalConfigTool';
import DeviceBaseConfigs from './DeviceBaseConfigs';
import Customer from './Customer';

const options = ['Test Cases', 'Test Sites', 'Devices' , 'Device Types', 'Device Type Firmware', 'Shipping', "Accessory Items", "Sub Assemblies", "Sub Assembly Parts", "External Tool", "Device Type Base Configurations", "Customer"];

//TODO: Style this shit
export default function AdminMain () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [activeOption, setActiveOption] = useState(() => "Test Sites");
    const user = useSelector(s => s.user);
    

    const logout = () => {
        window.localStorage.clear();
        navigate("/")
    }
    useEffect(() => {
        console.log(activeOption)
    }, [activeOption]);

    let adminScreen = null;

    switch(activeOption){
        case "Test Sites": {
            adminScreen = <TestSites/>
            break;
        }
        case "Device Types": {
            adminScreen = <DeviceTypes/>
            break;
        }
        case "Devices": {
            adminScreen = <Devices/>
            break;
        }
        case "Test Cases": {
            adminScreen = <TestCases/>
            break;
        }
        case "Device Type Firmware": {
            adminScreen = <DeviceTypeFirmware />
            break;
        }
        case "Shipping": {
            adminScreen = <ShippingList />
            break;
        }
        case "Accessory Items": {
            adminScreen = <Accessory />
            break;
        }

        case "Sub Assemblies": {
            adminScreen = <SubAssemblyTypes/>
            break;
        }

        case "Sub Assembly Parts": {
            adminScreen = <SubAssemblyPartTypes/>
            break;
        }

        case "External Tool": {
            adminScreen = <ExternalConfigTool/>
            break;
        }

        case "Device Type Base Configurations": {
            adminScreen = <DeviceBaseConfigs/>
            break;
        }

        case "Customer": {
            adminScreen = <Customer />
            break;
        }
        
    }
    
    if (!user || !user.data || !user.data.is_admin) {
        console.log("SHOULD WORK")
        //UNAUTHORIZED
        return(
        <div>
            <h3>You are not authorized to view this page</h3>
        </div>
        )
    }

    return (
        <div>
            <select className="form-select mb-5" value={activeOption} onChange={evt => setActiveOption(evt.target.value)}>
                {options.map((o, i) => {
                    return <option key={i} value={o}>{o}</option>
                })}
            </select>

            {adminScreen}

            <button onClick={logout} style={{position: 'fixed', right: 0, bottom: 0}}>Logout</button>
        </div>
    )
}