import {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SpartanApi from '../helpers/spartan-api';
import { Container, Table, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';

const typeOptions = ['all', 'passes', 'fails'];

export default function Report(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [results, setResults] = useState(() => null);
    const [request, setRequest] = useState(() => {
        return {
            test_site_id: undefined,
            start_date: undefined,
            end_date: undefined,
            type: 'all'
        }
    })
    const [inventoryRequest, setInventoryRequest] = useState(() => {
        return {
            test_site_id: undefined,
            isGenerating: false
        }
    });
    const testSites = useSelector(s => s.testSite.sites);
    const loadData = async () => {
        await api.attemptGetTestSites();
    }

    useEffect(() => {
        loadData();
    }, []);

    const attemptGenerateReport = async (evt) => {
        evt.preventDefault();
        console.log("REQUEST: ", request);
        const newResults = await api.attemptGenerateTestingReport(request);
        if (!newResults) return;
        setResults([...newResults]);
    }

    const generateInventoryReport = async (evt) => {
        evt.preventDefault();
        console.log("REQUEST: ", inventoryRequest);
        const inventoryResults = await api.attemptGenerateInventoryReport(inventoryRequest);
        for (let report in inventoryResults) {
            downloadInventory(report, inventoryResults[report])
        }
    }

    const downloadInventory = (name, data) => {
        const csvData = Papa.unparse(data)
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
        let underscoreString = name.replace(/([A-Z])/g, '_$1').toLowerCase();
        // Add Date.now() at the end
        underscoreString += "_" + Date.now();

        link.download = `${underscoreString}.csv`;
        link.click();
    }

    /*
    const convertToCSV = () => {
        const csvData = Papa.unparse(results);
        return csvData;
    }

    const downloadCSV = () => {
        const data = convertToCSV();
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
        link.download = `Spartan_Armory_Report_Tests_Passing.csv`;
        link.click();
    }

    */

    const buildPage = () => {
        return (
            <div>
                <Card bg="dark" text="white">
                    <Card.Header>
                        <h4>Generate Testing Report</h4>
                    </Card.Header>
                
                <Card.Body>
                <Form onSubmit={attemptGenerateReport}>
                    
                <Row className="mt-5">
                    
                    
                    <Col>
                        <h6>Test Site</h6>
                        <Form.Select value={request.test_site_id} onChange={evt => setRequest({...request, test_site_id: evt.target.value})} required>
                            <option></option>
                            {testSites.map((s, i) => {
                                return <option key={i} value={s.id}>{s.site_name} ({s.site_city}, {s.site_state})</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col>
                        <h6>Result Types</h6>
                        <Form.Select value={request.type} onChange={evt => setRequest({...request, type: evt.target.value})} required>
                            {typeOptions.map((t, i) => {
                                return <option key={i}>{t}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col>
                        <h6>Start Date</h6>
                        <Form.Control type="date" value={request.start_date} onChange={evt => setRequest({...request, start_date: evt.target.value})} required/>
                    </Col>
                    <Col>
                        <h6>End Date</h6>
                        <Form.Control type="date" value={request.end_date} onChange={evt => setRequest({...request, end_date: evt.target.value})} required/>
                    </Col>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit">Generate Report</Button>
                    </Col>
                </Row>
                </Form>

                {buildReportSection()}
                </Card.Body>
                </Card>
                <Card bg="dark" text="white" className="mt-5">
                    <Card.Header>
                        <h4>Get Full Inventory Report</h4>
                    </Card.Header>
                    <Card.Body>
                        <p>The following will generate a full list of:</p>
                        <ul>
                            <li>Shipped Devices</li>
                            <li>Unshipped Devices</li>
                            <li>Shipped Parts</li>
                            <li>Unshipped Parts</li>
                        </ul>
                        <p>Based on the selected test site.</p>
                        <Form onSubmit={generateInventoryReport}>
                            <Form.Select value={inventoryRequest.test_site_id} onChange={evt => setInventoryRequest({...inventoryRequest, test_site_id: evt.target.value})} required>
                                <option></option>
                                {testSites.map((s, i) => {
                                    return <option key={i} value={s.id}>{s.site_name} ({s.site_city}, {s.site_state})</option>
                                })}
                            </Form.Select>
                            <div className="mt-3 text-center">
                                <Button type="submit">Generate All Reports</Button>
                            </div>
                        </Form>
                        
                        
                    </Card.Body>
                </Card>
            </div>
        )
    }

    const convertToCSV = () => {
        const csvData = Papa.unparse(results);
        return csvData;
    }

    const downloadCSV = () => {
        const data = convertToCSV();
        const link = document.createElement('a');
        link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
        link.download = `Spartan_Armory_Report_Tests_Passing.csv`;
        link.click();
    }

    const buildReportSection = () => {
        if (!results || !results.length) return null;

        return (
            <Row className="mt-5">
                <h4 className="text-center">Generated Report</h4>
                <Button onClick={downloadCSV}>Download Report</Button>
                <Table variant="dark" color="white" className="mt-3">
                    <thead>
                        <tr>
                            <th>Serial Number</th>
                            <th>Device Line</th>
                            <th>Device Name</th>
                            <th>Device Model</th>
                            <th>Tests Passed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((r, i) => {
                            return (
                                <tr key={i}>
                                    <td>{r.serial_number}</td>
                                    <td>{r.device_line}</td>
                                    <td>{r.device_name}</td>
                                    <td>{r.model}</td>
                                    <td>{r.tests_passed} / {r.total_tests} <br/> passed</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Row>
        )
    }

    return (
        <Container>
            {buildPage()}
        </Container>
    )
}