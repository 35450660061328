import { useState, useEffect} from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SpartanApi from "../helpers/spartan-api";
import CreateShippingModal from "./modals/CreateShippingModal";
import UpdateShippingModal from "./modals/UpdateShippingModal";
import AdvancedBundleSearchModal from "./modals/advancedBundleSearchModal";
import moment from 'moment';
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useNavigate } from "react-router-dom";
import ShippingListRow from "./shared/ShippingListRow";
const limits = ['5', '10', '25', '50', '100'];
export default function ShippingList(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch, navigate}));
    const [createShippingModalOpen, setCreateShippingModalOpen] = useState(() => false);
    const [updateShippingModalOpen, setUpdateShippingModalOpen] = useState(() => false);
    const [bundleToUpdate, setBundleToUpdate] = useState(() => undefined);
    const [searchModalOpen, setSearchModalOpen] = useState(() => false);
    const [searchTerm, setSearchTerm] = useState(() => '');
    const [limit, setLimit]  = useState(() => '100');
    const [offset, setOffset] = useState(() => 0); 
    const shipmentData = useSelector(s => s.shipping)
    const loadData = async() => {
        await api.attemptGetAccessories();
        await api.attemptGetShippingBundles();
    }
    
    const buildSubAssemblySection = (bundle) => {
        if (!bundle.sub_assemblies || !bundle.sub_assemblies.length) return null;
        
        return (
            <div >
                <hr/>
                <h6 className="text-center">Sub-Assemblies Shipped</h6>
                <ul>
                    {bundle.sub_assemblies.map((sa, i) => {
                        let part_count = 0;
                        if (sa.assembly_part_counts && sa.assembly_part_counts.length){
                            part_count = sa.assembly_part_counts.reduce((t, p) => t + p.quantity, 0);
                        }
                        return (
                            <li key={i}>{sa.model} | {sa.assembly_name} <br/>
                                ({part_count} parts)
                                {part_count > 0 ? (
                                    sa.assembly_part_counts.map((part, j) => {
                                        return <div key={j}>{part.model} | {part.part_name} ({part.quantity} units)</div>
                                    })
                                ) : null}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    const openUpdateModal = (bundle) =>  {
        setBundleToUpdate(bundle);
        setUpdateShippingModalOpen(true);
    }


    const closeUpdateShippingModal = async () => {
        loadData();
        setUpdateShippingModalOpen(false)
    }

    const closeCreateShippingModal = async () =>  {
        loadData();
        setCreateShippingModalOpen(false)
    }

    const searchBySerialNumber = async() => {
        await api.attemptSearchShippingBundlesBySerialNumber(searchTerm)
    }

    const handleLimitChange = async(newLimit) => {
        setLimit(newLimit);
        setOffset(0)
        await api.attemptGetShippingBundles({
            limit: newLimit, offset: 0
        })
    }

    const prevPage = async () => {
        const newOffset = offset - 1;
        setOffset(newOffset);

        await api.attemptGetShippingBundles({
            limit, offset: newOffset
        })
    }

    const nextPage = async () => {
        const newOffset = offset + 1;
        setOffset(newOffset);

        await api.attemptGetShippingBundles({
            limit, offset: newOffset
        })
    }

    const buildStandAlonePartSection = (bundle) => {
        if (!bundle.stand_alone_parts_count) return null;

        return (
            <div>
                <hr/>
                <h6 className="text-center">Standalone Parts Shipped</h6>
                <ul>
                    {bundle.stand_alone_parts_count.map((b, i) => {
                        return (
                            <li key={i}>{b.count}x {b.model} | {b.part_name}</li>
                        )
                    })}
                </ul>
            </div>
        )

    }


    useEffect(() =>  {
        loadData();
    }, [])

    let modal = null;

    if (createShippingModalOpen) {
        modal =  <CreateShippingModal close={closeCreateShippingModal}/>
    }

    if (updateShippingModalOpen && bundleToUpdate){
        modal =  <UpdateShippingModal close={closeUpdateShippingModal} bundle={bundleToUpdate}/>
    }

    if (searchModalOpen){
        modal = <AdvancedBundleSearchModal close={() => setSearchModalOpen(false)}/>
    }

    
    
    return (
        <div> 
            {modal}
            <Button onClick={() => setCreateShippingModalOpen(true)}> Create New Bundle </Button>
            <br/> <br/> <br/>
            <input type="text" value={searchTerm} onChange={evt => setSearchTerm(evt.target.value)}/> 
            <Button onClick={searchBySerialNumber}>Search By Device Serial Number</Button>
            <br/> <br/>
            <a className="clicky" onClick={() => setSearchModalOpen(true)}>Open Advanced Search View</a>
            <br/> <br/>
            <div className="right-text">
                    <Button disabled={offset <= 0} onClick={prevPage}>Prev</Button>
                    <select value={limit} onChange={evt => handleLimitChange(evt.target.value)}>
                        {limits.map((l, i) => {
                            return (
                                <option key={i} value={l}>{l} Per Page</option>
                            )
                        })}
                    </select>
                    <Button disabled={!shipmentData.bundles.length} onClick={nextPage}>Next</Button>
            </div>
            <div style={{paddingLeft:20, paddingRight: 20}}>
                {shipmentData.bundles.map((b, i) => {
                    return (
                        <ShippingListRow bundle={b} key={i} />
                    )
                })}
            </div>
           
        </div>
    )
}