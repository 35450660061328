import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    globalConfigurations: (state = [] , action) => {
        switch(action.type){
            case ACTIONS.SET_EXTERNAL_TOOL_GLOBAL_CONFIGURATIONS: {
                return action.payload;
            }
            default: return state;
        }
    },
    organizationsAndFields: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_EXTERNAL_TOOL_ORGANIZATIONS_AND_FIELDS: {
                return action.payload;
            }
            default: return state;
        }
    },
    fieldOptions: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_EXTERNAL_TOOL_FIELD_OPTIONS: {
                return action.payload;
            }
            default: return state;
        }
    },
    premiumProfiles: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_EXTERNAL_PREMIUM_PROFILES: {
                return action.payload;
            }
            default: return state;
        }
    }
});