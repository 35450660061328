import { useState, useEffect } from "react";
import { useSelector, useDispatch} from 'react-redux'
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import SpartanApi from "../../helpers/spartan-api";

export default function UpdateDeviceTypeFirmwareModal({close, firmware}){
    const dispatch = useDispatch();
    const [api] = useState(() => new SpartanApi({dispatch}));
    const deviceData = useSelector(s => s.device);
    const [isSubmitting, setIsSubmitting] = useState(() => false)
    const [file, setFile] = useState(() => undefined);
    const [newFirmware, setNewFirmware] = useState(() => {
        return {
            ...firmware
        }
    })
    const state = useSelector(s => s);
    const [iniFile, setIniFile] = useState(() => undefined);

    const handleFormChange = (key, value) => {
        setNewFirmware({...newFirmware, [key]: value})
    }

    const uploadFileGetDownloadUrl = async () => {
        if (!file) return null;

        await api.attemptGetToken();
        if (!state.user.sasToken) return;
        
        const blobService = new BlobServiceClient('https://spartanproductiontest.blob.core.windows.net/?' + state.user.sasToken);

        const containerClient = blobService.getContainerClient('firmwarereleases');
        await containerClient.createIfNotExists({access: 'container'});
        
        const uploadDate = Date.now();
        const fileName = uploadDate + file.name

        const blobClient = containerClient.getBlockBlobClient(fileName);
        const options = {blobHTTPHeaders: {blobContentType: file.type}};
        await blobClient.uploadBrowserData(file, options);

        return `https://spartanproductiontest.blob.core.windows.net/firmwarereleases/${fileName}`;
     
        
    }

    const uploadIniFileAndGetDownloadUrl = async () => {
        if (!iniFile) return null;

        await api.attemptGetToken();
        if (!state.user.sasToken) return;
        
        const blobService = new BlobServiceClient('https://spartanproductiontest.blob.core.windows.net/?' + state.user.sasToken);

        const containerClient = blobService.getContainerClient('firmwarebaseconfigs');
        await containerClient.createIfNotExists({access: 'container'});
        
        const uploadDate = Date.now();
        const fileName = uploadDate + iniFile.name

        const blobClient = containerClient.getBlockBlobClient(fileName);
        const options = {blobHTTPHeaders: {blobContentType: iniFile.type}};
        await blobClient.uploadBrowserData(iniFile, options);

        return `https://spartanproductiontest.blob.core.windows.net/firmwarebaseconfigs/${fileName}`;
     
        
    }

   

    const handleSubmit = async(evt) => {
        evt.preventDefault();
        setIsSubmitting(() => true);
        let requestObj = {...newFirmware, device_type_firmware_id: newFirmware.id}
        const zip_download_url = await uploadFileGetDownloadUrl();
        if (zip_download_url){
            requestObj.zip_download_url = zip_download_url;
        }

        const default_config_url = await uploadIniFileAndGetDownloadUrl();
        if (default_config_url){
            requestObj.default_config_url = default_config_url;
        }
            
        await api.attemptUpdateDeviceTypeFirmware(requestObj)
        close(newFirmware.device_type_id)
    }   

    useEffect(() => {
        setNewFirmware(firmware)
    }, [firmware])

    return (
        <div className="spartan-modal">
            <div className="spartan-modal-heading">
                <h3 className="text-center">Create Device Type Firmware</h3>
            </div>
            <hr/>
            <div className="spartan-modal-body text-center">
                <form onSubmit={handleSubmit}>
                <h5>Device Type</h5>
                <select value={newFirmware.device_type_id} onChange={evt => handleFormChange('device_type_id', evt.target.value)}>
                    <option value={undefined}>Select a Device Type</option>
                {deviceData.deviceTypes.map((dt, i) =>  {
                    return <option key={i} value={dt.id}>{dt.device_line} | {dt.device_name} | {dt.model}</option>
                })}
                </select>

                <br/> <br/>
                <h5>Firmware Version</h5>
                <input type="text" value={newFirmware.firmware_version} onChange={evt => handleFormChange('firmware_version', evt.target.value)}/>
                <br/><br/>

                <h5>Firmware Github URL</h5>
                <input type="text" value={newFirmware.firmware_github_url} onChange={evt => handleFormChange('firmware_github_url', evt.target.value)}/>
                <br/> <br/>

                <h5>Firmware is Active </h5>
                <input type="checkbox" checked={newFirmware.is_active} onChange={evt => handleFormChange('is_active', evt.target.checked)} />
                <br/> <br/>

                <h5>Firmware Notes</h5>
                <textarea value={newFirmware.notes} onChange={evt => handleFormChange('notes', evt.target.value)}/>
                <br/> <br/>

                <h5>Firmware Image File (.bin)</h5>
                <input type="file" onChange={evt => setFile(evt.target.files[0])} />
                <br/> <br/>

                <h5>Firmware Default INI File</h5>
                <input type="file" onChange={evt => setIniFile(evt.target.files[0])} />
                <br/> <br/>

                <button type="submit" disabled={isSubmitting}>Save Firmware</button>
                </form>
            </div>
        </div>
    )
}